/*
 * @Author: Michael Lam 127068684+MichaelLam888@users.noreply.github.com
 * @Date: 2024-05-09 17:52:16
 * @LastEditTime: 2024-06-11 15:47:07
 * @Description: 
 */
import React from 'react';
import '../css/Footer.css'; // Make sure to have a Footer.css file

const Footer = () => {



  return (
    <div className="footer">
      <div className="footer-content"> {/* Content container */}
        <div className="footer-left">
          <img src="/images/bottom_logo.png" alt="Logo" className="footer-logo" />

        </div>
        <div className="footer-right">
          <a href="/aboutUs">About Us</a>
          <a href="/products">Service</a>
          <a href="/contactUs">Enquiry</a>
        </div>



      </div>
      <span className='rights'> © Master Select Group Limited 2024. ALL RIGHTS RESERVED.</span>
    </div>
  );
};

export default Footer;
