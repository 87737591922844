/*
 * @Author: Michael Lam 127068684+MichaelLam888@users.noreply.github.com
 * @Date: 2024-05-25 17:03:06
 * @LastEditTime: 2024-05-26 17:38:53
 * @Description: 
 */
import React from 'react';
import '../css/ConfirmationDialog.css'; // Assuming you have corresponding CSS file




const AddToCartDialog = ({ item, onCancel, onConfirm, conversionSuccess ,handleBack }) => {
  
  return (
    
    <div className="confirmation-overlay">
      {!conversionSuccess && (
        <div className="confirmation-dialog">
          <h2>確認從購物車刪除</h2>
          <p><strong>{item.fullProductName}</strong> (單個所需積分: {item.unitPrice}) x <strong>{item.qty}</strong></p>
          <div className="button-container">
            <button onClick={onCancel}>返回</button>
            <button onClick={() => onConfirm(item)}>確認</button>
          </div>
        </div>)}

      {conversionSuccess && (
        <div className="confirmation-dialog">
          <p>刪除成功</p>
          <div className="button-container">
            <button onClick={handleBack}>返回</button>
          </div>
        </div>)}
    </div>
  );
};

export default AddToCartDialog;
