/*
 * @Author: Michael Lam 127068684+MichaelLam888@users.noreply.github.com
 * @Date: 2024-05-23 21:40:18
 * @LastEditTime: 2024-05-28 15:09:02
 * @Description: 
 */
import React, { useState, useContext } from 'react';
import '../css/LoginComponent.css'; // Ensure you have a LoginComponent.css file
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import axios from 'axios';
import { AuthContext } from '../context/AuthContext';
import { API_PREFIX } from './ApiConfog';


const LoginComponent = () => {
  const [userAccount, setUserAccount] = useState('');
  const [userPassword, setUserPassword] = useState('');
  const [isPasswordShown, setIsPasswordShown] = useState(false);
  const { t } = useTranslation();
  const [error, setError] = useState('');
  const navigate = useNavigate(); // Import useNavigate
  const { userInfo, login } = useContext(AuthContext);
  const loginApiUrl = `${API_PREFIX}auth/login`;


  if (userInfo) {
    console.log(setIsPasswordShown);
    navigate("/");
  }

  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      const response = await axios.post(loginApiUrl, {
        userAccount,
        userPassword
      });
      const { accessToken, success, message } = response.data;
      if (success) {
        // Store access token in local storage or session storage
        login(accessToken);
        setError();
        navigate('/');
        // Optionally perform any other actions upon successful login
      } else {
        // Display error message if login is unsuccessful
        setError(message);
      }
    } catch (error) {
      // Handle login error, e.g., display an error message to the user
      setError('Login failed. Please try again later.');
      console.error('Login failed:', error);
    }
  };

  return (
    <div className="login-container">

      <div className="login-left">
        <form>
          <h2 className="login-title">Login</h2>
          {error && <p className="error-message">{error}</p>} {/* Conditionally render error message */}
          <p className="login-description">Please contact our sales team if you don't have a account or any login issues</p>
          <div className="login-form-group">
            <label htmlFor="username">ID</label>
            <input type="text" id="username" placeholder="id" value={userAccount} onChange={(e) => setUserAccount(e.target.value)} required />
          </div>
          <div className="login-form-group">
            <label htmlFor="password">Password</label>
            <input type={isPasswordShown ? 'text' : 'password'}
              value={userPassword}
              onChange={(e) => setUserPassword(e.target.value)}
              placeholder="Password"
              required
              autoComplete="on"
            />
          </div>

          <button className="login-button" onClick={handleSubmit}>Login</button>
        </form>
      </div>

    </div>
  );
};

export default LoginComponent;
