import React from 'react';
import '../css/About.css';
import useScrollToAnchor from '../until/useScrollToAnchor';

const About = () => {

  useScrollToAnchor();

  return (
    <div className="about-section" id="about-div">
      <div style={{ padding: '20px', fontFamily: 'Arial, sans-serif' }}>
        <h1>About Us</h1>
        <p>
          Welcome to Master Select Group, where digital innovation meets marketing excellence.
        </p>
        <p>
          At Master Select Group, we pride ourselves on being a leading digital marketing agency dedicated to elevating brands and influencers (KOLs) to their highest potential. With a team of passionate professionals, we specialize in creating dynamic marketing strategies that drive engagement, boost visibility, and achieve remarkable results.
        </p>
        <h2>Our Mission</h2>
        <p>
          Our mission is to empower our clients by delivering tailored digital marketing solutions that resonate with their target audience. We aim to bridge the gap between brands and their consumers, ensuring a seamless connection through innovative and effective marketing techniques.
        </p>
        <h2>What We Do</h2>
        <ul>
          <li>
            <strong>Brand Strategy:</strong> We develop comprehensive brand strategies that align with your business goals and resonate with your audience. From brand identity to positioning, we ensure your brand stands out in a crowded marketplace.
          </li>
          <li>
            <strong>Content Creation:</strong> Our team of creative experts crafts compelling content that captures attention and tells your brand story. Whether it's blog posts, videos, or social media content, we create engaging materials that drive interaction.
          </li>
          <li>
            <strong>Social Media Management:</strong> We manage your social media presence, creating and curating content that engages your followers and grows your community. Our strategies are designed to increase brand awareness and foster customer loyalty.
          </li>
          <li>
            <strong>Influencer Marketing:</strong> We connect brands with the right influencers to amplify their message. Our extensive network of KOLs ensures that your brand reaches its intended audience effectively.
          </li>
          <li>
            <strong>Digital Advertising:</strong> We design and implement targeted advertising campaigns that deliver measurable results. From PPC to social media ads, we ensure your marketing budget is spent efficiently.
          </li>
          <li>
            <strong>Analytics & Reporting:</strong> We believe in the power of data. Our detailed analytics and reporting provide insights into your campaign performance, helping you make informed decisions and optimize your marketing efforts.
          </li>
        </ul>
        <h2>Our Approach</h2>
        <p>
          At Master Select Group, we take a client-centric approach, focusing on understanding your unique needs and objectives. We believe in building long-term partnerships based on trust, transparency, and mutual success. Our strategies are data-driven and result-oriented, ensuring that every campaign we run delivers tangible benefits for your brand.
        </p>
        <h2>Why Choose Us?</h2>
        <ul>
          <li><strong>Expertise:</strong> Our team comprises seasoned professionals with years of experience in the digital marketing landscape.</li>
          <li><strong>Innovation:</strong> We stay ahead of the curve by leveraging the latest technologies and trends in digital marketing.</li>
          <li><strong>Customization:</strong> We provide personalized marketing solutions tailored to your specific needs and goals.</li>
          <li><strong>Results:</strong> We are committed to delivering measurable results that contribute to your business growth.</li>
        </ul>
        <h2>Contact Us</h2>
        <p>
          Ready to elevate your brand? Get in touch with us today and discover how Master Select Group can transform your digital presence.
        </p>
      </div>
    </div>
  );
};

export default About;
