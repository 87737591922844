/*
 * @Author: Michael Lam 127068684+MichaelLam888@users.noreply.github.com
 * @Date: 2024-05-25 17:03:06
 * @LastEditTime: 2024-05-28 11:49:17
 * @Description: 
 */
import React from 'react';
import '../css/ConfirmationDialog.css'; // Assuming you have corresponding CSS file




const AddToCartDialog = ({ item, onCancel }) => {

  console.log(item);


  return (

    <div className="confirmation-overlay">

      <div className="confirmation-dialog">
        <h2>訂單內容</h2>

        {item.map((record, index) => (
          <div key={index} className="product">
            <p><strong></strong>{record.fullProductName} (單個所需積分: {record.unitPrice}) x {record.qty} <strong></strong></p>
          </div>
        ))}





        <div className="button-container">
          <button onClick={onCancel}>返回</button>
        </div>
      </div>
    </div>
  );
};

export default AddToCartDialog;
