import React from 'react';
import { Link } from 'react-router-dom';
import '../css/Nav.css'; // Make sure to create a corresponding CSS file


const Nav = () => {



  return (
    <nav>

      <Link to="/">
        <div className="nav-logo">
          <img src="/images/logo.jpg" alt="Logo" />
        </div>
      </Link>

      <div className="nav-content">
        <ul className="nav-links">
          <li><a href="/aboutUs">About MSG</a></li>
          <li><a href="/products">Products</a></li>
          <li><a href="/contactUs">Enquire</a></li>
          {/*<li><a href="/products">List</a></li>*/}
        </ul>
      </div>

      <hr className="hr-solid"></hr>
    </nav>
  );
};

export default Nav;
