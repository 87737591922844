import React, { useRef } from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

function ImageSlider({ images }) {
  const sliderRef = useRef(null);

  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  const handleDotButtonClick = (index) => {
    sliderRef.current.slickGoTo(index); // Go to the slide with the specified index
  };

  if (!images || !Array.isArray(images) || images.length === 0) {
    return null; // Return null if images are not available or empty
  }

  return (
    <div>
    <div className="image-slider-container">
      <Slider ref={sliderRef} {...settings}>
        {images.map((imageUrl, index) => (
          <div key={index}>
            <img src={imageUrl.link} alt={`${index}`} />
          </div>
        ))}
      </Slider>
    </div>
    <div className="dot-buttons">
        {images.map((imageUrl, index) => (
          <button key={index} onClick={() => handleDotButtonClick(index)}></button>
        ))}
      </div>
    </div>
  );
}

export default ImageSlider;
