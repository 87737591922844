/*
 * @Author: Michael Lam 127068684+MichaelLam888@users.noreply.github.com
 * @Date: 2024-05-09 17:52:16
 * @LastEditTime: 2024-07-11 18:28:26
 * @Description: 
 */
import React from 'react';
import '../css/Contact.css';
import useScrollToAnchor from '../until/useScrollToAnchor';

const Contact = () => {

  useScrollToAnchor();

  return (
    <div className="contact-div">
      <h3>Contact Us</h3>
      <div className="split-component">
        <div className="left-column">
          <img src="/images/contactUs.jpg" alt="contact us" />
        </div>
        <div className="right-column">

          <div className="contact_content">
            <p>General</p>
            <a href="mailto:info@msgdm.com"><p>info@msgdm.com</p></a>
          </div>

          <div className="contact_content">
            <p>Sales</p>
            <a href="mailto:cs@msgdm.com"><p>cs@msgdm.com</p></a>
          </div>

          <div className="contact_content">
            <p>Support</p>
            <a href="mailto:support@msgdm.com"><p>support@msgdm.com</p></a>
          </div>


        </div>


      </div>
    </div>
  );
};

export default Contact;
