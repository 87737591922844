/*
 * @Author: Michael Lam 127068684+MichaelLam888@users.noreply.github.com
 * @Date: 2024-01-14 12:05:35
 * @LastEditTime: 2024-05-31 16:50:59
 * @Description: 
 */
import React from 'react';
import Header from '../components/Header';
import Nav from '../components/Nav';
import BannerAboutUs2 from '../components/Banner_aboutUs2';
import '../i18n/i18n'
import Footer from '../components/Footer';
import About from '../components/About';




const AboutUs = () => {



  return (
    <div>
      <Header />
      <Nav />

      <BannerAboutUs2 />
      <About />
      <Footer />
    </div>
  );
};

export default AboutUs;


