/*
 * @Author: Michael Lam 127068684+MichaelLam888@users.noreply.github.com
 * @Date: 2024-01-14 12:05:35
 * @LastEditTime: 2024-05-31 12:27:30
 * @Description: 
 */
import React from 'react';
import Header from '../components/Header';
import Nav from '../components/Nav';
import '../i18n/i18n'
import Footer from '../components/Footer';
import ProductDetails from '../components/ProductDetails';
import Featured from '../components/FeaturedFull';

const Products = () => {



  return (
    <div>
      <Header />
      <Nav />
      <ProductDetails />
      <hr className="hr-solid"></hr>
      <Featured />
      <Footer />
    </div>
  );
};

export default Products;


