/*
 * @Author: Michael Lam 127068684+MichaelLam888@users.noreply.github.com
 * @Date: 2024-05-27 22:03:39
 * @LastEditTime: 2024-05-28 18:30:13
 * @Description: 
 */
import React, { useState, useEffect } from 'react';
import OrderDetialDialog from './OrderDetialDialog'
import '../css/RecordsComponent.css'; // Import your CSS file here
import { API_PREFIX } from './ApiConfog';

const RecordsComponent = (userInfo) => {
  const [selectedButton, setSelectedButton] = useState();
  const [showOrderDetial, setShowOrderDetial] = useState(false);
  const token = localStorage.getItem("accessToken");
  const [records, setRecords] = useState([]);
  const [orderItems, setOrderItems] = useState([]);
  const [error, setError] = useState(null);
  const getRecordsApiUrl = `${API_PREFIX}user/getRecords/`;
  const getOrderItemsApiUrl = `${API_PREFIX}user/getOrderItems/`;

  const handleButtonClick = (buttonName) => {
    setSelectedButton(buttonName);
  };

  const handleCancelConfirmation = () => {
    setShowOrderDetial(false);
  };


  useEffect(() => {
    //console.log(userInfo);
    const fetchRecords = async () => {
      try {
        const response = await fetch(getRecordsApiUrl + userInfo.userInfo.userId, {
          headers: {
            Authorization: `Bearer ${token}`, // Include the token in the Authorization header
          }
        });
        if (!response.ok) {
          throw new Error('Failed to fetch records');
        }
        const data = await response.json();
        console.log(data);
        setRecords(data);
      } catch (error) {
        setError(error.message);
      }
    };
    fetchRecords();
  }, [userInfo]); // Make sure to include myUserId in the dependency array


  const handleCheckOrder = (orderId) => {
    //console.log(orderId);
    const fetchData = async () => {
      try {
        const response = await fetch(getOrderItemsApiUrl + orderId, {
          headers: {
            Authorization: `Bearer ${token}`, // Include the token in the Authorization header
          }
        }); // Replace with your API endpoint
        if (!response.ok) {
          throw new Error('Fail to fetch data');
        }
        const jsonData = await response.json();
        console.log(jsonData)
        setOrderItems(jsonData)
      } catch (error) {
        setError(error);
      }
    };
    fetchData();
    //console.log(orderItems)
    setShowOrderDetial(true);
  }


  return (
    <div>
      <div className="top-buttons-container">
        <div>
          <button
            className={`top-button ${selectedButton === 'button1' ? 'selected' : ''}`}
            onClick={() => handleButtonClick('button1')}
          >
            禮品換領紀錄
          </button>
          <span className="separator">|</span>
          <button
            className={`top-button ${selectedButton === 'button2' ? 'selected' : ''}`}
            onClick={() => handleButtonClick('button2')}
          >
            積分兌換紀錄
          </button>
        </div>

      </div>
      <div>

        {records && selectedButton === 'button1' && (
          <div>
            <div className="records-container">
              {records.orderRecords.map((record, index) => (
                <div key={index} className="record" onClick={() => handleCheckOrder(record.id)}>
                  <div className='points-record-div'>
                    <p>訂單號碼 :  {record.id}</p>
                    <div className='points-record-value'>
                      <img src="/images/greyIcon.png" alt="Icon" />
                      -{record.totalPrice}
                    </div>
                  </div>
                  <p>{record.createTime}</p>
                  <div className="record-break-line" />
                </div>
              ))}
            </div>
            
          </div>
        )}


        {records && selectedButton === 'button2' && (
          <div>
            <div className="records-container">
              {records.pointsRecords.map((record, index) => (
                <div key={index} className="record">
                  <div className='points-record-div'>
                    <p>扣除GDMASK積分 {record.convertGdPoints}</p>
                    <div className='points-record-value'>
                      <img src="/images/greyIcon.png" alt="Icon" />
                      +{record.toGoodclubPoints}
                    </div>
                  </div>
                  <p>{record.createTime}</p>
                  <div className="record-break-line" />
                </div>
              ))}
            </div>

          </div>
        )}

        {showOrderDetial && orderItems && (
          <OrderDetialDialog
            item={orderItems}
            onCancel={handleCancelConfirmation}
          />
        )}
      </div>
    </div>
  );
};

export default RecordsComponent;
