/*
 * @Author: Michael Lam 127068684+MichaelLam888@users.noreply.github.com
 * @Date: 2024-05-23 21:40:18
 * @LastEditTime: 2024-05-31 12:18:45
 * @Description: 
 */
import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';

import Home from './pages/Home'; // Your home component
import ProductList from './pages/ProductList';
import AboutUs from './pages/AboutUs';
import ContactUs from './pages/ContactUs';
import Terms from './pages/Terms';
import Login from './pages/Login';
import Products from './pages/Products';
import Cart from './pages/Cart';
import { AuthProvider } from './context/AuthContext';
import './minWidth.css';



const App = () => {



  return (
    <AuthProvider>
      <Router>
        <Routes>
          <Route path="/login" element={<Login />} />
          <Route path="/" element={<Home />} />
          <Route path="/aboutUs" element={<AboutUs />} />
          <Route path="/terms" element={<Terms />} />
          <Route path="/cart" element={<Cart />} />
          <Route path="/contactUs" element={<ContactUs />} />
          <Route path="/products" element={<ProductList />} />
          <Route path="/products/:id" element={<Products />} />
        </Routes>
      </Router>
    </AuthProvider>
  );
};

export default App;
