/*
 * @Author: Michael Lam 127068684+MichaelLam888@users.noreply.github.com
 * @Date: 2024-05-25 17:03:06
 * @LastEditTime: 2024-05-26 17:49:49
 * @Description: 
 */
import React from 'react';
import '../css/ConfirmationDialog.css'; // Assuming you have corresponding CSS file


const AddToCartDialog = ({ cartData, onCancel, onConfirm, submitSuccess,handleBack }) => {

  const productsDivs = cartData.items.map(item => {
    // Add a "qty" key with a default value of 1 to each product
    //const productWithQty = { ...product, qty: 1 }
    return (
      <div key={item.id} className="product">
        <p><strong>{item.fullProductName}</strong> (單個所需積分: {item.unitPrice}) x <strong>{item.qty}</strong></p>
      </div>
    );
  });

  return (
    
    <div className="confirmation-overlay">
      {!submitSuccess && (
        <div className="confirmation-dialog">
          <h2>確認提交換領</h2>
          {productsDivs}
          <p><strong>總共需要積分 <span className='Confirm-total-Price'>{cartData.cartPrice}</span></strong></p>
          <div className="button-container">
            <button onClick={onCancel}>返回</button>
            <button onClick={onConfirm}>確認</button>
          </div>
        </div>)}

      {submitSuccess && (
        <div className="confirmation-dialog">
          <p>換領成功，我們會有專人聯絡你，謝謝</p>
          <div className="button-container">
            <button onClick={handleBack}>返回</button>
          </div>
        </div>)}
    </div>
  );
};

export default AddToCartDialog;
