import React from 'react';
import Header from '../components/Header';
import '../i18n/i18n'
import Footer from '../components/Footer';
import LoginComponent from '../components/LoginComponent';
import Nav from '../components/Nav';


const Login = () => {



  return (
    <div>
      <Header />
      <Nav />
      <LoginComponent />
      <Footer />
    </div>
  );
};

export default Login;


