// AuthContext.js
import React, { createContext, useContext, useState, useEffect } from 'react';
import { jwtDecode } from 'jwt-decode';

export const AuthContext = createContext();
export const useAuth = () => useContext(AuthContext);

export const AuthProvider = ({ children }) => {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [userInfo, setUserInfo] = useState(null);

useEffect(() => {
    //console.log("Token Check");
    const accessToken = localStorage.getItem('accessToken');
    if (accessToken) {
      const decodedToken = jwtDecode(accessToken);
      const currentTime = Date.now() / 1000;
      if (decodedToken.exp > currentTime) {
        // Token is valid, set user
        setUserInfo({
          userId: decodedToken.id,
          userName: decodedToken.userName,
          userIntegral : decodedToken.userIntegral,
          goodclubPoints : decodedToken.goodclubPoints
        });
        setIsLoggedIn(true);
      } else {
        // Token is expired, clear from storage and reset user
        localStorage.removeItem('accessToken');
        userInfo(null);
      }
    }
  }, []);
  
  const login = (token) => {
    localStorage.setItem('accessToken', token);
    setIsLoggedIn(true);
        // Decode token to extract user info
        const decodedToken = jwtDecode(token);
        setUserInfo({
          userId: decodedToken.id,
          userName: decodedToken.userName,
          userIntegral : decodedToken.userIntegral,
          goodclubPoints : decodedToken.goodclubPoints
        });
  };

  const logout = () => {
    setIsLoggedIn(false);
    localStorage.removeItem('accessToken');
    setUserInfo(null);
  };

  return (
    <AuthContext.Provider value={{ isLoggedIn,userInfo, login, logout }}>
      {children}
    </AuthContext.Provider>
  );
};
