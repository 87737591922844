/*
 * @Author: Michael Lam 127068684+MichaelLam888@users.noreply.github.com
 * @Date: 2024-05-09 18:39:49
 * @LastEditTime: 2024-05-28 10:46:27
 * @Description: 
 */
import React, { useState, useEffect, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import '../css/ChatComponent.css'; // Import your page specific CSS file here
import { AuthContext } from '../context/AuthContext';
import DeleteCartDialog from './DeleteCartDialog';
import ConfirmSubmitOrderDialog from './ConfirmSubmitOrderDialog';
import RecordsComponent from './RecordsComponent';
import { API_PREFIX } from './ApiConfog';



const ChartComponent = () => {
  const { userInfo } = useContext(AuthContext);
  const token = localStorage.getItem("accessToken");
  const [cartData, setCartData] = useState({});
  const [error, setError] = useState(null);
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [showSubmitConfirmation, setShowSubmitConfirmation] = useState(false);
  const [conversionSuccess, setConversionSuccess] = useState(false);
  const [submitSuccess, setSubmitSuccess] = useState(false);
  const [itemToDelete, setItemToDelete] = useState(null);
  const deleteCartapiUrl = `${API_PREFIX}user/deleteCart/`;
  const checkCartapiUrl = `${API_PREFIX}user/checkCart/`;
  const confirmOrderapiUrl = `${API_PREFIX}user/confirmOrder`;

  const { t } = useTranslation();

  const handleDelete = (item) => {
    setItemToDelete(item);
    setShowConfirmation(true);
  }

  const handleCancelConfirmation = () => {
    setItemToDelete(null);
    setShowConfirmation(false);
  };

  const handleBack = () => {
    window.location.reload(); // This will refresh the page
  };

  const handleConfirmDelete = (item) => {
    const fetchData = async () => {
      try {
        const response = await fetch(deleteCartapiUrl + userInfo.userId + "/" + item.id, {
          method: "PATCH",
          headers: {
            Authorization: `Bearer ${token}`, // Include the token in the Authorization header
          }
        }); // Replace with your API endpoint
        if (!response.ok) {
          throw new Error('Fail to fetch data');
        }
        const jsonData = await response.json();
        console.log(jsonData);
        setConversionSuccess(true);
      } catch (error) {
        setError(error);
      }
    };
    fetchData();
  }

  useEffect(() => {
    if (userInfo) {
      const fetchData = async () => {
        //console.log(userInfo.userId)
        try {
          const response = await fetch(checkCartapiUrl + userInfo.userId, {
            headers: {
              Authorization: `Bearer ${token}`, // Include the token in the Authorization header
            }
          }); // Replace with your API endpoint
          if (!response.ok) {
            throw new Error('Fail to fetch data');
          }
          const jsonData = await response.json();
          setCartData(jsonData);
        } catch (error) {
          setError(error);
        }
      };
      fetchData();
    }
  }, [userInfo, token]);

  const handleSubmit = () => {
    setShowSubmitConfirmation(true);
  }

  const handleCancelSubmitConfirmation = () => {
    setShowSubmitConfirmation(false);
  };

  const handleConfirmSubmit = async () => {
    try {
      const response = await fetch(confirmOrderapiUrl, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(cartData),
      });

      if (!response.ok) {
        throw new Error("Failed to convert points");
      }

      const result = await response.json();

      if (result.success) {
        setSubmitSuccess(true);
        // Show success message
        //console.log(result);

        //setConversionSuccess(true);
      } else {
        // Handle failure
        throw new Error("Conversion failed");
      }
    } catch (error) {
      console.error("Error:", error);
      // Handle error
    }
  };

  if (error) {
    return (
      <div>
        <h2>{error.message}</h2>
      </div>
    );
  }

  return (

    <div className="cart-container">

      {userInfo && cartData.items && (
        <h2 className="cart-head-title">{t('cart.title')}</h2>)}

      {userInfo && cartData.items === null && (
        <div>
          <h1 className="no-product">暫時沒有禮品</h1>
          <h2 className="cart-head-title"><a href="/products">按此瀏覽精彩禮品</a></h2>
        </div>
      )}

      {userInfo && cartData.items && cartData.items.map((item, index) => (
        <div key={index}>
          <div className='delete_button' onClick={() => handleDelete(item)}>x</div>
          <div className="item-container">
            <div className="cart-left ">
              <img src={item.productImage} alt="Item" className="item-image" />
            </div>
            <div className="cart-right">
              <div className="cart-title">
                <p>{item.productName}</p>
                <p>Qty: {item.qty}</p>
              </div>
              <hr className="cart-break-line" />
              <div className="details">
                <div className="inside-left">
                  <p>{item.fullProductName}</p>
                </div>
                <div className="inside-right">
                  <p className="price">單個所需分數：{item.unitPrice}</p>
                  <p className="subtotal"><img src="/images/greyIcon.png" alt="Icon"></img>{item.totalPrice}</p>
                </div>
              </div>
            </div>
          </div>
          <hr className='break-line-long' />
        </div>
      ))}

      {userInfo && cartData.items && (
        <div className="check-counter">
          <div className="counter-left">
            <p className="counter-title">{t('cart.total_points')}</p>
          </div>
          <div className="counter-right">
            <p className="counter-content"> <img src="/images/greyIcon.png" alt="Icon" className="icon" />{cartData.cartPrice}</p>
          </div>
        </div>
      )}



      {userInfo && (<div>

        {cartData.userPoints > cartData.cartPrice && (
          <div className="button-div">
            <button className="cart-button" onClick={handleSubmit}>{t('cart.submit')}</button>
          </div>
        )}

        {cartData.userPoints < cartData.cartPrice && (
          <div className="button-div">
            <button className="cart-button-grey">積分不足</button>
          </div>
        )}


      </div>)}

      {showConfirmation && (
        <DeleteCartDialog
          item={itemToDelete}
          onCancel={handleCancelConfirmation}
          onConfirm={handleConfirmDelete}
          conversionSuccess={conversionSuccess}
          handleBack={handleBack}
        />
      )}

      {showSubmitConfirmation && (
        <ConfirmSubmitOrderDialog
          cartData={cartData}
          onCancel={handleCancelSubmitConfirmation}
          onConfirm={handleConfirmSubmit}
          submitSuccess={submitSuccess}
          handleBack={handleBack}
        />
      )}

      {userInfo && (<RecordsComponent
        userInfo={userInfo} />)}


    </div>
  );
};

export default ChartComponent;
