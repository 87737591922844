/*
 * @Author: Michael Lam 127068684+MichaelLam888@users.noreply.github.com
 * @Date: 2024-01-14 12:05:35
 * @LastEditTime: 2024-01-15 09:41:04
 * @Description: 
 */
import React from 'react';
import '../css/Banner.css';


const Banner2 = () => {

  return (
    <div>
      <div className="banner-image">
        <img src="/images/About_banner2.png" alt="banner" />
      </div>
    </div>
  );
};

export default Banner2;
