import React from 'react';

const Pagination = ({ productsPerPage, totalProducts, paginate, currentPage }) => {
  const pageNumbers = [];

  for (let i = 1; i <= Math.ceil(totalProducts / productsPerPage); i++) { pageNumbers.push(i); } return (<div
    className="pagination">
    {pageNumbers.map(number => (
      <button key={number} onClick={() => paginate(number)}
        className={`page-item ${currentPage === number ? 'active' : ''}`}
      >
        {number}
      </button>
    ))}
  </div>
  );
};

export default Pagination;