/*
 * @Author: Michael Lam 127068684+MichaelLam888@users.noreply.github.com
 * @Date: 2024-05-08 20:55:24
 * @LastEditTime: 2024-05-28 21:44:42
 * @Description: 
 */

import React, { useState, useContext } from 'react';
import '../css/ProductDetailType2.css';
import { useTranslation } from 'react-i18next';
import ImageSlider from './ImageSlider';
import { Link } from 'react-router-dom';
import { AuthContext } from '../context/AuthContext';
import AddToCartDialog0 from './AddToCartDialog0';

function ProductDetailType0({ product }) {


  const { t } = useTranslation();

  const { userInfo } = useContext(AuthContext);
  //const [selectedProduct, setSelectedProduct] = useState(null); // Corrected state initialization
  const [showConfirmation, setShowConfirmation] = useState(false);


  const handleCancelConfirmation = () => {
    setShowConfirmation(false);
  };



  const handleSubmit = () => {
    setShowConfirmation(true);
  }


  return (
    <div className="container">

      <div className="left">
        <div className="image-gallery">
          <ImageSlider images={product.images} />
        </div>
        <div className="image-buttons">
          <img src="/images/greyIcon.png" alt="Icon" />
          <span className='product-price'>{product.price}Up</span>
        </div>
        <hr />
      </div>

      <div className="right">
        <div className="right-title">
          <h2>{product.title}</h2>
        </div>

        <div className="right-brand">
          <p>Service provided by</p>
          <a href={product.brandLink} target="_blank" rel="noreferrer">{product.brand}</a>
        </div>
        <div className="right-details">
          <p>Details:</p>
          <p>{product.discription}</p>
        </div>

        <div className="right-details">
          <p>Terms:</p>
          <ul className='point-form'>
            {product.terms.split('\n').map((line, index) => (
              <li key={index}> {line}</li>
            ))}
          </ul>
        </div>
      </div>

      <div className="submit-area">
        <div className="submit-title">
          <p>Service Price</p>
        </div>
        <div className="submit-points">
          <img src="/images/greyIcon.png" alt="Icon" />
          <span className='submit-price'>{product.price}Up</span>
        </div>
        {userInfo && (
          <button type="submit" onClick={handleSubmit} className='gradient-button'>Subscribe Now</button>
        )}


        {!userInfo && (
          <Link to="/login">
            <button type="submit" className='gradient-button'>Subscribe Now</button>
          </Link>
        )}
      </div>

      {showConfirmation && (
        <AddToCartDialog0
          product={product}
          onCancel={handleCancelConfirmation}
        />
      )}
    </div>
  );
};

export default ProductDetailType0;



