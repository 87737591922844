import React from 'react';
import useScrollToAnchor from '../until/useScrollToAnchor';
import '../css/TermsComponent.css'; 

const TermsComponent = () => {

  useScrollToAnchor();

  return (
    <div className="terms-section" id="terms-div">
<>
  <p
    style={{
      marginTop: "15.0pt",
      marginRight: "0cm",
      marginBottom: "15.0pt",
      marginLeft: "0cm",
      lineHeight: "normal",
      fontSize: 16,
      fontFamily: '"Aptos",sans-serif',
      textAlign: "justify"
    }}
  >
    <strong>
      <span
        style={{
          fontSize: 18,
          fontFamily: '"PMingLiU",serif',
          color: "#1A1A1A"
        }}
      >
        使用條款
      </span>
    </strong>
  </p>
  <p
    style={{
      marginTop: "0cm",
      marginRight: "0cm",
      marginBottom: "22.5pt",
      marginLeft: "0cm",
      lineHeight: "normal",
      fontSize: 16,
      fontFamily: '"Aptos",sans-serif',
      textAlign: "justify"
    }}
  >
    <span
      style={{ fontSize: 14, fontFamily: '"PMingLiU",serif', color: "#1A1A1A" }}
    >
      在進入和使用本平台及使用本平台提供的服務時，閣下會受到以下使用條款約束。請細閱本使用條款，透過使用
    </span>
    <span
      style={{ fontSize: 14, fontFamily: '"Nunito Sans"', color: "#1A1A1A" }}
    >
      /
    </span>
    <span
      style={{ fontSize: 14, fontFamily: '"PMingLiU",serif', color: "#1A1A1A" }}
    >
      進入平台，即表示閣下同意遵守本使用條款。
    </span>
    <span
      style={{ fontSize: 14, fontFamily: '"Nunito Sans"', color: "#1A1A1A" }}
    >
      <br />
      &nbsp;
    </span>
    <span
      style={{ fontSize: 14, fontFamily: '"PMingLiU",serif', color: "#1A1A1A" }}
    >
      本網站的服務及
    </span>
    <span
      style={{ fontSize: 14, fontFamily: '"Nunito Sans"', color: "#1A1A1A" }}
    >
      /
    </span>
    <span
      style={{ fontSize: 14, fontFamily: '"PMingLiU",serif', color: "#1A1A1A" }}
    >
      或平台內需密碼方可登錄部份僅限於賬戶擁有者使用。閣下不能獲得或試圖獲得未經授權使用關於本平台及
    </span>
    <span
      style={{ fontSize: 14, fontFamily: '"Nunito Sans"', color: "#1A1A1A" }}
    >
      /
    </span>
    <span
      style={{ fontSize: 14, fontFamily: '"PMingLiU",serif', color: "#1A1A1A" }}
    >
      或服務及
    </span>
    <span
      style={{ fontSize: 14, fontFamily: '"Nunito Sans"', color: "#1A1A1A" }}
    >
      /
    </span>
    <span
      style={{ fontSize: 14, fontFamily: '"PMingLiU",serif', color: "#1A1A1A" }}
    >
      或受保護的資料。
    </span>
    <span
      style={{ fontSize: 14, fontFamily: '"Nunito Sans"', color: "#1A1A1A" }}
    >
      <br />
      &nbsp;
    </span>
    <span
      style={{ fontSize: 14, fontFamily: '"PMingLiU",serif', color: "#1A1A1A" }}
    >
      如果閣下未滿
    </span>
    <span
      style={{ fontSize: 14, fontFamily: '"Nunito Sans"', color: "#1A1A1A" }}
    >
      18
    </span>
    <span
      style={{ fontSize: 14, fontFamily: '"PMingLiU",serif', color: "#1A1A1A" }}
    >
      歲，請勿進入或使用本平台或服務。
    </span>
    <span
      style={{ fontSize: 14, fontFamily: '"Nunito Sans"', color: "#1A1A1A" }}
    >
      &nbsp;
    </span>
    <span
      style={{ fontSize: 14, fontFamily: '"PMingLiU",serif', color: "#1A1A1A" }}
    >
      若閣下使用本平台或服務，即表示閣下至少已年滿
    </span>
    <span
      style={{ fontSize: 14, fontFamily: '"Nunito Sans"', color: "#1A1A1A" }}
    >
      18
    </span>
    <span
      style={{ fontSize: 14, fontFamily: '"PMingLiU",serif', color: "#1A1A1A" }}
    >
      歲。
    </span>
    <span
      style={{ fontSize: 14, fontFamily: '"Nunito Sans"', color: "#1A1A1A" }}
    >
      <br />
      &nbsp;
    </span>
    <strong>
      <em>
        <span
          style={{
            fontSize: 14,
            fontFamily: '"PMingLiU",serif',
            color: "#1A1A1A"
          }}
        >
          請注意
        </span>
      </em>
    </strong>
    <strong>
      <em>
        <span
          style={{
            fontSize: 14,
            fontFamily: '"Nunito Sans"',
            color: "#1A1A1A"
          }}
        >
          :&nbsp;
        </span>
      </em>
    </strong>
    <strong>
      <em>
        <span
          style={{
            fontSize: 14,
            fontFamily: '"PMingLiU",serif',
            color: "#1A1A1A"
          }}
        >
          本平台提供的服務目前無法供歐盟居民使用。
        </span>
      </em>
    </strong>
  </p>
  <p
    style={{
      marginTop: "15.0pt",
      marginRight: "0cm",
      marginBottom: "15.0pt",
      marginLeft: "0cm",
      lineHeight: "normal",
      fontSize: 16,
      fontFamily: '"Aptos",sans-serif',
      textAlign: "justify"
    }}
  >
    <strong>
      <span
        style={{ fontSize: 14, fontFamily: '"Nunito Sans"', color: "#1A1A1A" }}
      >
        1
      </span>
    </strong>
    <strong>
      <span
        style={{ fontSize: 14, fontFamily: '"Nunito Sans"', color: "#1A1A1A" }}
      >
        .&nbsp;
      </span>
    </strong>
    <strong>
      <span
        style={{
          fontSize: 14,
          fontFamily: '"PMingLiU",serif',
          color: "#1A1A1A"
        }}
      >
        登記
      </span>
    </strong>
  </p>
  <ol style={{ marginBottom: "0cm", marginTop: "0cm" }} start={1} type="a">
    <li
      style={{
        marginTop: "0cm",
        marginRight: "0cm",
        marginBottom: "8.0pt",
        marginLeft: "0cm",
        lineHeight: "normal",
        fontSize: 16,
        fontFamily: '"Aptos",sans-serif',
        color: "#1A1A1A",
        textAlign: "justify"
      }}
    >
      <span style={{ fontSize: 14, fontFamily: '"PMingLiU",serif' }}>
        閣下可能須預先登記才可進入本網站某些部分
      </span>
      <span style={{ fontSize: 14, fontFamily: '"Nunito Sans"' }}>&nbsp;</span>
      <span style={{ fontSize: 14, fontFamily: '"PMingLiU",serif' }}>
        （「入門分站」）。當登記時，閣下：
      </span>
      <ol style={{ marginBottom: "0cm", marginTop: "0cm" }} start={1} type="i">
        <li
          style={{
            marginTop: "0cm",
            marginRight: "0cm",
            marginBottom: "8.0pt",
            marginLeft: "0cm",
            lineHeight: "normal",
            fontSize: 16,
            fontFamily: '"Aptos",sans-serif',
            color: "#1A1A1A",
            textAlign: "justify"
          }}
        >
          <span style={{ fontSize: 14, fontFamily: '"PMingLiU",serif' }}>
            提供的登記資料必須為準確、完整及最新；
          </span>
        </li>
        <li
          style={{
            marginTop: "0cm",
            marginRight: "0cm",
            marginBottom: "8.0pt",
            marginLeft: "0cm",
            lineHeight: "normal",
            fontSize: 16,
            fontFamily: '"Aptos",sans-serif',
            color: "#1A1A1A",
            textAlign: "justify"
          }}
        >
          <span style={{ fontSize: 14, fontFamily: '"PMingLiU",serif' }}>
            必須妥善保管由我們提供的用戶名稱及密碼；及
          </span>
        </li>
        <li
          style={{
            marginTop: "0cm",
            marginRight: "0cm",
            marginBottom: "8.0pt",
            marginLeft: "0cm",
            lineHeight: "normal",
            fontSize: 16,
            fontFamily: '"Aptos",sans-serif',
            color: "#1A1A1A",
            textAlign: "justify"
          }}
        >
          <span style={{ fontSize: 14, fontFamily: '"PMingLiU",serif' }}>
            授權我們假設任何以閣下之用戶名稱及密碼使用本網站的人士是閣下或已獲得授權代表閣下的人士。
          </span>
        </li>
      </ol>
    </li>
    <li
      style={{
        marginTop: "0cm",
        marginRight: "0cm",
        marginBottom: "8.0pt",
        marginLeft: "0cm",
        lineHeight: "normal",
        fontSize: 16,
        fontFamily: '"Aptos",sans-serif',
        color: "#1A1A1A",
        textAlign: "justify"
      }}
    >
      <span style={{ fontSize: 14, fontFamily: '"PMingLiU",serif' }}>
        閣下確認在使用一些入門分站時，可能受到附加條款及細則管轄。在這情況下，閣下將會相應地收到通知，而且：
      </span>
      <ol style={{ marginBottom: "0cm", marginTop: "0cm" }} start={1} type="i">
        <li
          style={{
            marginTop: "0cm",
            marginRight: "0cm",
            marginBottom: "8.0pt",
            marginLeft: "0cm",
            lineHeight: "normal",
            fontSize: 16,
            fontFamily: '"Aptos",sans-serif',
            color: "#1A1A1A",
            textAlign: "justify"
          }}
        >
          <span style={{ fontSize: 14, fontFamily: '"PMingLiU",serif' }}>
            除了本條款及細則外，該等附加條款及細則亦將適用於閣下對入門分站的使用；及
          </span>
        </li>
        <li
          style={{
            marginTop: "0cm",
            marginRight: "0cm",
            marginBottom: "8.0pt",
            marginLeft: "0cm",
            lineHeight: "normal",
            fontSize: 16,
            fontFamily: '"Aptos",sans-serif',
            color: "#1A1A1A",
            textAlign: "justify"
          }}
        >
          <span style={{ fontSize: 14, fontFamily: '"PMingLiU",serif' }}>
            倘若該等附加條款及細則與本條款及細則不一致，將以該等附加條款及細則為準。
          </span>
        </li>
      </ol>
    </li>
  </ol>
  <p
    style={{
      marginTop: "15.0pt",
      marginRight: "0cm",
      marginBottom: "15.0pt",
      marginLeft: "0cm",
      lineHeight: "normal",
      fontSize: 16,
      fontFamily: '"Aptos",sans-serif',
      textAlign: "justify"
    }}
  >
    <strong>
      <span
        style={{ fontSize: 14, fontFamily: '"Nunito Sans"', color: "#1A1A1A" }}
      >
        2
      </span>
    </strong>
    <strong>
      <span
        style={{ fontSize: 14, fontFamily: '"Nunito Sans"', color: "#1A1A1A" }}
      >
        .&nbsp;
      </span>
    </strong>
    <strong>
      <span
        style={{
          fontSize: 14,
          fontFamily: '"PMingLiU",serif',
          color: "#1A1A1A"
        }}
      >
        費用
      </span>
    </strong>
  </p>
  <ol style={{ marginBottom: "0cm", marginTop: "0cm" }} start={1} type="a">
    <li
      style={{
        marginTop: "0cm",
        marginRight: "0cm",
        marginBottom: "8.0pt",
        marginLeft: "0cm",
        lineHeight: "normal",
        fontSize: 16,
        fontFamily: '"Aptos",sans-serif',
        color: "#1A1A1A",
        textAlign: "justify"
      }}
    >
      <span style={{ fontSize: 14, fontFamily: '"PMingLiU",serif' }}>
        我們保留權利，在任何時間就進入或使用本網站之全部或某些部分：
      </span>
      <ol style={{ marginBottom: "0cm", marginTop: "0cm" }} start={1} type="i">
        <li
          style={{
            marginTop: "0cm",
            marginRight: "0cm",
            marginBottom: "8.0pt",
            marginLeft: "0cm",
            lineHeight: "normal",
            fontSize: 16,
            fontFamily: '"Aptos",sans-serif',
            color: "#1A1A1A",
            textAlign: "justify"
          }}
        >
          <span style={{ fontSize: 14, fontFamily: '"PMingLiU",serif' }}>
            收取費用；及
          </span>
        </li>
        <li
          style={{
            marginTop: "0cm",
            marginRight: "0cm",
            marginBottom: "8.0pt",
            marginLeft: "0cm",
            lineHeight: "normal",
            fontSize: 16,
            fontFamily: '"Aptos",sans-serif',
            color: "#1A1A1A",
            textAlign: "justify"
          }}
        >
          <span style={{ fontSize: 14, fontFamily: '"PMingLiU",serif' }}>
            更改或修訂涵蓋此等收費的條款及細則。
          </span>
        </li>
      </ol>
    </li>
    <li
      style={{
        marginTop: "0cm",
        marginRight: "0cm",
        marginBottom: "8.0pt",
        marginLeft: "0cm",
        lineHeight: "normal",
        fontSize: 16,
        fontFamily: '"Aptos",sans-serif',
        color: "#1A1A1A",
        textAlign: "justify"
      }}
    >
      <span style={{ fontSize: 14, fontFamily: '"PMingLiU",serif' }}>
        上述第
      </span>
      <span style={{ fontSize: 14, fontFamily: '"Nunito Sans"' }}>2</span>
      <span style={{ fontSize: 14, fontFamily: '"Nunito Sans"' }}>(a)</span>
      <span style={{ fontSize: 14, fontFamily: '"PMingLiU",serif' }}>
        條所提及的收費之引入或關於收費之條款及細則的修訂，均被視為（在我們獨有的酌情決定權下）：
      </span>
      <ol style={{ marginBottom: "0cm", marginTop: "0cm" }} start={1} type="i">
        <li
          style={{
            marginTop: "0cm",
            marginRight: "0cm",
            marginBottom: "8.0pt",
            marginLeft: "0cm",
            lineHeight: "normal",
            fontSize: 16,
            fontFamily: '"Aptos",sans-serif',
            color: "#1A1A1A",
            textAlign: "justify"
          }}
        >
          <span style={{ fontSize: 14, fontFamily: '"PMingLiU",serif' }}>
            本條款及細則之修訂；或
          </span>
        </li>
        <li
          style={{
            marginTop: "0cm",
            marginRight: "0cm",
            marginBottom: "8.0pt",
            marginLeft: "0cm",
            lineHeight: "normal",
            fontSize: 16,
            fontFamily: '"Aptos",sans-serif',
            color: "#1A1A1A",
            textAlign: "justify"
          }}
        >
          <span style={{ fontSize: 14, fontFamily: '"PMingLiU",serif' }}>
            管轄入門分站之條款及細則之引入或修訂，而閣下將相應地接獲通知及受該些新或經修訂的條款及細則約束。
          </span>
        </li>
      </ol>
    </li>
  </ol>
  <p
    style={{
      marginTop: "15.0pt",
      marginRight: "0cm",
      marginBottom: "15.0pt",
      marginLeft: "0cm",
      lineHeight: "normal",
      fontSize: 16,
      fontFamily: '"Aptos",sans-serif',
      textAlign: "justify"
    }}
  >
    <strong>
      <span
        style={{ fontSize: 14, fontFamily: '"Nunito Sans"', color: "#1A1A1A" }}
      >
        3
      </span>
    </strong>
    <strong>
      <span
        style={{ fontSize: 14, fontFamily: '"Nunito Sans"', color: "#1A1A1A" }}
      >
        .&nbsp;
      </span>
    </strong>
    <strong>
      <span
        style={{
          fontSize: 14,
          fontFamily: '"PMingLiU",serif',
          color: "#1A1A1A"
        }}
      >
        本網站內容
      </span>
    </strong>
  </p>
  <ol style={{ marginBottom: "0cm", marginTop: "0cm" }} start={1} type="a">
    <li
      style={{
        marginTop: "0cm",
        marginRight: "0cm",
        marginBottom: "8.0pt",
        marginLeft: "0cm",
        lineHeight: "normal",
        fontSize: 16,
        fontFamily: '"Aptos",sans-serif',
        color: "#1A1A1A",
        textAlign: "justify"
      }}
    >
      <span style={{ fontSize: 14, fontFamily: '"PMingLiU",serif' }}>
        閣下確認本網站所載內容受版權及可能涉及的其他知識產權（「知識產權」）所限制。除非閣下在法律範圍下得到明示授權，否則閣下不得親自、或參與或容許任何其他人士直接或間接地作以下的行為：
      </span>
      <ol style={{ marginBottom: "0cm", marginTop: "0cm" }} start={1} type="i">
        <li
          style={{
            marginTop: "0cm",
            marginRight: "0cm",
            marginBottom: "8.0pt",
            marginLeft: "0cm",
            lineHeight: "normal",
            fontSize: 16,
            fontFamily: '"Aptos",sans-serif',
            color: "#1A1A1A",
            textAlign: "justify"
          }}
        >
          <span style={{ fontSize: 14, fontFamily: '"PMingLiU",serif' }}>
            在未得我們事前的書面同意下，銷售、複製、分銷、修改、展示、公開表演任何內容、準備任何基於內容而製成的衍生產品、再張貼或以其他形式使用任何內容以達致任何公開或商業目的；
          </span>
        </li>
        <li
          style={{
            marginTop: "0cm",
            marginRight: "0cm",
            marginBottom: "8.0pt",
            marginLeft: "0cm",
            lineHeight: "normal",
            fontSize: 16,
            fontFamily: '"Aptos",sans-serif',
            color: "#1A1A1A",
            textAlign: "justify"
          }}
        >
          <span style={{ fontSize: 14, fontFamily: '"PMingLiU",serif' }}>
            於任何其他網站上或接駁網絡的電腦環境下使用任何內容以達致任何目的；
          </span>
        </li>
        <li
          style={{
            marginTop: "0cm",
            marginRight: "0cm",
            marginBottom: "8.0pt",
            marginLeft: "0cm",
            lineHeight: "normal",
            fontSize: 16,
            fontFamily: '"Aptos",sans-serif',
            color: "#1A1A1A",
            textAlign: "justify"
          }}
        >
          <span style={{ fontSize: 14, fontFamily: '"PMingLiU",serif' }}>
            對任何包含下載軟件的內容作逆向工程；或
          </span>
        </li>
        <li
          style={{
            marginTop: "0cm",
            marginRight: "0cm",
            marginBottom: "8.0pt",
            marginLeft: "0cm",
            lineHeight: "normal",
            fontSize: 16,
            fontFamily: '"Aptos",sans-serif',
            color: "#1A1A1A",
            textAlign: "justify"
          }}
        >
          <span style={{ fontSize: 14, fontFamily: '"PMingLiU",serif' }}>
            在使用本網站或任何內容時，以其他方式侵犯任何人士的知識產權。
          </span>
        </li>
      </ol>
    </li>
    <li
      style={{
        marginTop: "0cm",
        marginRight: "0cm",
        marginBottom: "8.0pt",
        marginLeft: "0cm",
        lineHeight: "normal",
        fontSize: 16,
        fontFamily: '"Aptos",sans-serif',
        color: "#1A1A1A",
        textAlign: "justify"
      }}
    >
      <span style={{ fontSize: 14, fontFamily: '"PMingLiU",serif' }}>
        除非另有明文規定，否則閣下在本網站所作的或所作與本網站有關的任何事情都不會構成任何知識產權的轉讓或許可閣下行使任何知識產權。
      </span>
    </li>
  </ol>
  <p
    style={{
      marginTop: "15.0pt",
      marginRight: "0cm",
      marginBottom: "15.0pt",
      marginLeft: "0cm",
      lineHeight: "normal",
      fontSize: 16,
      fontFamily: '"Aptos",sans-serif',
      textAlign: "justify"
    }}
  >
    <strong>
      <span
        style={{ fontSize: 14, fontFamily: '"Nunito Sans"', color: "#1A1A1A" }}
      >
        4
      </span>
    </strong>
    <strong>
      <span
        style={{ fontSize: 14, fontFamily: '"Nunito Sans"', color: "#1A1A1A" }}
      >
        .&nbsp;
      </span>
    </strong>
    <strong>
      <span
        style={{
          fontSize: 14,
          fontFamily: '"PMingLiU",serif',
          color: "#1A1A1A"
        }}
      >
        一般使用服務及
      </span>
    </strong>
    <strong>
      <span
        style={{ fontSize: 14, fontFamily: '"Nunito Sans"', color: "#1A1A1A" }}
      >
        /
      </span>
    </strong>
    <strong>
      <span
        style={{
          fontSize: 14,
          fontFamily: '"PMingLiU",serif',
          color: "#1A1A1A"
        }}
      >
        或進入本平台
      </span>
    </strong>
  </p>
  <p
    style={{
      marginTop: "0cm",
      marginRight: "0cm",
      marginBottom: "0cm",
      marginLeft: "0cm",
      lineHeight: "normal",
      fontSize: 16,
      fontFamily: '"Aptos",sans-serif',
      textAlign: "justify"
    }}
  >
    <span
      style={{ fontSize: 14, fontFamily: '"Nunito Sans"', color: "#1A1A1A" }}
    >
      4
    </span>
    <span
      style={{ fontSize: 14, fontFamily: '"Nunito Sans"', color: "#1A1A1A" }}
    >
      .1&nbsp;
    </span>
    <span
      style={{ fontSize: 14, fontFamily: '"PMingLiU",serif', color: "#1A1A1A" }}
    >
      使用平台及
    </span>
    <span
      style={{ fontSize: 14, fontFamily: '"Nunito Sans"', color: "#1A1A1A" }}
    >
      /
    </span>
    <span
      style={{ fontSize: 14, fontFamily: '"PMingLiU",serif', color: "#1A1A1A" }}
    >
      或服務的指引
    </span>
  </p>
  <ol style={{ marginBottom: "0cm", marginTop: "0cm" }} start={1} type="a">
    <li
      style={{
        marginTop: "0cm",
        marginRight: "0cm",
        marginBottom: "8.0pt",
        marginLeft: "0cm",
        lineHeight: "normal",
        fontSize: 16,
        fontFamily: '"Aptos",sans-serif',
        color: "#1A1A1A",
        textAlign: "justify"
      }}
    >
      <span style={{ fontSize: 14, fontFamily: '"PMingLiU",serif' }}>
        我們授予閣下可撤銷、非排他性、不可轉讓和有限的許可，按照本使用條款使用服務及
      </span>
      <span style={{ fontSize: 14, fontFamily: '"Nunito Sans"' }}>/</span>
      <span style={{ fontSize: 14, fontFamily: '"PMingLiU",serif' }}>
        或進入平台。閣下同意遵守我們不定時發布有關使用服務及
      </span>
      <span style={{ fontSize: 14, fontFamily: '"Nunito Sans"' }}>/</span>
      <span style={{ fontSize: 14, fontFamily: '"PMingLiU",serif' }}>
        或進入平台的操作規則、政策、指引、通知和說明，以及對其的任何修改。我們可能會隨時修改這些操作規則、政策、指引、通知和說明，閣下被視為了解這些變更，並在平台發布後受其約束。閣下只能將平台用於自己個人需要，不得將平台用於任何其他目的。
      </span>
    </li>
    <li
      style={{
        marginTop: "0cm",
        marginRight: "0cm",
        marginBottom: "8.0pt",
        marginLeft: "0cm",
        lineHeight: "normal",
        fontSize: 16,
        fontFamily: '"Aptos",sans-serif',
        color: "#1A1A1A",
        textAlign: "justify"
      }}
    >
      <span style={{ fontSize: 14, fontFamily: '"PMingLiU",serif' }}>
        閣下必須確保透過平台提供給我們的所有信息是真實的、準確的、最新的、完整的和沒有誤導性的。
      </span>
    </li>
    <li
      style={{
        marginTop: "0cm",
        marginRight: "0cm",
        marginBottom: "8.0pt",
        marginLeft: "0cm",
        lineHeight: "normal",
        fontSize: 16,
        fontFamily: '"Aptos",sans-serif',
        color: "#1A1A1A",
        textAlign: "justify"
      }}
    >
      <span style={{ fontSize: 14, fontFamily: '"PMingLiU",serif' }}>
        本平台使用曲奇。透過使用及
      </span>
      <span style={{ fontSize: 14, fontFamily: '"Nunito Sans"' }}>/</span>
      <span style={{ fontSize: 14, fontFamily: '"PMingLiU",serif' }}>
        或進入平台或同意本使用條款，代表閣下同意我們根據我們的隱私政策使用曲奇。
      </span>
    </li>
  </ol>
  <p
    style={{
      marginTop: "0cm",
      marginRight: "0cm",
      marginBottom: "0cm",
      marginLeft: "0cm",
      lineHeight: "normal",
      fontSize: 16,
      fontFamily: '"Aptos",sans-serif',
      textAlign: "justify"
    }}
  >
    <span
      style={{ fontSize: 14, fontFamily: '"Nunito Sans"', color: "#1A1A1A" }}
    >
      4
    </span>
    <span
      style={{ fontSize: 14, fontFamily: '"Nunito Sans"', color: "#1A1A1A" }}
    >
      .2&nbsp;
    </span>
    <span
      style={{ fontSize: 14, fontFamily: '"PMingLiU",serif', color: "#1A1A1A" }}
    >
      限制活動
    </span>
  </p>
  <p
    style={{
      marginTop: "0cm",
      marginRight: "0cm",
      marginBottom: "0cm",
      marginLeft: "0cm",
      lineHeight: "normal",
      fontSize: 16,
      fontFamily: '"Aptos",sans-serif',
      textAlign: "justify"
    }}
  >
    <span
      style={{ fontSize: 14, fontFamily: '"PMingLiU",serif', color: "#1A1A1A" }}
    >
      閣下同意並承諾不會：
    </span>
  </p>
  <ol style={{ marginBottom: "0cm", marginTop: "0cm" }} start={1} type="a">
    <li
      style={{
        marginTop: "0cm",
        marginRight: "0cm",
        marginBottom: "8.0pt",
        marginLeft: "0cm",
        lineHeight: "normal",
        fontSize: 16,
        fontFamily: '"Aptos",sans-serif',
        color: "#1A1A1A",
        textAlign: "justify"
      }}
    >
      <span style={{ fontSize: 14, fontFamily: '"PMingLiU",serif' }}>
        冒充任何人或實體或提供錯誤陳述或歪曲與任何人或實體的關繫；
      </span>
    </li>
    <li
      style={{
        marginTop: "0cm",
        marginRight: "0cm",
        marginBottom: "8.0pt",
        marginLeft: "0cm",
        lineHeight: "normal",
        fontSize: 16,
        fontFamily: '"Aptos",sans-serif',
        color: "#1A1A1A",
        textAlign: "justify"
      }}
    >
      <span style={{ fontSize: 14, fontFamily: '"PMingLiU",serif' }}>
        使用平台或服務進行任何非法、欺詐或有害的目的或活動（包括但不限於濫用折扣、促銷或優惠券代碼）
      </span>
      <span style={{ fontSize: 14, fontFamily: '"Nunito Sans"' }}>;</span>
    </li>
    <li
      style={{
        marginTop: "0cm",
        marginRight: "0cm",
        marginBottom: "8.0pt",
        marginLeft: "0cm",
        lineHeight: "normal",
        fontSize: 16,
        fontFamily: '"Aptos",sans-serif',
        color: "#1A1A1A",
        textAlign: "justify"
      }}
    >
      <span style={{ fontSize: 14, fontFamily: '"PMingLiU",serif' }}>
        在平台上進行任何投機、賭博或類似活動；
      </span>
    </li>
    <li
      style={{
        marginTop: "0cm",
        marginRight: "0cm",
        marginBottom: "8.0pt",
        marginLeft: "0cm",
        lineHeight: "normal",
        fontSize: 16,
        fontFamily: '"Aptos",sans-serif',
        color: "#1A1A1A",
        textAlign: "justify"
      }}
    >
      <span style={{ fontSize: 14, fontFamily: '"PMingLiU",serif' }}>
        試圖獲得未經授權進入、篡改或以其他方式干擾、破壞、損壞或損害我們或其他電腦系統或連接到本平台或服務的網絡或我們供應商的技術交付系統，包括但不限於發送病毒、木馬
      </span>
      <span style={{ fontSize: 14, fontFamily: '"Nunito Sans"' }}>&nbsp;</span>
      <span style={{ fontSize: 14, fontFamily: '"PMingLiU",serif' }}>
        、蠕蟲、邏輯炸彈或其他惡意的或技術上有害的材料、超載、氾濫、垃圾郵件、郵件轟炸平台或服務、或腳本創建的內容，以干擾或創造對平台或服務不必要的負擔；
      </span>
    </li>
    <li
      style={{
        marginTop: "0cm",
        marginRight: "0cm",
        marginBottom: "8.0pt",
        marginLeft: "0cm",
        lineHeight: "normal",
        fontSize: 16,
        fontFamily: '"Aptos",sans-serif',
        color: "#1A1A1A",
        textAlign: "justify"
      }}
    >
      <span style={{ fontSize: 14, fontFamily: '"PMingLiU",serif' }}>
        透過本平台或服務發布、宣傳或傳播任何禁用材料；
      </span>
    </li>
    <li
      style={{
        marginTop: "0cm",
        marginRight: "0cm",
        marginBottom: "8.0pt",
        marginLeft: "0cm",
        lineHeight: "normal",
        fontSize: 16,
        fontFamily: '"Aptos",sans-serif',
        color: "#1A1A1A",
        textAlign: "justify"
      }}
    >
      <span style={{ fontSize: 14, fontFamily: '"PMingLiU",serif' }}>
        妨礙他人使用和享用本平台或他人提供的服務；
      </span>
    </li>
    <li
      style={{
        marginTop: "0cm",
        marginRight: "0cm",
        marginBottom: "8.0pt",
        marginLeft: "0cm",
        lineHeight: "normal",
        fontSize: 16,
        fontFamily: '"Aptos",sans-serif',
        color: "#1A1A1A",
        textAlign: "justify"
      }}
    >
      <span style={{ fontSize: 14, fontFamily: '"PMingLiU",serif' }}>
        探測、掃描或測試任何系統或網絡的漏洞，或違反或規避任何安全或身份驗證措施；
      </span>
    </li>
    <li
      style={{
        marginTop: "0cm",
        marginRight: "0cm",
        marginBottom: "8.0pt",
        marginLeft: "0cm",
        lineHeight: "normal",
        fontSize: 16,
        fontFamily: '"Aptos",sans-serif',
        color: "#1A1A1A",
        textAlign: "justify"
      }}
    >
      <span style={{ fontSize: 14, fontFamily: '"PMingLiU",serif' }}>
        除了透過我們目前已公開發布的界面進入本平台外，閣下不能以其他方式進入或搜索（無論是以自動化還是其他方式）或嘗試進入或搜索本平台或服務；
      </span>
    </li>
    <li
      style={{
        marginTop: "0cm",
        marginRight: "0cm",
        marginBottom: "8.0pt",
        marginLeft: "0cm",
        lineHeight: "normal",
        fontSize: 16,
        fontFamily: '"Aptos",sans-serif',
        color: "#1A1A1A",
        textAlign: "justify"
      }}
    >
      <span style={{ fontSize: 14, fontFamily: '"PMingLiU",serif' }}>
        在任何電子郵件或公告中偽造任何
      </span>
      <span style={{ fontSize: 14, fontFamily: '"Nunito Sans"' }}>TCP/IP</span>
      <span style={{ fontSize: 14, fontFamily: '"PMingLiU",serif' }}>
        數據包標題或標題信息的任何部分，或以任何方式使用平台或服務發送更改的、欺騙性的或虛假的來源識別信息；
      </span>
    </li>
    <li
      style={{
        marginTop: "0cm",
        marginRight: "0cm",
        marginBottom: "8.0pt",
        marginLeft: "0cm",
        lineHeight: "normal",
        fontSize: 16,
        fontFamily: '"Aptos",sans-serif',
        color: "#1A1A1A",
        textAlign: "justify"
      }}
    >
      <span style={{ fontSize: 14, fontFamily: '"PMingLiU",serif' }}>
        以任何方式使用或上載任何含有或有理由懷疑含有病毒、破壞性組件、惡意代碼或有害組件的軟件或材料，這些軟件或材料可能會損害或破壞平台的數據或損壞或干擾他人的電腦或手機設備或其平台或服務的運作或侵犯
      </span>
      <span style={{ fontSize: 14, fontFamily: '"Nunito Sans"' }}>
        The Club
      </span>
      <span style={{ fontSize: 14, fontFamily: '"PMingLiU",serif' }}>
        或任何第三方的所有權利、稱號、利益或知識產權；
      </span>
    </li>
    <li
      style={{
        marginTop: "0cm",
        marginRight: "0cm",
        marginBottom: "8.0pt",
        marginLeft: "0cm",
        lineHeight: "normal",
        fontSize: 16,
        fontFamily: '"Aptos",sans-serif',
        color: "#1A1A1A",
        textAlign: "justify"
      }}
    >
      <span style={{ fontSize: 14, fontFamily: '"PMingLiU",serif' }}>
        披露任何個人資料，除非是根據「個人資料（私隱）條例」下，獲得該人事先明確同意；
      </span>
    </li>
    <li
      style={{
        marginTop: "0cm",
        marginRight: "0cm",
        marginBottom: "8.0pt",
        marginLeft: "0cm",
        lineHeight: "normal",
        fontSize: 16,
        fontFamily: '"Aptos",sans-serif',
        color: "#1A1A1A",
        textAlign: "justify"
      }}
    >
      <span style={{ fontSize: 14, fontFamily: '"PMingLiU",serif' }}>
        使用平台複製、存儲、託管、傳輸、發送、使用、發布或分發含有（或鏈接到）任何間諜軟件、電腦病毒、特洛伊木馬、蠕蟲、按鍵記錄器、
      </span>
      <span style={{ fontSize: 14, fontFamily: '"Nunito Sans"' }}>
        rootkit&nbsp;
      </span>
      <span style={{ fontSize: 14, fontFamily: '"PMingLiU",serif' }}>
        或其他惡意電腦軟件；
      </span>
    </li>
    <li
      style={{
        marginTop: "0cm",
        marginRight: "0cm",
        marginBottom: "8.0pt",
        marginLeft: "0cm",
        lineHeight: "normal",
        fontSize: 16,
        fontFamily: '"Aptos",sans-serif',
        color: "#1A1A1A",
        textAlign: "justify"
      }}
    >
      <span style={{ fontSize: 14, fontFamily: '"PMingLiU",serif' }}>
        未經我們明確書面同意，在平台上或與平台有關的地方進行任何系統的或自動化的數據收集活動（包括但不限於刮取、數據挖掘、數據提取和數據收集）
      </span>
      <span style={{ fontSize: 14, fontFamily: '"Nunito Sans"' }}>;</span>
    </li>
    <li
      style={{
        marginTop: "0cm",
        marginRight: "0cm",
        marginBottom: "8.0pt",
        marginLeft: "0cm",
        lineHeight: "normal",
        fontSize: 16,
        fontFamily: '"Aptos",sans-serif',
        color: "#1A1A1A",
        textAlign: "justify"
      }}
    >
      <span style={{ fontSize: 14, fontFamily: '"PMingLiU",serif' }}>
        使用任何機器人、蜘蛛程式或其他自動化手段進入平台或與平台互動；
      </span>
    </li>
    <li
      style={{
        marginTop: "0cm",
        marginRight: "0cm",
        marginBottom: "8.0pt",
        marginLeft: "0cm",
        lineHeight: "normal",
        fontSize: 16,
        fontFamily: '"Aptos",sans-serif',
        color: "#1A1A1A",
        textAlign: "justify"
      }}
    >
      <span style={{ fontSize: 14, fontFamily: '"PMingLiU",serif' }}>
        使用從平台或服務收集的數據進行任何營銷活動（包括但不限於電子郵件營銷、短信營銷、電話營銷和直接郵寄）或聯繫個人、公司或其他人員或實體；
      </span>
    </li>
    <li
      style={{
        marginTop: "0cm",
        marginRight: "0cm",
        marginBottom: "8.0pt",
        marginLeft: "0cm",
        lineHeight: "normal",
        fontSize: 16,
        fontFamily: '"Aptos",sans-serif',
        color: "#1A1A1A",
        textAlign: "justify"
      }}
    >
      <span style={{ fontSize: 14, fontFamily: '"PMingLiU",serif' }}>
        使用平台或服務而不遵守連接電腦網絡的使用政策、不符合適用互聯網的標準和其他適用的法律。
      </span>
    </li>
  </ol>
  <p
    style={{
      marginTop: "0cm",
      marginRight: "0cm",
      marginBottom: "22.5pt",
      marginLeft: "0cm",
      lineHeight: "normal",
      fontSize: 16,
      fontFamily: '"Aptos",sans-serif',
      textAlign: "justify"
    }}
  >
    <span
      style={{ fontSize: 14, fontFamily: '"Nunito Sans"', color: "#1A1A1A" }}
    >
      4
    </span>
    <span
      style={{ fontSize: 14, fontFamily: '"Nunito Sans"', color: "#1A1A1A" }}
    >
      .3&nbsp;
    </span>
    <span
      style={{ fontSize: 14, fontFamily: '"PMingLiU",serif', color: "#1A1A1A" }}
    >
      平台和服務的可用性
    </span>
    <span
      style={{ fontSize: 14, fontFamily: '"Nunito Sans"', color: "#1A1A1A" }}
    >
      <br />
      &nbsp;
    </span>
    <span
      style={{ fontSize: 14, fontFamily: '"PMingLiU",serif', color: "#1A1A1A" }}
    >
      我們可能在沒有任何理由或事先通知的情況下，對平台或任何服務作出全部或部分的升級、修改、暫停或停止提供或刪除，如果任何此類升級、修改、暫停、中斷或刪除令閣下不能進入平台或使用任何服務，我們則不承擔任何責任。
    </span>
  </p>
  <p
    style={{
      marginTop: "0cm",
      marginRight: "0cm",
      marginBottom: "0cm",
      marginLeft: "0cm",
      lineHeight: "normal",
      fontSize: 16,
      fontFamily: '"Aptos",sans-serif',
      textAlign: "justify"
    }}
  >
    <span
      style={{ fontSize: 14, fontFamily: '"Nunito Sans"', color: "#1A1A1A" }}
    >
      4
    </span>
    <span
      style={{ fontSize: 14, fontFamily: '"Nunito Sans"', color: "#1A1A1A" }}
    >
      .4&nbsp;
    </span>
    <span
      style={{ fontSize: 14, fontFamily: '"PMingLiU",serif', color: "#1A1A1A" }}
    >
      監視內容的權利
    </span>
  </p>
  <p
    style={{
      marginTop: "0cm",
      marginRight: "0cm",
      marginBottom: "0cm",
      marginLeft: "0cm",
      lineHeight: "normal",
      fontSize: 16,
      fontFamily: '"Aptos",sans-serif',
      textAlign: "justify"
    }}
  >
    <span
      style={{ fontSize: 14, fontFamily: '"PMingLiU",serif', color: "#1A1A1A" }}
    >
      我們可能，但不一定有義務：
    </span>
  </p>
  <ol style={{ marginBottom: "0cm", marginTop: "0cm" }} start={1} type="a">
    <li
      style={{
        marginTop: "0cm",
        marginRight: "0cm",
        marginBottom: "8.0pt",
        marginLeft: "0cm",
        lineHeight: "normal",
        fontSize: 16,
        fontFamily: '"Aptos",sans-serif',
        color: "#1A1A1A",
        textAlign: "justify"
      }}
    >
      <span style={{ fontSize: 14, fontFamily: '"PMingLiU",serif' }}>
        監視、檢視、刪除或以其他方式控制平台上的任何活動、內容或資料。
      </span>
      <span style={{ fontSize: 14, fontFamily: '"Nunito Sans"' }}>&nbsp;</span>
      <span style={{ fontSize: 14, fontFamily: '"PMingLiU",serif' }}>
        我們可以自行決定對違反這些條款及細則的行為進行調查，並採取認為合適的行動；
      </span>
    </li>
    <li
      style={{
        marginTop: "0cm",
        marginRight: "0cm",
        marginBottom: "8.0pt",
        marginLeft: "0cm",
        lineHeight: "normal",
        fontSize: 16,
        fontFamily: '"Aptos",sans-serif',
        color: "#1A1A1A",
        textAlign: "justify"
      }}
    >
      <span style={{ fontSize: 14, fontFamily: '"PMingLiU",serif' }}>
        根據我們的判斷、阻止或限制任何用戶進入平台及
      </span>
      <span style={{ fontSize: 14, fontFamily: '"Nunito Sans"' }}>/</span>
      <span style={{ fontSize: 14, fontFamily: '"PMingLiU",serif' }}>
        或使用服務，閣下不得規避或繞過或試圖規避或繞過任何實施進入平台的措施；
      </span>
    </li>
    <li
      style={{
        marginTop: "0cm",
        marginRight: "0cm",
        marginBottom: "8.0pt",
        marginLeft: "0cm",
        lineHeight: "normal",
        fontSize: 16,
        fontFamily: '"Aptos",sans-serif',
        color: "#1A1A1A",
        textAlign: "justify"
      }}
    >
      <span style={{ fontSize: 14, fontFamily: '"PMingLiU",serif' }}>
        向有關當局舉報任何涉嫌違反任何適用法律、條例或法令的活動並與有關當局合作；及
      </span>
      <span style={{ fontSize: 14, fontFamily: '"Nunito Sans"' }}>/</span>
      <span style={{ fontSize: 14, fontFamily: '"PMingLiU",serif' }}>或</span>
    </li>
    <li
      style={{
        marginTop: "0cm",
        marginRight: "0cm",
        marginBottom: "8.0pt",
        marginLeft: "0cm",
        lineHeight: "normal",
        fontSize: 16,
        fontFamily: '"Aptos",sans-serif',
        color: "#1A1A1A",
        textAlign: "justify"
      }}
    >
      <span style={{ fontSize: 14, fontFamily: '"PMingLiU",serif' }}>
        隨時要求閣下提供有關使用服務及
      </span>
      <span style={{ fontSize: 14, fontFamily: '"Nunito Sans"' }}>/</span>
      <span style={{ fontSize: 14, fontFamily: '"PMingLiU",serif' }}>
        或進入平台的信息或數據，如果閣下拒絕透露這些信息及
      </span>
      <span style={{ fontSize: 14, fontFamily: '"Nunito Sans"' }}>/</span>
      <span style={{ fontSize: 14, fontFamily: '"PMingLiU",serif' }}>
        或數據或如果我們有合理的理由懷疑閣下提供不準確、誤導性或欺詐性的信息及
      </span>
      <span style={{ fontSize: 14, fontFamily: '"Nunito Sans"' }}>/</span>
      <span style={{ fontSize: 14, fontFamily: '"PMingLiU",serif' }}>
        或數據，我們會行使我們的權利。
      </span>
    </li>
  </ol>
  <p
    style={{
      marginTop: "0cm",
      marginRight: "0cm",
      marginBottom: "0cm",
      marginLeft: "0cm",
      lineHeight: "normal",
      fontSize: 16,
      fontFamily: '"Aptos",sans-serif',
      textAlign: "justify"
    }}
  >
    <span
      style={{ fontSize: 14, fontFamily: '"Nunito Sans"', color: "#1A1A1A" }}
    >
      4
    </span>
    <span
      style={{ fontSize: 14, fontFamily: '"Nunito Sans"', color: "#1A1A1A" }}
    >
      .5&nbsp;
    </span>
    <span
      style={{ fontSize: 14, fontFamily: '"PMingLiU",serif', color: "#1A1A1A" }}
    >
      獲取和披露信息的權利
    </span>
  </p>
  <p
    style={{
      marginTop: "0cm",
      marginRight: "0cm",
      marginBottom: "0cm",
      marginLeft: "0cm",
      lineHeight: "normal",
      fontSize: 16,
      fontFamily: '"Aptos",sans-serif',
      textAlign: "justify"
    }}
  >
    <span
      style={{ fontSize: 14, fontFamily: '"PMingLiU",serif', color: "#1A1A1A" }}
    >
      我們保留進入、閱讀、保留和披露我們認為有必要的任何信息（包括閣下的身份）的權利以作：
    </span>
  </p>
  <ol style={{ marginBottom: "0cm", marginTop: "0cm" }} start={1} type="a">
    <li
      style={{
        marginTop: "0cm",
        marginRight: "0cm",
        marginBottom: "8.0pt",
        marginLeft: "0cm",
        lineHeight: "normal",
        fontSize: 16,
        fontFamily: '"Aptos",sans-serif',
        color: "#1A1A1A",
        textAlign: "justify"
      }}
    >
      <span style={{ fontSize: 14, fontFamily: '"PMingLiU",serif' }}>
        滿足任何適用的法律、法規、法律程序或政府或監管機構的要求；
      </span>
    </li>
    <li
      style={{
        marginTop: "0cm",
        marginRight: "0cm",
        marginBottom: "8.0pt",
        marginLeft: "0cm",
        lineHeight: "normal",
        fontSize: 16,
        fontFamily: '"Aptos",sans-serif',
        color: "#1A1A1A",
        textAlign: "justify"
      }}
    >
      <span style={{ fontSize: 14, fontFamily: '"PMingLiU",serif' }}>
        執行本使用條款，包括調查潛在的違規行為；
      </span>
    </li>
    <li
      style={{
        marginTop: "0cm",
        marginRight: "0cm",
        marginBottom: "8.0pt",
        marginLeft: "0cm",
        lineHeight: "normal",
        fontSize: 16,
        fontFamily: '"Aptos",sans-serif',
        color: "#1A1A1A",
        textAlign: "justify"
      }}
    >
      <span style={{ fontSize: 14, fontFamily: '"PMingLiU",serif' }}>
        偵測、防止或以其他方式處理欺詐、安全或技術問題；
      </span>
    </li>
    <li
      style={{
        marginTop: "0cm",
        marginRight: "0cm",
        marginBottom: "8.0pt",
        marginLeft: "0cm",
        lineHeight: "normal",
        fontSize: 16,
        fontFamily: '"Aptos",sans-serif',
        color: "#1A1A1A",
        textAlign: "justify"
      }}
    >
      <span style={{ fontSize: 14, fontFamily: '"PMingLiU",serif' }}>
        回應用戶支援請求
      </span>
      <span style={{ fontSize: 14, fontFamily: '"Nunito Sans"' }}>;</span>
      <span style={{ fontSize: 14, fontFamily: '"PMingLiU",serif' }}>及</span>
      <span style={{ fontSize: 14, fontFamily: '"Nunito Sans"' }}>/</span>
      <span style={{ fontSize: 14, fontFamily: '"PMingLiU",serif' }}>或</span>
    </li>
    <li
      style={{
        marginTop: "0cm",
        marginRight: "0cm",
        marginBottom: "8.0pt",
        marginLeft: "0cm",
        lineHeight: "normal",
        fontSize: 16,
        fontFamily: '"Aptos",sans-serif',
        color: "#1A1A1A",
        textAlign: "justify"
      }}
    >
      <span style={{ fontSize: 14, fontFamily: '"PMingLiU",serif' }}>保護</span>
      <span style={{ fontSize: 14, fontFamily: '"Nunito Sans"' }}>&nbsp;</span>
      <span style={{ fontSize: 14, fontFamily: '"Nunito Sans"' }}>
        Good Club
      </span>
      <span style={{ fontSize: 14, fontFamily: '"PMingLiU",serif' }}>
        、平台或其使用者及
      </span>
      <span style={{ fontSize: 14, fontFamily: '"Nunito Sans"' }}>/</span>
      <span style={{ fontSize: 14, fontFamily: '"PMingLiU",serif' }}>
        或公眾的權利、財產或安全。
      </span>
    </li>
  </ol>
  <p
    style={{
      marginTop: "0cm",
      marginRight: "0cm",
      marginBottom: "22.5pt",
      marginLeft: "0cm",
      lineHeight: "normal",
      fontSize: 16,
      fontFamily: '"Aptos",sans-serif',
      textAlign: "justify"
    }}
  >
    <span
      style={{ fontSize: 14, fontFamily: '"Nunito Sans"', color: "#1A1A1A" }}
    >
      4
    </span>
    <span
      style={{ fontSize: 14, fontFamily: '"Nunito Sans"', color: "#1A1A1A" }}
    >
      .6&nbsp;
    </span>
    <span
      style={{ fontSize: 14, fontFamily: '"PMingLiU",serif', color: "#1A1A1A" }}
    >
      附加條款
    </span>
    <span
      style={{ fontSize: 14, fontFamily: '"Nunito Sans"', color: "#1A1A1A" }}
    >
      <br />
      &nbsp;
    </span>
    <span
      style={{ fontSize: 14, fontFamily: '"PMingLiU",serif', color: "#1A1A1A" }}
    >
      除了本使用條款之外，如果閣下使用指定的材料和服務，我們或我們指定的承包商提供的最新版本的材料，即會受到這些附加條款及細則的約束。
    </span>
  </p>
  <p
    style={{
      marginTop: "15.0pt",
      marginRight: "0cm",
      marginBottom: "15.0pt",
      marginLeft: "0cm",
      lineHeight: "normal",
      fontSize: 16,
      fontFamily: '"Aptos",sans-serif',
      textAlign: "justify"
    }}
  >
    <strong>
      <span
        style={{ fontSize: 14, fontFamily: '"Nunito Sans"', color: "#1A1A1A" }}
      >
        5
      </span>
    </strong>
    <strong>
      <span
        style={{ fontSize: 14, fontFamily: '"Nunito Sans"', color: "#1A1A1A" }}
      >
        .&nbsp;
      </span>
    </strong>
    <strong>
      <span
        style={{
          fontSize: 14,
          fontFamily: '"PMingLiU",serif',
          color: "#1A1A1A"
        }}
      >
        個人資料及曲奇
      </span>
    </strong>
  </p>
  <ol style={{ marginBottom: "0cm", marginTop: "0cm" }} start={1} type="a">
    <li
      style={{
        marginTop: "0cm",
        marginRight: "0cm",
        marginBottom: "8.0pt",
        marginLeft: "0cm",
        lineHeight: "normal",
        fontSize: 16,
        fontFamily: '"Aptos",sans-serif',
        color: "#1A1A1A",
        textAlign: "justify"
      }}
    >
      <span style={{ fontSize: 14, fontFamily: '"PMingLiU",serif' }}>
        在使用本網站時，閣下可能會向我們提供「個人資料（私隱）條例」內所界定的「個人資料」。閣下享有一些此等個人資料的權利。閣下使用本網站即表示同意我們根據我們的私隱政策聲明使用閣下的個人資料。
      </span>
    </li>
    <li
      style={{
        marginTop: "0cm",
        marginRight: "0cm",
        marginBottom: "8.0pt",
        marginLeft: "0cm",
        lineHeight: "normal",
        fontSize: 16,
        fontFamily: '"Aptos",sans-serif',
        color: "#1A1A1A",
        textAlign: "justify"
      }}
    >
      <span style={{ fontSize: 14, fontFamily: '"PMingLiU",serif' }}>
        當閣下使用本網站時，我們可能將俗稱「曲奇」的資料發送予閣下的電腦及要求閣下的電腦發出它們。閣下不得更改任何從本網站發送至閣下電腦的曲奇。閣下必須確保閣下電腦就本網站任何有關要求而發出的曲奇均為正確及準確。
      </span>
    </li>
    <li
      style={{
        marginTop: "0cm",
        marginRight: "0cm",
        marginBottom: "8.0pt",
        marginLeft: "0cm",
        lineHeight: "normal",
        fontSize: 16,
        fontFamily: '"Aptos",sans-serif',
        color: "#1A1A1A",
        textAlign: "justify"
      }}
    >
      <span style={{ fontSize: 14, fontFamily: '"PMingLiU",serif' }}>
        我們可能自行及
      </span>
      <span style={{ fontSize: 14, fontFamily: '"Nunito Sans"' }}>/</span>
      <span style={{ fontSize: 14, fontFamily: '"PMingLiU",serif' }}>
        或使用第三方供應商
      </span>
      <span style={{ fontSize: 14, fontFamily: '"Nunito Sans"' }}>&nbsp;(</span>
      <span style={{ fontSize: 14, fontFamily: '"PMingLiU",serif' }}>
        例如廣告公司，如
      </span>
      <span style={{ fontSize: 14, fontFamily: '"Nunito Sans"' }}>
        &nbsp;DoubleClick)&nbsp;
      </span>
      <span style={{ fontSize: 14, fontFamily: '"PMingLiU",serif' }}>
        以處理本網站及其他網站的廣告。這些第三方供應商可能會使用閣下到訪本網站及其他網站的有關資料（不包括閣下的姓名、地址、電郵地址或電話號碼），以衡量廣告的效力，以及提供閣下感興趣的產品和服務的廣告。有關
      </span>
      <span style={{ fontSize: 14, fontFamily: '"Nunito Sans"' }}>
        &nbsp;DoubleClick&nbsp;
      </span>
      <span style={{ fontSize: 14, fontFamily: '"PMingLiU",serif' }}>
        如何使用曲奇、其搜集資料慣例，以及停止使用
      </span>
      <span style={{ fontSize: 14, fontFamily: '"Nunito Sans"' }}>
        &nbsp;DoubleClick&nbsp;
      </span>
      <span style={{ fontSize: 14, fontFamily: '"PMingLiU",serif' }}>
        的程序的進一步詳情，請瀏覽
      </span>
      <span style={{ fontSize: 14, fontFamily: '"Nunito Sans"' }}>&nbsp;</span>
      <span style={{ color: "windowtext" }}>
        <a
          href="https://policies.google.com/privacy?gl=hk&hl=zh-HK"
          target="_blank"
          rel="noreferrer"
        >
          <span
            style={{
              fontSize: 14,
              fontFamily: '"Nunito Sans"',
              color: "#0026CA"
            }}
          >
            google.com.hk/policies/privacy/
          </span>
        </a>
      </span>
      <span style={{ fontSize: 14, fontFamily: '"PMingLiU",serif' }}>。</span>
    </li>
    <li
      style={{
        marginTop: "0cm",
        marginRight: "0cm",
        marginBottom: "8.0pt",
        marginLeft: "0cm",
        lineHeight: "normal",
        fontSize: 16,
        fontFamily: '"Aptos",sans-serif',
        color: "#1A1A1A",
        textAlign: "justify"
      }}
    >
      <span style={{ fontSize: 14, fontFamily: '"PMingLiU",serif' }}>
        我們可能在本網站使用由我們及
      </span>
      <span style={{ fontSize: 14, fontFamily: '"Nunito Sans"' }}>/</span>
      <span style={{ fontSize: 14, fontFamily: '"PMingLiU",serif' }}>
        或第三方供應商提供的網站分析服務及工具（例如
      </span>
      <span style={{ fontSize: 14, fontFamily: '"Nunito Sans"' }}>
        Google, Inc.
      </span>
      <span style={{ fontSize: 14, fontFamily: '"PMingLiU",serif' }}>
        提供的
      </span>
      <span style={{ fontSize: 14, fontFamily: '"Nunito Sans"' }}>
        Google Analytics&nbsp;
      </span>
      <span style={{ fontSize: 14, fontFamily: '"PMingLiU",serif' }}>
        （分析））以協助我們監控及分析本網站訪客流量、行為及統計。為此目的，
      </span>
      <span style={{ fontSize: 14, fontFamily: '"Nunito Sans"' }}>
        Google Analytics&nbsp;
      </span>
      <span style={{ fontSize: 14, fontFamily: '"PMingLiU",serif' }}>
        （分析）主要使用儲存非個人識別資料的第一方曲奇，向我們報告用戶在本網站的互動情況。有關
      </span>
      <span style={{ fontSize: 14, fontFamily: '"Nunito Sans"' }}>
        &nbsp;Google Analytics
      </span>
      <span style={{ fontSize: 14, fontFamily: '"PMingLiU",serif' }}>
        （分析）的進一步詳情，請瀏覽
      </span>
      <span style={{ fontSize: 14, fontFamily: '"Nunito Sans"' }}>&nbsp;</span>
      <span style={{ color: "windowtext" }}>
        <a
          href="https://marketingplatform.google.com/about/analytics/"
          target="_blank"
          rel="noreferrer"
        >
          <span
            style={{
              fontSize: 14,
              fontFamily: '"Nunito Sans"',
              color: "#0026CA"
            }}
          >
            www.google.com/analytics/
          </span>
        </a>
      </span>
      <span style={{ fontSize: 14, fontFamily: '"PMingLiU",serif' }}>
        。有關
      </span>
      <span style={{ fontSize: 14, fontFamily: '"Nunito Sans"' }}>
        Google Analytics
      </span>
      <span style={{ fontSize: 14, fontFamily: '"PMingLiU",serif' }}>
        （分析）如何收集及處理資料，請瀏覽
      </span>
      <span style={{ fontSize: 14, fontFamily: '"Nunito Sans"' }}>&nbsp;</span>
      <span style={{ color: "windowtext" }}>
        <a
          href="https://policies.google.com/technologies/partner-sites?hl=zh-HK&gl=hk"
          target="_blank"
          rel="noreferrer"
        >
          <span
            style={{
              fontSize: 14,
              fontFamily: '"Nunito Sans"',
              color: "#0026CA"
            }}
          >
            google.com/policies/privacy/partners/
          </span>
        </a>
      </span>
      <span style={{ fontSize: 14, fontFamily: '"Nunito Sans"' }}>&nbsp;</span>
      <span style={{ fontSize: 14, fontFamily: '"PMingLiU",serif' }}>
        參看「當閣下使用
      </span>
      <span style={{ fontSize: 14, fontFamily: '"Nunito Sans"' }}>
        &nbsp;Google&nbsp;
      </span>
      <span style={{ fontSize: 14, fontFamily: '"PMingLiU",serif' }}>
        合作夥伴的網站或應用程式時，
      </span>
      <span style={{ fontSize: 14, fontFamily: '"Nunito Sans"' }}>
        Google&nbsp;
      </span>
      <span style={{ fontSize: 14, fontFamily: '"PMingLiU",serif' }}>
        如何運用閣下的資料」。
      </span>
    </li>
  </ol>
  <p
    style={{
      marginTop: "15.0pt",
      marginRight: "0cm",
      marginBottom: "15.0pt",
      marginLeft: "0cm",
      lineHeight: "normal",
      fontSize: 16,
      fontFamily: '"Aptos",sans-serif',
      textAlign: "justify"
    }}
  >
    <strong>
      <span
        style={{ fontSize: 14, fontFamily: '"Nunito Sans"', color: "#1A1A1A" }}
      >
        6
      </span>
    </strong>
    <strong>
      <span
        style={{ fontSize: 14, fontFamily: '"Nunito Sans"', color: "#1A1A1A" }}
      >
        .&nbsp;
      </span>
    </strong>
    <strong>
      <span
        style={{
          fontSize: 14,
          fontFamily: '"PMingLiU",serif',
          color: "#1A1A1A"
        }}
      >
        閣下承擔使用本網站之風險
      </span>
    </strong>
  </p>
  <ol style={{ marginBottom: "0cm", marginTop: "0cm" }} start={1} type="a">
    <li
      style={{
        marginTop: "0cm",
        marginRight: "0cm",
        marginBottom: "8.0pt",
        marginLeft: "0cm",
        lineHeight: "normal",
        fontSize: 16,
        fontFamily: '"Aptos",sans-serif',
        color: "#1A1A1A",
        textAlign: "justify"
      }}
    >
      <span style={{ fontSize: 14, fontFamily: '"PMingLiU",serif' }}>
        閣下自行承擔使用本網站之風險。閣下必須評估及承擔所有與使用任何內容有關的風險，包括依賴任何內容之準確性、完整性或有用性。尤其是由本網站或透過本網站提供有關公司、投資或證券的資料，僅供參考。關於任何內容，閣下應自行尋求獨立意見。
      </span>
    </li>
    <li
      style={{
        marginTop: "0cm",
        marginRight: "0cm",
        marginBottom: "8.0pt",
        marginLeft: "0cm",
        lineHeight: "normal",
        fontSize: 16,
        fontFamily: '"Aptos",sans-serif',
        color: "#1A1A1A",
        textAlign: "justify"
      }}
    >
      <span style={{ fontSize: 14, fontFamily: '"PMingLiU",serif' }}>
        我們將盡力提供一個既方便又具功能性的網站，但我們不能保證內容完全無誤或本網站或操作本網站之伺服器完全沒載有任何病毒或其他有害成分。
      </span>
    </li>
    <li
      style={{
        marginTop: "0cm",
        marginRight: "0cm",
        marginBottom: "8.0pt",
        marginLeft: "0cm",
        lineHeight: "normal",
        fontSize: 16,
        fontFamily: '"Aptos",sans-serif',
        color: "#1A1A1A",
        textAlign: "justify"
      }}
    >
      <span style={{ fontSize: 14, fontFamily: '"PMingLiU",serif' }}>
        雖然我們會盡合理的努力保存內容，但我們並不承諾就內容提供支援或維護服務。我們強烈建議閣下對閣下在本網站張貼、保存或使用的內容進行備份。
      </span>
    </li>
    <li
      style={{
        marginTop: "0cm",
        marginRight: "0cm",
        marginBottom: "8.0pt",
        marginLeft: "0cm",
        lineHeight: "normal",
        fontSize: 16,
        fontFamily: '"Aptos",sans-serif',
        color: "#1A1A1A",
        textAlign: "justify"
      }}
    >
      <span style={{ fontSize: 14, fontFamily: '"PMingLiU",serif' }}>
        倘若閣下因使用本網站以致需要維修或更換財產、材料、設備或數據，我們將不會承擔有關費用。
      </span>
    </li>
    <li
      style={{
        marginTop: "0cm",
        marginRight: "0cm",
        marginBottom: "8.0pt",
        marginLeft: "0cm",
        lineHeight: "normal",
        fontSize: 16,
        fontFamily: '"Aptos",sans-serif',
        color: "#1A1A1A",
        textAlign: "justify"
      }}
    >
      <span style={{ fontSize: 14, fontFamily: '"PMingLiU",serif' }}>
        在不局限上文的原則下，本網站的一切均以「現狀」情況及「當時可供使用」的情況提供，並不包含任何形式（不論明示或暗示）的保證或條件，包括但不限於任何隱含的有關可銷售性、對特殊用途的適用性或非侵權性的保證。在任何適用法律可容許的範圍內，我們免除所有聲明及保證。
      </span>
    </li>
    <li
      style={{
        marginTop: "0cm",
        marginRight: "0cm",
        marginBottom: "8.0pt",
        marginLeft: "0cm",
        lineHeight: "normal",
        fontSize: 16,
        fontFamily: '"Aptos",sans-serif',
        color: "#1A1A1A",
        textAlign: "justify"
      }}
    >
      <span style={{ fontSize: 14, fontFamily: '"PMingLiU",serif' }}>
        倘若司法管轄區不容許以上第
      </span>
      <span style={{ fontSize: 14, fontFamily: '"Nunito Sans"' }}>7(e)</span>
      <span style={{ fontSize: 14, fontFamily: '"PMingLiU",serif' }}>
        條的隱含保證的免除，但卻容許有一定最大程度的責任限制，則我們的保證會限制至該程度。
      </span>
    </li>
  </ol>
  <p
    style={{
      marginTop: "15.0pt",
      marginRight: "0cm",
      marginBottom: "15.0pt",
      marginLeft: "0cm",
      lineHeight: "normal",
      fontSize: 16,
      fontFamily: '"Aptos",sans-serif',
      textAlign: "justify"
    }}
  >
    <strong>
      <span
        style={{ fontSize: 14, fontFamily: '"Nunito Sans"', color: "#1A1A1A" }}
      >
        7
      </span>
    </strong>
    <strong>
      <span
        style={{ fontSize: 14, fontFamily: '"Nunito Sans"', color: "#1A1A1A" }}
      >
        .&nbsp;
      </span>
    </strong>
    <strong>
      <span
        style={{
          fontSize: 14,
          fontFamily: '"PMingLiU",serif',
          color: "#1A1A1A"
        }}
      >
        通告
      </span>
    </strong>
  </p>
  <p
    style={{
      marginTop: "0cm",
      marginRight: "0cm",
      marginBottom: "0cm",
      marginLeft: "0cm",
      lineHeight: "normal",
      fontSize: 16,
      fontFamily: '"Aptos",sans-serif',
      textAlign: "justify"
    }}
  >
    <span
      style={{ fontSize: 14, fontFamily: '"PMingLiU",serif', color: "#1A1A1A" }}
    >
      我們會在本網站內張貼任何必須給予閣下的通告。閣下同意：
    </span>
  </p>
  <ol style={{ marginBottom: "0cm", marginTop: "0cm" }} start={1} type="i">
    <li
      style={{
        marginTop: "0cm",
        marginRight: "0cm",
        marginBottom: "8.0pt",
        marginLeft: "0cm",
        lineHeight: "normal",
        fontSize: 16,
        fontFamily: '"Aptos",sans-serif',
        color: "#1A1A1A",
        textAlign: "justify"
      }}
    >
      <span style={{ fontSize: 14, fontFamily: '"PMingLiU",serif' }}>
        查看本網站上的通告；及
      </span>
    </li>
    <li
      style={{
        marginTop: "0cm",
        marginRight: "0cm",
        marginBottom: "8.0pt",
        marginLeft: "0cm",
        lineHeight: "normal",
        fontSize: 16,
        fontFamily: '"Aptos",sans-serif',
        color: "#1A1A1A",
        textAlign: "justify"
      }}
    >
      <span style={{ fontSize: 14, fontFamily: '"PMingLiU",serif' }}>
        當通告張貼於本網站時，即視為閣下已收到該通告。
      </span>
    </li>
  </ol>
  <p
    style={{
      marginTop: "15.0pt",
      marginRight: "0cm",
      marginBottom: "15.0pt",
      marginLeft: "0cm",
      lineHeight: "normal",
      fontSize: 16,
      fontFamily: '"Aptos",sans-serif',
      textAlign: "justify"
    }}
  >
    <strong>
      <span
        style={{ fontSize: 14, fontFamily: '"Nunito Sans"', color: "#1A1A1A" }}
      >
        8
      </span>
    </strong>
    <strong>
      <span
        style={{ fontSize: 14, fontFamily: '"Nunito Sans"', color: "#1A1A1A" }}
      >
        .&nbsp;
      </span>
    </strong>
    <strong>
      <span
        style={{
          fontSize: 14,
          fontFamily: '"PMingLiU",serif',
          color: "#1A1A1A"
        }}
      >
        一般事項
      </span>
    </strong>
  </p>
  <ol style={{ marginBottom: "0cm", marginTop: "0cm" }} start={1} type="a">
    <li
      style={{
        marginTop: "0cm",
        marginRight: "0cm",
        marginBottom: "8.0pt",
        marginLeft: "0cm",
        lineHeight: "normal",
        fontSize: 16,
        fontFamily: '"Aptos",sans-serif',
        color: "#1A1A1A",
        textAlign: "justify"
      }}
    >
      <span style={{ fontSize: 14, fontFamily: '"PMingLiU",serif' }}>
        倘若本條款及細則中任何部分在法律上不能執行，則該部分會於法律容許的最大可能範圍內生效，而其他部分則仍完全有效力及作用。
      </span>
    </li>
    <li
      style={{
        marginTop: "0cm",
        marginRight: "0cm",
        marginBottom: "8.0pt",
        marginLeft: "0cm",
        lineHeight: "normal",
        fontSize: 16,
        fontFamily: '"Aptos",sans-serif',
        color: "#1A1A1A",
        textAlign: "justify"
      }}
    >
      <span style={{ fontSize: 14, fontFamily: '"PMingLiU",serif' }}>
        本條款及細則受香港法律所管轄。閣下當不可撤回地接受香港法院的專屬司法管轄權。
      </span>
    </li>
    <li
      style={{
        marginTop: "0cm",
        marginRight: "0cm",
        marginBottom: "8.0pt",
        marginLeft: "0cm",
        lineHeight: "normal",
        fontSize: 16,
        fontFamily: '"Aptos",sans-serif',
        color: "#1A1A1A",
        textAlign: "justify"
      }}
    >
      <span style={{ fontSize: 14, fontFamily: '"PMingLiU",serif' }}>
        由於閣下使用或被指稱使用本網站或內容，或因閣下違反本條款及細則所引起的任何索償、訴訟或索求，包括但不限於合理法律及會計費用，閣下同意代我們抗辯，向我們、我們的人員工、董事、職員及代理人作出彌償及使其免受損害。
      </span>
    </li>
    <li
      style={{
        marginTop: "0cm",
        marginRight: "0cm",
        marginBottom: "8.0pt",
        marginLeft: "0cm",
        lineHeight: "normal",
        fontSize: 16,
        fontFamily: '"Aptos",sans-serif',
        color: "#1A1A1A",
        textAlign: "justify"
      }}
    >
      <span style={{ fontSize: 14, fontFamily: '"PMingLiU",serif' }}>
        本條款及細則以其英文本為準，中文本
      </span>
      <span style={{ fontSize: 14, fontFamily: '"Nunito Sans"' }}>(</span>
      <span style={{ fontSize: 14, fontFamily: '"PMingLiU",serif' }}>如有</span>
      <span style={{ fontSize: 14, fontFamily: '"Nunito Sans"' }}>)</span>
      <span style={{ fontSize: 14, fontFamily: '"PMingLiU",serif' }}>
        僅供參考。
      </span>
    </li>
  </ol>
  <p
    style={{
      marginTop: "15.0pt",
      marginRight: "0cm",
      marginBottom: "15.0pt",
      marginLeft: "0cm",
      lineHeight: "normal",
      fontSize: 16,
      fontFamily: '"Aptos",sans-serif',
      textAlign: "justify"
    }}
  >
    <strong>
      <span
        style={{ fontSize: 14, fontFamily: '"Nunito Sans"', color: "#1A1A1A" }}
      >
        9
      </span>
    </strong>
    <strong>
      <span
        style={{ fontSize: 14, fontFamily: '"Nunito Sans"', color: "#1A1A1A" }}
      >
        .&nbsp;
      </span>
    </strong>
    <strong>
      <span
        style={{
          fontSize: 14,
          fontFamily: '"PMingLiU",serif',
          color: "#1A1A1A"
        }}
      >
        保險產品及服務
      </span>
    </strong>
  </p>
  <p
    style={{
      marginTop: "0cm",
      marginRight: "0cm",
      marginBottom: "22.5pt",
      marginLeft: "0cm",
      lineHeight: "normal",
      fontSize: 16,
      fontFamily: '"Aptos",sans-serif',
      textAlign: "justify"
    }}
  >
    <span
      style={{ fontSize: 14, fontFamily: '"Nunito Sans"', color: "#1A1A1A" }}
    >
      9
    </span>
    <span
      style={{ fontSize: 14, fontFamily: '"Nunito Sans"', color: "#1A1A1A" }}
    >
      .1&nbsp;
    </span>
    <span
      style={{ fontSize: 14, fontFamily: '"Nunito Sans"', color: "#1A1A1A" }}
    >
      Good Insurance
    </span>
    <span
      style={{ fontSize: 14, fontFamily: '"PMingLiU",serif', color: "#1A1A1A" }}
    >
      為山河集團所經營的眾多品牌之一。
    </span>
    <span
      style={{ fontSize: 14, fontFamily: '"Nunito Sans"', color: "#1A1A1A" }}
    >
      Good Insurance
    </span>
    <span
      style={{ fontSize: 14, fontFamily: '"PMingLiU",serif', color: "#1A1A1A" }}
    >
      為「
    </span>
    <span
      style={{ fontSize: 14, fontFamily: '"Nunito Sans"', color: "#1A1A1A" }}
    >
      IA
    </span>
    <span
      style={{ fontSize: 14, fontFamily: '"PMingLiU",serif', color: "#1A1A1A" }}
    >
      」下的持牌保險代理機構
    </span>
    <span
      style={{ fontSize: 14, fontFamily: '"Nunito Sans"', color: "#1A1A1A" }}
    >
      (
    </span>
    <span
      style={{ fontSize: 14, fontFamily: '"PMingLiU",serif', color: "#1A1A1A" }}
    >
      持牌保險代理牌照號碼
    </span>
    <span
      style={{ fontSize: 14, fontFamily: '"Nunito Sans"', color: "#1A1A1A" }}
    >
      :
    </span>
    <span
      style={{ fontSize: 14, fontFamily: '"Nunito Sans"', color: "#1A1A1A" }}
    >
      GB1070
    </span>
    <span
      style={{ fontSize: 14, fontFamily: '"Nunito Sans"', color: "#1A1A1A" }}
    >
      )
    </span>
    <span
      style={{ fontSize: 14, fontFamily: '"PMingLiU",serif', color: "#1A1A1A" }}
    >
      ，代理及安排其主事持牌保險公司（「保險公司」）的多元化保險產品及服務。
    </span>
  </p>
  <p
    style={{
      marginTop: "0cm",
      marginRight: "0cm",
      marginBottom: "22.5pt",
      marginLeft: "0cm",
      lineHeight: "normal",
      fontSize: 16,
      fontFamily: '"Aptos",sans-serif',
      textAlign: "justify"
    }}
  >
    <span
      style={{ fontSize: 14, fontFamily: '"Nunito Sans"', color: "#1A1A1A" }}
    >
      9
    </span>
    <span
      style={{ fontSize: 14, fontFamily: '"Nunito Sans"', color: "#1A1A1A" }}
    >
      .2&nbsp;
    </span>
    <span
      style={{ fontSize: 14, fontFamily: '"PMingLiU",serif', color: "#1A1A1A" }}
    >
      平台上顯示或談及之所有保險產品及服務（包括但不限於
    </span>
    <span
      style={{ fontSize: 14, fontFamily: '"Nunito Sans"', color: "#1A1A1A" }}
    >
      Good
    </span>
    <span
      style={{ fontSize: 14, fontFamily: '"Nunito Sans"', color: "#1A1A1A" }}
    >
      &nbsp;Insurance
    </span>
    <span
      style={{ fontSize: 14, fontFamily: '"PMingLiU",serif', color: "#1A1A1A" }}
    >
      ）由保險公司提供及承保。
    </span>
    <span
      style={{ fontSize: 14, fontFamily: '"Nunito Sans"', color: "#1A1A1A" }}
    >
      Good Insurance
    </span>
    <span
      style={{ fontSize: 14, fontFamily: '"Nunito Sans"', color: "#1A1A1A" }}
    >
      &nbsp;
    </span>
    <span
      style={{ fontSize: 14, fontFamily: '"PMingLiU",serif', color: "#1A1A1A" }}
    >
      之角色只限於代理及安排保險公司的保險產品，而
    </span>
    <span
      style={{ fontSize: 14, fontFamily: '"Nunito Sans"', color: "#1A1A1A" }}
    >
      &nbsp;
    </span>
    <span
      style={{ fontSize: 14, fontFamily: '"Nunito Sans"', color: "#1A1A1A" }}
    >
      Good Insurance
    </span>
    <span
      style={{ fontSize: 14, fontFamily: '"Nunito Sans"', color: "#1A1A1A" }}
    >
      &nbsp;
    </span>
    <span
      style={{ fontSize: 14, fontFamily: '"PMingLiU",serif', color: "#1A1A1A" }}
    >
      對有關保險產品的提供的任何事項概不負責。所有保險申請以相關保險公司的承保及接納為準。
    </span>
  </p>
  <p
    style={{
      marginTop: "0cm",
      marginRight: "0cm",
      marginBottom: "22.5pt",
      marginLeft: "0cm",
      lineHeight: "normal",
      fontSize: 16,
      fontFamily: '"Aptos",sans-serif',
      textAlign: "justify"
    }}
  >
    <span
      style={{ fontSize: 14, fontFamily: '"Nunito Sans"', color: "#1A1A1A" }}
    >
      9
    </span>
    <span
      style={{ fontSize: 14, fontFamily: '"Nunito Sans"', color: "#1A1A1A" }}
    >
      .3&nbsp;
    </span>
    <span
      style={{ fontSize: 14, fontFamily: '"PMingLiU",serif', color: "#1A1A1A" }}
    >
      平台上顯示之所有保險產品及服務及有關保險內容
    </span>
    <span
      style={{ fontSize: 14, fontFamily: '"Nunito Sans"', color: "#1A1A1A" }}
    >
      &nbsp;
    </span>
    <span
      style={{ fontSize: 14, fontFamily: '"PMingLiU",serif', color: "#1A1A1A" }}
    >
      （「保險內容」）
    </span>
    <span
      style={{ fontSize: 14, fontFamily: '"Nunito Sans"', color: "#1A1A1A" }}
    >
      &nbsp;
    </span>
    <span
      style={{ fontSize: 14, fontFamily: '"PMingLiU",serif', color: "#1A1A1A" }}
    >
      旨在只於香港境內提供。所有保險內容並不旨在為位於或居住在香港以外的人仕使用。於此網站上就保險產品資料之顯示不能被詮釋為
    </span>
    <span
      style={{ fontSize: 14, fontFamily: '"Nunito Sans"', color: "#1A1A1A" }}
    >
      &nbsp;
    </span>
    <span
      style={{ fontSize: 14, fontFamily: '"Nunito Sans"', color: "#1A1A1A" }}
    >
      Good Insurance
    </span>
    <span
      style={{ fontSize: 14, fontFamily: '"Nunito Sans"', color: "#1A1A1A" }}
    >
      &nbsp;
    </span>
    <span
      style={{ fontSize: 14, fontFamily: '"PMingLiU",serif', color: "#1A1A1A" }}
    >
      或保險公司在香港以外要約出售或招攬要約或建議購買或出售或提供任何保險產品。
    </span>
  </p>
  <p
    style={{
      marginTop: "0cm",
      marginRight: "0cm",
      marginBottom: "22.5pt",
      marginLeft: "0cm",
      lineHeight: "normal",
      fontSize: 16,
      fontFamily: '"Aptos",sans-serif',
      textAlign: "justify"
    }}
  >
    <span
      style={{ fontSize: 14, fontFamily: '"Nunito Sans"', color: "#1A1A1A" }}
    >
      9
    </span>
    <span
      style={{ fontSize: 14, fontFamily: '"Nunito Sans"', color: "#1A1A1A" }}
    >
      .4&nbsp;
    </span>
    <span
      style={{ fontSize: 14, fontFamily: '"PMingLiU",serif', color: "#1A1A1A" }}
    >
      平台上顯示的保險內容僅提供保險範圍之條款及細則之概述，不包括保單的完整條款和不保事項。在此提供的任何資訊均受準確的條款及細則約束，完整的條款及細則可於保單（包括產品小冊子）查閱；如有不一致之處，應以保單為準。
    </span>
  </p>
  <p
    style={{
      marginTop: "0cm",
      marginRight: "0cm",
      marginBottom: "22.5pt",
      marginLeft: "0cm",
      lineHeight: "normal",
      fontSize: 16,
      fontFamily: '"Aptos",sans-serif',
      textAlign: "justify"
    }}
  >
    <span
      style={{ fontSize: 14, fontFamily: '"Nunito Sans"', color: "#1A1A1A" }}
    >
      9
    </span>
    <span
      style={{ fontSize: 14, fontFamily: '"Nunito Sans"', color: "#1A1A1A" }}
    >
      .5&nbsp;
    </span>
    <span
      style={{ fontSize: 14, fontFamily: '"PMingLiU",serif', color: "#1A1A1A" }}
    >
      除非另有特別註明，保險產品之保費將會被支付予保險公司，而
    </span>
    <span
      style={{ fontSize: 14, fontFamily: '"Nunito Sans"', color: "#1A1A1A" }}
    >
      &nbsp;
    </span>
    <span
      style={{ fontSize: 14, fontFamily: '"Nunito Sans"', color: "#1A1A1A" }}
    >
      Good Insurance
    </span>
    <span
      style={{ fontSize: 14, fontFamily: '"Nunito Sans"', color: "#1A1A1A" }}
    >
      &nbsp;
    </span>
    <span
      style={{ fontSize: 14, fontFamily: '"PMingLiU",serif', color: "#1A1A1A" }}
    >
      作為保險委任的持牌保險代理機構，將從保險公司獲取佣金。
    </span>
  </p>
  <p
    style={{
      marginTop: "0cm",
      marginRight: "0cm",
      marginBottom: "22.5pt",
      marginLeft: "0cm",
      lineHeight: "normal",
      fontSize: 16,
      fontFamily: '"Aptos",sans-serif',
      textAlign: "justify"
    }}
  >
    <span
      style={{ fontSize: 14, fontFamily: '"Nunito Sans"', color: "#1A1A1A" }}
    >
      9
    </span>
    <span
      style={{ fontSize: 14, fontFamily: '"Nunito Sans"', color: "#1A1A1A" }}
    >
      .6&nbsp;
    </span>
    <span
      style={{ fontSize: 14, fontFamily: '"PMingLiU",serif', color: "#1A1A1A" }}
    >
      我們將不會向閣下提供任何法律、會計或稅務意見。建議閣下小心審查提供給閣下的保險產品資料及諮詢獨立的專業建議，如需要。
    </span>
  </p>
  <p
    style={{
      marginTop: "0cm",
      marginRight: "0cm",
      marginBottom: "22.5pt",
      marginLeft: "0cm",
      lineHeight: "normal",
      fontSize: 16,
      fontFamily: '"Aptos",sans-serif',
      textAlign: "justify"
    }}
  >
    <span
      style={{ fontSize: 14, fontFamily: '"Nunito Sans"', color: "#1A1A1A" }}
    >
      9
    </span>
    <span
      style={{ fontSize: 14, fontFamily: '"Nunito Sans"', color: "#1A1A1A" }}
    >
      .7&nbsp;
    </span>
    <span
      style={{ fontSize: 14, fontFamily: '"PMingLiU",serif', color: "#1A1A1A" }}
    >
      所有保險內容和受規管的保險活動（定義見《保險業條例》《第
    </span>
    <span
      style={{ fontSize: 14, fontFamily: '"Nunito Sans"', color: "#1A1A1A" }}
    >
      41
    </span>
    <span
      style={{ fontSize: 14, fontFamily: '"PMingLiU",serif', color: "#1A1A1A" }}
    >
      章》）僅由
    </span>
    <span
      style={{ fontSize: 14, fontFamily: '"Nunito Sans"', color: "#1A1A1A" }}
    >
      &nbsp;
    </span>
    <span
      style={{ fontSize: 14, fontFamily: '"Nunito Sans"', color: "#1A1A1A" }}
    >
      Good Insurance
    </span>
    <span
      style={{ fontSize: 14, fontFamily: '"Nunito Sans"', color: "#1A1A1A" }}
    >
      &nbsp;
    </span>
    <span
      style={{ fontSize: 14, fontFamily: '"PMingLiU",serif', color: "#1A1A1A" }}
    >
      以持牌保險代理的身份進行。
    </span>
  </p>
  <p
    style={{
      marginTop: "0cm",
      marginRight: "0cm",
      marginBottom: "22.5pt",
      marginLeft: "0cm",
      lineHeight: "normal",
      fontSize: 16,
      fontFamily: '"Aptos",sans-serif',
      textAlign: "justify"
    }}
  >
    <span
      style={{ fontSize: 14, fontFamily: '"Nunito Sans"', color: "#1A1A1A" }}
    >
      9
    </span>
    <span
      style={{ fontSize: 14, fontFamily: '"Nunito Sans"', color: "#1A1A1A" }}
    >
      .8&nbsp;
    </span>
    <span
      style={{ fontSize: 14, fontFamily: '"PMingLiU",serif', color: "#1A1A1A" }}
    >
      任何有關
    </span>
    <span
      style={{ fontSize: 14, fontFamily: '"Nunito Sans"', color: "#1A1A1A" }}
    >
      &nbsp;
    </span>
    <span
      style={{ fontSize: 14, fontFamily: '"Nunito Sans"', color: "#1A1A1A" }}
    >
      Good Insurance
    </span>
    <span
      style={{ fontSize: 14, fontFamily: '"Nunito Sans"', color: "#1A1A1A" }}
    >
      &nbsp;
    </span>
    <span
      style={{ fontSize: 14, fontFamily: '"PMingLiU",serif', color: "#1A1A1A" }}
    >
      代理和安排的保險產品和服務的查詢，請聯絡
    </span>
    <span
      style={{ fontSize: 14, fontFamily: '"Nunito Sans"', color: "#1A1A1A" }}
    >
      Good Insurance
    </span>
    <span
      style={{ fontSize: 14, fontFamily: '"Nunito Sans"', color: "#1A1A1A" }}
    >
      &nbsp;
    </span>
    <span
      style={{ fontSize: 14, fontFamily: '"PMingLiU",serif', color: "#1A1A1A" }}
    >
      客戶服務熱線
    </span>
    <span
      style={{ fontSize: 14, fontFamily: '"Nunito Sans"', color: "#1A1A1A" }}
    >
      (+852)&nbsp;
    </span>
    <span
      style={{ fontSize: 14, fontFamily: '"Nunito Sans"', color: "#1A1A1A" }}
    >
      6611 8961
    </span>
    <span
      style={{ fontSize: 14, fontFamily: '"PMingLiU",serif', color: "#1A1A1A" }}
    >
      。
    </span>
  </p>
  <p
    style={{
      marginTop: "15.0pt",
      marginRight: "0cm",
      marginBottom: "15.0pt",
      marginLeft: "0cm",
      lineHeight: "normal",
      fontSize: 16,
      fontFamily: '"Aptos",sans-serif',
      textAlign: "justify"
    }}
  >
    <strong>
      <span
        style={{ fontSize: 14, fontFamily: '"Nunito Sans"', color: "#1A1A1A" }}
      >
        1
      </span>
    </strong>
    <strong>
      <span
        style={{ fontSize: 14, fontFamily: '"Nunito Sans"', color: "#1A1A1A" }}
      >
        0
      </span>
    </strong>
    <strong>
      <span
        style={{ fontSize: 14, fontFamily: '"Nunito Sans"', color: "#1A1A1A" }}
      >
        .&nbsp;
      </span>
    </strong>
    <strong>
      <span
        style={{
          fontSize: 14,
          fontFamily: '"PMingLiU",serif',
          color: "#1A1A1A"
        }}
      >
        賬戶擁有者
      </span>
    </strong>
  </p>
  <p
    style={{
      marginTop: "0cm",
      marginRight: "0cm",
      marginBottom: "22.5pt",
      marginLeft: "0cm",
      lineHeight: "normal",
      fontSize: 16,
      fontFamily: '"Aptos",sans-serif',
      textAlign: "justify"
    }}
  >
    <span
      style={{ fontSize: 14, fontFamily: '"Nunito Sans"', color: "#1A1A1A" }}
    >
      1
    </span>
    <span
      style={{ fontSize: 14, fontFamily: '"Nunito Sans"', color: "#1A1A1A" }}
    >
      0
    </span>
    <span
      style={{ fontSize: 14, fontFamily: '"Nunito Sans"', color: "#1A1A1A" }}
    >
      .1&nbsp;
    </span>
    <span
      style={{ fontSize: 14, fontFamily: '"PMingLiU",serif', color: "#1A1A1A" }}
    >
      用戶名稱和密碼
    </span>
    <span
      style={{ fontSize: 14, fontFamily: '"Nunito Sans"', color: "#1A1A1A" }}
    >
      <br />
      &nbsp;
    </span>
    <span
      style={{ fontSize: 14, fontFamily: '"PMingLiU",serif', color: "#1A1A1A" }}
    >
      閣下可能需要建立一個賬戶或提供個人資料才能在平台上使用某些服務或網站的某部份（及
    </span>
    <span
      style={{ fontSize: 14, fontFamily: '"Nunito Sans"', color: "#1A1A1A" }}
    >
      /
    </span>
    <span
      style={{ fontSize: 14, fontFamily: '"PMingLiU",serif', color: "#1A1A1A" }}
    >
      或其入門分站）。但是，閣下不應該透過建立多個同樣平台（或入門分站）的賬戶來濫用本平台或服務。如果閣下要求在我們這裡建立一個賬戶，我們會為閣下建立用戶名稱和密碼以作閣下進入本平台及使用服務之用。
    </span>
    <span
      style={{ fontSize: 14, fontFamily: '"Nunito Sans"', color: "#1A1A1A" }}
    >
      <br />
      &nbsp;
    </span>
    <span
      style={{ fontSize: 14, fontFamily: '"PMingLiU",serif', color: "#1A1A1A" }}
    >
      我們可以在任何時候決定要求閣下立即更新個人資料或癈除用戶名稱及
    </span>
    <span
      style={{ fontSize: 14, fontFamily: '"Nunito Sans"', color: "#1A1A1A" }}
    >
      /
    </span>
    <span
      style={{ fontSize: 14, fontFamily: '"PMingLiU",serif', color: "#1A1A1A" }}
    >
      或及
    </span>
    <span
      style={{ fontSize: 14, fontFamily: '"Nunito Sans"', color: "#1A1A1A" }}
    >
      /
    </span>
    <span
      style={{ fontSize: 14, fontFamily: '"PMingLiU",serif', color: "#1A1A1A" }}
    >
      或密碼，而無需提供任何理由或事先通知，對由造成閣下的任何損失或因此類請求或癈除而造成的或與之有關的損失概不負責。在此閣下同意會不時更改密碼，及保護用戶名用戶名稱和密碼的安全，並且不得將用戶名稱和密碼透露給任何第三方。閣下應對用戶名稱的安全負責，並對披露或使用用戶名稱及
    </span>
    <span
      style={{ fontSize: 14, fontFamily: '"Nunito Sans"', color: "#1A1A1A" }}
    >
      /
    </span>
    <span
      style={{ fontSize: 14, fontFamily: '"PMingLiU",serif', color: "#1A1A1A" }}
    >
      或密碼（無論是否授權）承擔責任。如果閣下知道或有合理理由懷疑用戶名稱及
    </span>
    <span
      style={{ fontSize: 14, fontFamily: '"Nunito Sans"', color: "#1A1A1A" }}
    >
      /
    </span>
    <span
      style={{ fontSize: 14, fontFamily: '"PMingLiU",serif', color: "#1A1A1A" }}
    >
      或密碼的機密性或安全性已被洩露，或者發現有未經授權使用用戶名稱及
    </span>
    <span
      style={{ fontSize: 14, fontFamily: '"Nunito Sans"', color: "#1A1A1A" }}
    >
      /
    </span>
    <span
      style={{ fontSize: 14, fontFamily: '"PMingLiU",serif', color: "#1A1A1A" }}
    >
      或密碼的情況，或閣下的個人數據需要更新，閣下應立即通知我們。
    </span>
    <span
      style={{ fontSize: 14, fontFamily: '"Nunito Sans"', color: "#1A1A1A" }}
    >
      <br />
      &nbsp;
    </span>
    <span
      style={{ fontSize: 14, fontFamily: '"PMingLiU",serif', color: "#1A1A1A" }}
    >
      閣下不得使用任何其他人的用戶名稱進入本平台或使用服務。
    </span>
    <span
      style={{ fontSize: 14, fontFamily: '"Nunito Sans"', color: "#1A1A1A" }}
    >
      <br />
      &nbsp;
    </span>
    <span
      style={{ fontSize: 14, fontFamily: '"PMingLiU",serif', color: "#1A1A1A" }}
    >
      我們不承擔或負責任何因閣下未能遵守上述規定而引起的損失。
    </span>
  </p>
  <p
    style={{
      marginTop: "0cm",
      marginRight: "0cm",
      marginBottom: "0cm",
      marginLeft: "0cm",
      lineHeight: "normal",
      fontSize: 16,
      fontFamily: '"Aptos",sans-serif',
      textAlign: "justify"
    }}
  >
    <span
      style={{ fontSize: 14, fontFamily: '"Nunito Sans"', color: "#1A1A1A" }}
    >
      1
    </span>
    <span
      style={{ fontSize: 14, fontFamily: '"Nunito Sans"', color: "#1A1A1A" }}
    >
      0
    </span>
    <span
      style={{ fontSize: 14, fontFamily: '"Nunito Sans"', color: "#1A1A1A" }}
    >
      .2&nbsp;
    </span>
    <span
      style={{ fontSize: 14, fontFamily: '"PMingLiU",serif', color: "#1A1A1A" }}
    >
      使用聲明
    </span>
  </p>
  <p
    style={{
      marginTop: "0cm",
      marginRight: "0cm",
      marginBottom: "0cm",
      marginLeft: "0cm",
      lineHeight: "normal",
      fontSize: 16,
      fontFamily: '"Aptos",sans-serif',
      textAlign: "justify"
    }}
  >
    <span
      style={{ fontSize: 14, fontFamily: '"PMingLiU",serif', color: "#1A1A1A" }}
    >
      閣下同意並明白任何對進入本平台及
    </span>
    <span
      style={{ fontSize: 14, fontFamily: '"Nunito Sans"', color: "#1A1A1A" }}
    >
      /
    </span>
    <span
      style={{ fontSize: 14, fontFamily: '"PMingLiU",serif', color: "#1A1A1A" }}
    >
      或使用服務以及任何使用閣下的用戶名稱和密碼相關的信息、數據或通信都應被視為：
    </span>
  </p>
  <ol style={{ marginBottom: "0cm", marginTop: "0cm" }} start={1} type="i">
    <li
      style={{
        marginTop: "0cm",
        marginRight: "0cm",
        marginBottom: "8.0pt",
        marginLeft: "0cm",
        lineHeight: "normal",
        fontSize: 16,
        fontFamily: '"Aptos",sans-serif',
        color: "#1A1A1A",
        textAlign: "justify"
      }}
    >
      <span style={{ fontSize: 14, fontFamily: '"PMingLiU",serif' }}>
        閣下進入相關平台及
      </span>
      <span style={{ fontSize: 14, fontFamily: '"Nunito Sans"' }}>/</span>
      <span style={{ fontSize: 14, fontFamily: '"PMingLiU",serif' }}>
        或使用服務；或
      </span>
    </li>
    <li
      style={{
        marginTop: "0cm",
        marginRight: "0cm",
        marginBottom: "8.0pt",
        marginLeft: "0cm",
        lineHeight: "normal",
        fontSize: 16,
        fontFamily: '"Aptos",sans-serif',
        color: "#1A1A1A",
        textAlign: "justify"
      }}
    >
      <span style={{ fontSize: 14, fontFamily: '"PMingLiU",serif' }}>
        信息、數據或張貼通訊都是由閣下傳送和發布的。
      </span>
    </li>
  </ol>
  <p
    style={{
      marginTop: "0cm",
      marginRight: "0cm",
      marginBottom: "0cm",
      marginLeft: "0cm",
      lineHeight: "normal",
      fontSize: 16,
      fontFamily: '"Aptos",sans-serif',
      textAlign: "justify"
    }}
  >
    <span
      style={{ fontSize: 14, fontFamily: '"PMingLiU",serif', color: "#1A1A1A" }}
    >
      如有人
    </span>
    <span
      style={{ fontSize: 14, fontFamily: '"Nunito Sans"', color: "#1A1A1A" }}
    >
      (
    </span>
    <span
      style={{ fontSize: 14, fontFamily: '"PMingLiU",serif', color: "#1A1A1A" }}
    >
      不論是否閣下
    </span>
    <span
      style={{ fontSize: 14, fontFamily: '"Nunito Sans"', color: "#1A1A1A" }}
    >
      )&nbsp;
    </span>
    <span
      style={{ fontSize: 14, fontFamily: '"PMingLiU",serif', color: "#1A1A1A" }}
    >
      使用閣下的用戶名稱和密碼進入平台或使用任何服務，則表示閣下同意我們有權當作是閣下本人的行為。另外，因與閣下的用戶名稱和密碼有關之服務使用及
    </span>
    <span
      style={{ fontSize: 14, fontFamily: '"Nunito Sans"', color: "#1A1A1A" }}
    >
      /
    </span>
    <span
      style={{ fontSize: 14, fontFamily: '"PMingLiU",serif', color: "#1A1A1A" }}
    >
      或進入平台而造成我們的任何損失，需由閣下承擔。
    </span>
  </p>
  <p
    style={{
      marginTop: "15.0pt",
      marginRight: "0cm",
      marginBottom: "15.0pt",
      marginLeft: "0cm",
      lineHeight: "normal",
      fontSize: 16,
      fontFamily: '"Aptos",sans-serif',
      textAlign: "justify"
    }}
  >
    <strong>
      <span
        style={{ fontSize: 14, fontFamily: '"Nunito Sans"', color: "#1A1A1A" }}
      >
        1
      </span>
    </strong>
    <strong>
      <span
        style={{ fontSize: 14, fontFamily: '"Nunito Sans"', color: "#1A1A1A" }}
      >
        1
      </span>
    </strong>
    <strong>
      <span
        style={{ fontSize: 14, fontFamily: '"Nunito Sans"', color: "#1A1A1A" }}
      >
        .&nbsp;
      </span>
    </strong>
    <strong>
      <span
        style={{
          fontSize: 14,
          fontFamily: '"PMingLiU",serif',
          color: "#1A1A1A"
        }}
      >
        付款
      </span>
    </strong>
  </p>
  <p
    style={{
      marginTop: "0cm",
      marginRight: "0cm",
      marginBottom: "0cm",
      marginLeft: "0cm",
      lineHeight: "normal",
      fontSize: 16,
      fontFamily: '"Aptos",sans-serif',
      textAlign: "justify"
    }}
  >
    <span
      style={{ fontSize: 14, fontFamily: '"PMingLiU",serif', color: "#1A1A1A" }}
    >
      在使用閣下的服務賬戶進行購物、兌換或服務申請或訂閱時，閣下保證閣下是服務賬戶的擁有者。不管是否由閣下使用，在閣下的服務賬戶下的所有活動，均應被視為是閣下所進行的活動或已獲得閣下的授權下所進行。
    </span>
  </p>
  <ol style={{ marginBottom: "0cm", marginTop: "0cm" }} start={1} type="a">
    <li
      style={{
        marginTop: "0cm",
        marginRight: "0cm",
        marginBottom: "8.0pt",
        marginLeft: "0cm",
        lineHeight: "normal",
        fontSize: 16,
        fontFamily: '"Aptos",sans-serif',
        color: "#1A1A1A",
        textAlign: "justify"
      }}
    >
      <span style={{ fontSize: 14, fontFamily: '"PMingLiU",serif' }}>
        透過在網站上訂購、兌換商品或服務申請或訂閱，閣下授權我們及
      </span>
      <span style={{ fontSize: 14, fontFamily: '"Nunito Sans"' }}>/</span>
      <span style={{ fontSize: 14, fontFamily: '"PMingLiU",serif' }}>
        或相關供應商（視情況而定）在閣下指定的信用卡或我們接受的其他支付方式收取所訂購或兌換商品或服務訂閱的全部金額以及所有相關費用（包括但不限於運送費用）（
      </span>
      <span style={{ fontSize: 14, fontFamily: '"Nunito Sans"' }}>&nbsp;</span>
      <span style={{ fontSize: 14, fontFamily: '"PMingLiU",serif' }}>
        統稱為「費用」）。就閣下選擇的付款方式發生錯誤、中斷或失敗，閣下同意閣下不可以對我們或我們的任何代理人提出索償。閣下保證閣下被正式授權使用閣下選擇的信用卡或付款方式，並遵守閣下的付款方式的用戶協議。閣下同意我們有權隨時自行決定接受，拒絕或修改閣下選擇的任何信用卡或任何付款方式。除非我們或相關供應商（視情況而定）的供應條款另有規定，否則所有價格均以港元報價及不包括任何政府服務稅，增值稅或類似稅項（如有），並可能不時更改，恕不另行通知。如果商品以不正確的價格上市或有印刷錯誤，我們有權自行決定拒絕，取消或修改任何對該商品或服務的訂單，我們不需承擔任何責任或就正確的價格與閣下聯繫。閣下在平台購買的商品應以港幣於平台上支付。在任何情況下均不予退還已支付的購物款項（或其任何部分），除非有註明規定或我方另有協議。
      </span>
    </li>
    <li
      style={{
        marginTop: "0cm",
        marginRight: "0cm",
        marginBottom: "8.0pt",
        marginLeft: "0cm",
        lineHeight: "normal",
        fontSize: 16,
        fontFamily: '"Aptos",sans-serif',
        color: "#1A1A1A",
        textAlign: "justify"
      }}
    >
      <span style={{ fontSize: 14, fontFamily: '"PMingLiU",serif' }}>
        閣下在此授權我們或我們的結算代理機構從閣下選擇的信用卡或其他支付方式（「卡賬戶」）收取全部費用。閣下的銀行，信用卡發卡公司及
      </span>
      <span style={{ fontSize: 14, fontFamily: '"Nunito Sans"' }}>/</span>
      <span style={{ fontSize: 14, fontFamily: '"PMingLiU",serif' }}>
        或其他相關方（「銀行」）可能會向閣下收取使用信用卡或其他支付方式付款的額外費用，詳情請向銀行查詢。此外，閣下保證閣下已獲得所有使用信用卡賬戶進行支付的必要授權。除非另有特別註明，即使與銀行的持卡人協議中的條款有任何衝突，就信用卡賬戶中的任何收費爭議都必須在賬單的發出日期起計
      </span>
      <span style={{ fontSize: 14, fontFamily: '"Nunito Sans"' }}>30</span>
      <span style={{ fontSize: 14, fontFamily: '"PMingLiU",serif' }}>
        天內向我們提出。如果我們沒有在賬單發出日期起計的
      </span>
      <span style={{ fontSize: 14, fontFamily: '"Nunito Sans"' }}>30</span>
      <span style={{ fontSize: 14, fontFamily: '"PMingLiU",serif' }}>
        天內收到閣下的書面查詢，閣下將被視為已經同意信用卡賬單上的所有交易。
      </span>
    </li>
    <li
      style={{
        marginTop: "0cm",
        marginRight: "0cm",
        marginBottom: "8.0pt",
        marginLeft: "0cm",
        lineHeight: "normal",
        fontSize: 16,
        fontFamily: '"Aptos",sans-serif',
        color: "#1A1A1A",
        textAlign: "justify"
      }}
    >
      <span style={{ fontSize: 14, fontFamily: '"PMingLiU",serif' }}>
        如閣下未能根據所選付款方式在付款截止日前全額支付費用，我們有權取消或暫停客戶訂單、申請或服務訂閱，直到我們收到所有費用及
      </span>
      <span style={{ fontSize: 14, fontFamily: '"Nunito Sans"' }}>/</span>
      <span style={{ fontSize: 14, fontFamily: '"PMingLiU",serif' }}>
        或任何費用。
      </span>
    </li>
    <li
      style={{
        marginTop: "0cm",
        marginRight: "0cm",
        marginBottom: "8.0pt",
        marginLeft: "0cm",
        lineHeight: "normal",
        fontSize: 16,
        fontFamily: '"Aptos",sans-serif',
        color: "#1A1A1A",
        textAlign: "justify"
      }}
    >
      <span style={{ fontSize: 14, fontFamily: '"PMingLiU",serif' }}>
        作為訂單處理程序的一部分，我們會檢視所有訂單、申請或訂閱，並保留任何因涉嫌欺詐、未經授權或非法活動而暫停或拒絕處理訂單、服務訂閱或申請的權利。客戶可能需要提供足夠的驗證資料，例如相關交易的批准代碼（由銀行發出的相關交易的
      </span>
      <span style={{ fontSize: 14, fontFamily: '"Nunito Sans"' }}>
        &nbsp;SMS
      </span>
      <span style={{ fontSize: 14, fontFamily: '"PMingLiU",serif' }}>
        ）。我們可能以電話或透過電郵地址發送電子郵件給閣下進行驗證。
      </span>
    </li>
  </ol>
  <p
    style={{
      marginTop: "15.0pt",
      marginRight: "0cm",
      marginBottom: "15.0pt",
      marginLeft: "0cm",
      lineHeight: "normal",
      fontSize: 16,
      fontFamily: '"Aptos",sans-serif',
      textAlign: "justify"
    }}
  >
    <strong>
      <span
        style={{ fontSize: 14, fontFamily: '"Nunito Sans"', color: "#1A1A1A" }}
      >
        1
      </span>
    </strong>
    <strong>
      <span
        style={{ fontSize: 14, fontFamily: '"Nunito Sans"', color: "#1A1A1A" }}
      >
        2
      </span>
    </strong>
    <strong>
      <span
        style={{ fontSize: 14, fontFamily: '"Nunito Sans"', color: "#1A1A1A" }}
      >
        .&nbsp;
      </span>
    </strong>
    <strong>
      <span
        style={{
          fontSize: 14,
          fontFamily: '"PMingLiU",serif',
          color: "#1A1A1A"
        }}
      >
        知識產權
      </span>
    </strong>
  </p>
  <p
    style={{
      marginTop: "0cm",
      marginRight: "0cm",
      marginBottom: "22.5pt",
      marginLeft: "0cm",
      lineHeight: "normal",
      fontSize: 16,
      fontFamily: '"Aptos",sans-serif',
      textAlign: "justify"
    }}
  >
    <span
      style={{ fontSize: 14, fontFamily: '"Nunito Sans"', color: "#1A1A1A" }}
    >
      1
    </span>
    <span
      style={{ fontSize: 14, fontFamily: '"Nunito Sans"', color: "#1A1A1A" }}
    >
      2
    </span>
    <span
      style={{ fontSize: 14, fontFamily: '"Nunito Sans"', color: "#1A1A1A" }}
    >
      .1&nbsp;
    </span>
    <span
      style={{ fontSize: 14, fontFamily: '"PMingLiU",serif', color: "#1A1A1A" }}
    >
      擁有權
    </span>
    <span
      style={{ fontSize: 14, fontFamily: '"Nunito Sans"', color: "#1A1A1A" }}
    >
      <br />
      &nbsp;
    </span>
    <span
      style={{ fontSize: 14, fontFamily: '"PMingLiU",serif', color: "#1A1A1A" }}
    >
      本平台和材料中的知識產權由我們，我們的許可方或我們的產品或服務提供商擁有，授權或控制。我們保留最大限度地執行我們的知識產權之權利
    </span>
  </p>
  <p
    style={{
      marginTop: "0cm",
      marginRight: "0cm",
      marginBottom: "22.5pt",
      marginLeft: "0cm",
      lineHeight: "normal",
      fontSize: 16,
      fontFamily: '"Aptos",sans-serif',
      textAlign: "justify"
    }}
  >
    <span
      style={{ fontSize: 14, fontFamily: '"Nunito Sans"', color: "#1A1A1A" }}
    >
      1
    </span>
    <span
      style={{ fontSize: 14, fontFamily: '"Nunito Sans"', color: "#1A1A1A" }}
    >
      2
    </span>
    <span
      style={{ fontSize: 14, fontFamily: '"Nunito Sans"', color: "#1A1A1A" }}
    >
      .2&nbsp;
    </span>
    <span
      style={{ fontSize: 14, fontFamily: '"PMingLiU",serif', color: "#1A1A1A" }}
    >
      限制使用
    </span>
    <span
      style={{ fontSize: 14, fontFamily: '"Nunito Sans"', color: "#1A1A1A" }}
    >
      <br />
      &nbsp;
    </span>
    <span
      style={{ fontSize: 14, fontFamily: '"PMingLiU",serif', color: "#1A1A1A" }}
    >
      在未經我們事先書面批准或相關的版權所有者批准下，閣下不得在平台的任何部分或任何材料或我們以任何方式提供的任何軟件，或存儲在信息檢索系統中或安裝在任何服務器、系統或設備上複制、反向工程、反編譯、反彙編、修改、分離、出售、出租、再許可、分發、再版、顯示、廣播、超鏈接、建立、反映、轉讓、傳播或改變、更改或刪除任何版權、商標或其他保護聲明。除第
    </span>
    <span
      style={{ fontSize: 14, fontFamily: '"Nunito Sans"', color: "#1A1A1A" }}
    >
      1
    </span>
    <span
      style={{ fontSize: 14, fontFamily: '"Nunito Sans"', color: "#1A1A1A" }}
    >
      2
    </span>
    <span
      style={{ fontSize: 14, fontFamily: '"Nunito Sans"', color: "#1A1A1A" }}
    >
      .3
    </span>
    <span
      style={{ fontSize: 14, fontFamily: '"PMingLiU",serif', color: "#1A1A1A" }}
    >
      條另有規定外，只有在不修改材料的情況下，閣下才可以獲批准下載、打印或使用這些材料用於個人和非商業用途。我們或相關版權擁有者保留「材料」中包含的所有版權和所有權聲明。
    </span>
  </p>
  <p
    style={{
      marginTop: "0cm",
      marginRight: "0cm",
      marginBottom: "22.5pt",
      marginLeft: "0cm",
      lineHeight: "normal",
      fontSize: 16,
      fontFamily: '"Aptos",sans-serif',
      textAlign: "justify"
    }}
  >
    <span
      style={{ fontSize: 14, fontFamily: '"Nunito Sans"', color: "#1A1A1A" }}
    >
      1
    </span>
    <span
      style={{ fontSize: 14, fontFamily: '"Nunito Sans"', color: "#1A1A1A" }}
    >
      2
    </span>
    <span
      style={{ fontSize: 14, fontFamily: '"Nunito Sans"', color: "#1A1A1A" }}
    >
      .3&nbsp;
    </span>
    <span
      style={{ fontSize: 14, fontFamily: '"PMingLiU",serif', color: "#1A1A1A" }}
    >
      商標
    </span>
    <span
      style={{ fontSize: 14, fontFamily: '"Nunito Sans"', color: "#1A1A1A" }}
    >
      <br />
      &nbsp;
    </span>
    <span
      style={{ fontSize: 14, fontFamily: '"PMingLiU",serif', color: "#1A1A1A" }}
    >
      我們或我們的附屬公司的所有與平台和服務相關的商標、服務標誌、圖像和標識（包括但不限於
    </span>
    <span
      style={{ fontSize: 14, fontFamily: '"Nunito Sans"', color: "#1A1A1A" }}
    >
      &nbsp;
    </span>
    <span
      style={{ fontSize: 14, fontFamily: '"Nunito Sans"', color: "#1A1A1A" }}
    >
      Good
    </span>
    <span
      style={{ fontSize: 14, fontFamily: '"Nunito Sans"', color: "#1A1A1A" }}
    >
      &nbsp;Club
    </span>
    <span
      style={{ fontSize: 14, fontFamily: '"PMingLiU",serif', color: "#1A1A1A" }}
    >
      、
    </span>
    <span
      style={{ fontSize: 14, fontFamily: '"Nunito Sans"', color: "#1A1A1A" }}
    >
      Good Mask
    </span>
    <span
      style={{ fontSize: 14, fontFamily: '"PMingLiU",serif', color: "#1A1A1A" }}
    >
      、
    </span>
    <span
      style={{ fontSize: 14, fontFamily: '"Nunito Sans"', color: "#1A1A1A" }}
    >
      Good Meal
    </span>
    <span
      style={{ fontSize: 14, fontFamily: '"PMingLiU",serif', color: "#1A1A1A" }}
    >
      、
    </span>
    <span
      style={{ fontSize: 14, fontFamily: '"Nunito Sans"', color: "#1A1A1A" }}
    >
      Good Easy
    </span>
    <span
      style={{ fontSize: 14, fontFamily: '"PMingLiU",serif', color: "#1A1A1A" }}
    >
      、
    </span>
    <span
      style={{ fontSize: 14, fontFamily: '"Nunito Sans"', color: "#1A1A1A" }}
    >
      Good Insurance
    </span>
    <span
      style={{ fontSize: 14, fontFamily: '"PMingLiU",serif', color: "#1A1A1A" }}
    >
      、
    </span>
    <span
      style={{ fontSize: 14, fontFamily: '"Nunito Sans"', color: "#1A1A1A" }}
    >
      Good Property
    </span>
    <span
      style={{ fontSize: 14, fontFamily: '"PMingLiU",serif', color: "#1A1A1A" }}
    >
      和
    </span>
    <span
      style={{ fontSize: 14, fontFamily: '"Nunito Sans"', color: "#1A1A1A" }}
    >
      &nbsp;
    </span>
    <span
      style={{ fontSize: 14, fontFamily: '"Nunito Sans"', color: "#1A1A1A" }}
    >
      Good Trip
    </span>
    <span
      style={{ fontSize: 14, fontFamily: '"PMingLiU",serif', color: "#1A1A1A" }}
    >
      ）均為我們的附屬公司（視情況而定）的商標或註冊商標。平台中使用的其他商標、服務標記、圖像和標屬可能是其各自的擁有人的商標。根據這些使用條款或銷售條款及細則，或閣下對平台或服務的使用，我們未授予閣下使用上述商標的許可。嚴禁以任何方式未經授權使用上述標誌。
    </span>
  </p>
  <p
    style={{
      marginTop: "15.0pt",
      marginRight: "0cm",
      marginBottom: "15.0pt",
      marginLeft: "0cm",
      lineHeight: "normal",
      fontSize: 16,
      fontFamily: '"Aptos",sans-serif',
      textAlign: "justify"
    }}
  >
    <strong>
      <span
        style={{ fontSize: 14, fontFamily: '"Nunito Sans"', color: "#1A1A1A" }}
      >
        1
      </span>
    </strong>
    <strong>
      <span
        style={{ fontSize: 14, fontFamily: '"Nunito Sans"', color: "#1A1A1A" }}
      >
        3
      </span>
    </strong>
    <strong>
      <span
        style={{ fontSize: 14, fontFamily: '"Nunito Sans"', color: "#1A1A1A" }}
      >
        .&nbsp;
      </span>
    </strong>
    <strong>
      <span
        style={{
          fontSize: 14,
          fontFamily: '"PMingLiU",serif',
          color: "#1A1A1A"
        }}
      >
        責任和義務的限制
      </span>
    </strong>
  </p>
  <p
    style={{
      marginTop: "0cm",
      marginRight: "0cm",
      marginBottom: "0cm",
      marginLeft: "0cm",
      lineHeight: "normal",
      fontSize: 16,
      fontFamily: '"Aptos",sans-serif',
      textAlign: "justify"
    }}
  >
    <span
      style={{ fontSize: 14, fontFamily: '"Nunito Sans"', color: "#1A1A1A" }}
    >
      1
    </span>
    <span
      style={{ fontSize: 14, fontFamily: '"Nunito Sans"', color: "#1A1A1A" }}
    >
      3
    </span>
    <span
      style={{ fontSize: 14, fontFamily: '"Nunito Sans"', color: "#1A1A1A" }}
    >
      .1&nbsp;
    </span>
    <span
      style={{ fontSize: 14, fontFamily: '"PMingLiU",serif', color: "#1A1A1A" }}
    >
      沒有陳述或保證
    </span>
  </p>
  <p
    style={{
      marginTop: "0cm",
      marginRight: "0cm",
      marginBottom: "0cm",
      marginLeft: "0cm",
      lineHeight: "normal",
      fontSize: 16,
      fontFamily: '"Aptos",sans-serif',
      textAlign: "justify"
    }}
  >
    <span
      style={{ fontSize: 14, fontFamily: '"PMingLiU",serif', color: "#1A1A1A" }}
    >
      閣下同意並確認本平台、服務和資料均以「現狀」和「現有」的基礎下提供。在本平台，服務或材料中包含的所有數據及
    </span>
    <span
      style={{ fontSize: 14, fontFamily: '"Nunito Sans"', color: "#1A1A1A" }}
    >
      /
    </span>
    <span
      style={{ fontSize: 14, fontFamily: '"PMingLiU",serif', color: "#1A1A1A" }}
    >
      或信息僅供參考。對於本平台和服務，我們不作任何暗示、明示或法定的陳述或保證，包括不侵犯第三方權利、所有權、適銷性、令人滿意的質量或適合特定目的的保證或材料。特別是，我們不保證：
    </span>
  </p>
  <ol style={{ marginBottom: "0cm", marginTop: "0cm" }} start={1} type="a">
    <li
      style={{
        marginTop: "0cm",
        marginRight: "0cm",
        marginBottom: "8.0pt",
        marginLeft: "0cm",
        lineHeight: "normal",
        fontSize: 16,
        fontFamily: '"Aptos",sans-serif',
        color: "#1A1A1A",
        textAlign: "justify"
      }}
    >
      <span style={{ fontSize: 14, fontFamily: '"PMingLiU",serif' }}>
        本平台、服務或材料中包含的所有數據及
      </span>
      <span style={{ fontSize: 14, fontFamily: '"Nunito Sans"' }}>/</span>
      <span style={{ fontSize: 14, fontFamily: '"PMingLiU",serif' }}>
        或信息的準確性、及時性、充分性、價值或完整性以及連續可用性；
      </span>
    </li>
    <li
      style={{
        marginTop: "0cm",
        marginRight: "0cm",
        marginBottom: "8.0pt",
        marginLeft: "0cm",
        lineHeight: "normal",
        fontSize: 16,
        fontFamily: '"Aptos",sans-serif',
        color: "#1A1A1A",
        textAlign: "justify"
      }}
    >
      <span style={{ fontSize: 14, fontFamily: '"PMingLiU",serif' }}>
        本平台、服務或任何資料將被及時提供、不間斷、安全或無錯誤或遺漏、或任何缺陷會被糾正；
      </span>
    </li>
    <li
      style={{
        marginTop: "0cm",
        marginRight: "0cm",
        marginBottom: "8.0pt",
        marginLeft: "0cm",
        lineHeight: "normal",
        fontSize: 16,
        fontFamily: '"Aptos",sans-serif',
        color: "#1A1A1A",
        textAlign: "justify"
      }}
    >
      <span style={{ fontSize: 14, fontFamily: '"PMingLiU",serif' }}>
        本平台、服務或材料不含任何電腦病毒或缺陷、其他惡意、破壞性或腐敗的代碼、代理、程式或巨集或其他有害的組件；
      </span>
    </li>
    <li
      style={{
        marginTop: "0cm",
        marginRight: "0cm",
        marginBottom: "8.0pt",
        marginLeft: "0cm",
        lineHeight: "normal",
        fontSize: 16,
        fontFamily: '"Aptos",sans-serif',
        color: "#1A1A1A",
        textAlign: "justify"
      }}
    >
      <span style={{ fontSize: 14, fontFamily: '"PMingLiU",serif' }}>
        閣下透過本平台或服務傳輸或接收任何信息的安全性，並且閣下接受透過本平台發送或接收任何信息時可能會被未經授權的第三方使用及
      </span>
      <span style={{ fontSize: 14, fontFamily: '"Nunito Sans"' }}>/</span>
      <span style={{ fontSize: 14, fontFamily: '"PMingLiU",serif' }}>
        或由我們或我們的員工或代理向第三方披露的風險，這些行為被看作是閣下或是在閣下授權下進行的。此外，互聯網的公共性質，互聯網和電子郵件的傳輸可能會中斷，停頓或因互聯網流量或不正確的數據傳輸而造成延遲。
      </span>
    </li>
  </ol>
  <p
    style={{
      marginTop: "0cm",
      marginRight: "0cm",
      marginBottom: "22.5pt",
      marginLeft: "0cm",
      lineHeight: "normal",
      fontSize: 16,
      fontFamily: '"Aptos",sans-serif',
      textAlign: "justify"
    }}
  >
    <span
      style={{ fontSize: 14, fontFamily: '"PMingLiU",serif', color: "#1A1A1A" }}
    >
      閣下需自行負責配置閣下的信息技術，電腦程序和平台來進入和使用本平台及
    </span>
    <span
      style={{ fontSize: 14, fontFamily: '"Nunito Sans"', color: "#1A1A1A" }}
    >
      /
    </span>
    <span
      style={{ fontSize: 14, fontFamily: '"PMingLiU",serif', color: "#1A1A1A" }}
    >
      或服務，並使用閣下的病毒防護軟件。
    </span>
  </p>
  <p
    style={{
      marginTop: "0cm",
      marginRight: "0cm",
      marginBottom: "0cm",
      marginLeft: "0cm",
      lineHeight: "normal",
      fontSize: 16,
      fontFamily: '"Aptos",sans-serif',
      textAlign: "justify"
    }}
  >
    <span
      style={{ fontSize: 14, fontFamily: '"Nunito Sans"', color: "#1A1A1A" }}
    >
      1
    </span>
    <span
      style={{ fontSize: 14, fontFamily: '"Nunito Sans"', color: "#1A1A1A" }}
    >
      3
    </span>
    <span
      style={{ fontSize: 14, fontFamily: '"Nunito Sans"', color: "#1A1A1A" }}
    >
      .2&nbsp;
    </span>
    <span
      style={{ fontSize: 14, fontFamily: '"PMingLiU",serif', color: "#1A1A1A" }}
    >
      排除責任
    </span>
  </p>
  <p
    style={{
      marginTop: "0cm",
      marginRight: "0cm",
      marginBottom: "0cm",
      marginLeft: "0cm",
      lineHeight: "normal",
      fontSize: 16,
      fontFamily: '"Aptos",sans-serif',
      textAlign: "justify"
    }}
  >
    <span
      style={{ fontSize: 14, fontFamily: '"Nunito Sans"', color: "#1A1A1A" }}
    >
      Good
    </span>
    <span
      style={{ fontSize: 14, fontFamily: '"Nunito Sans"', color: "#1A1A1A" }}
    >
      &nbsp;Club&nbsp;
    </span>
    <span
      style={{ fontSize: 14, fontFamily: '"PMingLiU",serif', color: "#1A1A1A" }}
    >
      受償人對由於以下原因，無論是直接或間接引起的任何損失，在適用法律允許的範圍內均不承擔責任：
    </span>
  </p>
  <ol style={{ marginBottom: "0cm", marginTop: "0cm" }} start={1} type="a">
    <li
      style={{
        marginTop: "0cm",
        marginRight: "0cm",
        marginBottom: "8.0pt",
        marginLeft: "0cm",
        lineHeight: "normal",
        fontSize: 16,
        fontFamily: '"Aptos",sans-serif',
        color: "#1A1A1A",
        textAlign: "justify"
      }}
    >
      <span style={{ fontSize: 14, fontFamily: '"PMingLiU",serif' }}>
        進入、使用及
      </span>
      <span style={{ fontSize: 14, fontFamily: '"Nunito Sans"' }}>/</span>
      <span style={{ fontSize: 14, fontFamily: '"PMingLiU",serif' }}>
        或無法使用本平台或服務；
      </span>
    </li>
    <li
      style={{
        marginTop: "0cm",
        marginRight: "0cm",
        marginBottom: "8.0pt",
        marginLeft: "0cm",
        lineHeight: "normal",
        fontSize: 16,
        fontFamily: '"Aptos",sans-serif',
        color: "#1A1A1A",
        textAlign: "justify"
      }}
    >
      <span style={{ fontSize: 14, fontFamily: '"PMingLiU",serif' }}>
        閣下對透過本平台及
      </span>
      <span style={{ fontSize: 14, fontFamily: '"Nunito Sans"' }}>/</span>
      <span style={{ fontSize: 14, fontFamily: '"PMingLiU",serif' }}>
        或服務提供的任何數據或信息的依賴，應首先對其進行獨立驗證；
      </span>
    </li>
    <li
      style={{
        marginTop: "0cm",
        marginRight: "0cm",
        marginBottom: "8.0pt",
        marginLeft: "0cm",
        lineHeight: "normal",
        fontSize: 16,
        fontFamily: '"Aptos",sans-serif',
        color: "#1A1A1A",
        textAlign: "justify"
      }}
    >
      <span style={{ fontSize: 14, fontFamily: '"PMingLiU",serif' }}>
        任何系統、服務器或連接故障、錯誤、遺漏、中斷、傳輸延遲、電腦病毒或錯誤、其他惡意、破壞性的代碼、代理、程序或巨集或其他損壞或有害的組件；
      </span>
    </li>
    <li
      style={{
        marginTop: "0cm",
        marginRight: "0cm",
        marginBottom: "8.0pt",
        marginLeft: "0cm",
        lineHeight: "normal",
        fontSize: 16,
        fontFamily: '"Aptos",sans-serif',
        color: "#1A1A1A",
        textAlign: "justify"
      }}
    >
      <span style={{ fontSize: 14, fontFamily: '"PMingLiU",serif' }}>
        任何超出我們合理控制的事件；
      </span>
    </li>
    <li
      style={{
        marginTop: "0cm",
        marginRight: "0cm",
        marginBottom: "8.0pt",
        marginLeft: "0cm",
        lineHeight: "normal",
        fontSize: 16,
        fontFamily: '"Aptos",sans-serif',
        color: "#1A1A1A",
        textAlign: "justify"
      }}
    >
      <span style={{ fontSize: 14, fontFamily: '"PMingLiU",serif' }}>
        任何數據、數據庫或軟件的任何損失或損壞；
      </span>
      <span style={{ fontSize: 14, fontFamily: '"Nunito Sans"' }}>&nbsp;</span>
      <span style={{ fontSize: 14, fontFamily: '"PMingLiU",serif' }}>和</span>
    </li>
    <li
      style={{
        marginTop: "0cm",
        marginRight: "0cm",
        marginBottom: "8.0pt",
        marginLeft: "0cm",
        lineHeight: "normal",
        fontSize: 16,
        fontFamily: '"Aptos",sans-serif',
        color: "#1A1A1A",
        textAlign: "justify"
      }}
    >
      <span style={{ fontSize: 14, fontFamily: '"PMingLiU",serif' }}>
        使用或進入與平台相關的任何其他網站或網頁，即使我們或我們的職員、代理人或員工可能已經被告知或者可能已經預料到損失。
      </span>
    </li>
  </ol>
  <p
    style={{
      marginTop: "0cm",
      marginRight: "0cm",
      marginBottom: "22.5pt",
      marginLeft: "0cm",
      lineHeight: "normal",
      fontSize: 16,
      fontFamily: '"Aptos",sans-serif',
      textAlign: "justify"
    }}
  >
    <span
      style={{ fontSize: 14, fontFamily: '"Nunito Sans"', color: "#1A1A1A" }}
    >
      1
    </span>
    <span
      style={{ fontSize: 14, fontFamily: '"Nunito Sans"', color: "#1A1A1A" }}
    >
      3
    </span>
    <span
      style={{ fontSize: 14, fontFamily: '"Nunito Sans"', color: "#1A1A1A" }}
    >
      .3&nbsp;
    </span>
    <span
      style={{ fontSize: 14, fontFamily: '"PMingLiU",serif', color: "#1A1A1A" }}
    >
      承擔個人風險
    </span>
    <span
      style={{ fontSize: 14, fontFamily: '"Nunito Sans"', color: "#1A1A1A" }}
    >
      <br />
      &nbsp;
    </span>
    <span
      style={{ fontSize: 14, fontFamily: '"PMingLiU",serif', color: "#1A1A1A" }}
    >
      閣下同意因使用本平台導致的任何誤解、錯誤、損害、支出或損失的風險完全由閣下自己承擔，我們不承擔任何責任。
    </span>
  </p>
  <p
    style={{
      marginTop: "15.0pt",
      marginRight: "0cm",
      marginBottom: "15.0pt",
      marginLeft: "0cm",
      lineHeight: "normal",
      fontSize: 16,
      fontFamily: '"Aptos",sans-serif',
      textAlign: "justify"
    }}
  >
    <strong>
      <span
        style={{ fontSize: 14, fontFamily: '"Nunito Sans"', color: "#1A1A1A" }}
      >
        1
      </span>
    </strong>
    <strong>
      <span
        style={{ fontSize: 14, fontFamily: '"Nunito Sans"', color: "#1A1A1A" }}
      >
        4
      </span>
    </strong>
    <strong>
      <span
        style={{ fontSize: 14, fontFamily: '"Nunito Sans"', color: "#1A1A1A" }}
      >
        .&nbsp;
      </span>
    </strong>
    <strong>
      <span
        style={{
          fontSize: 14,
          fontFamily: '"PMingLiU",serif',
          color: "#1A1A1A"
        }}
      >
        超鏈接和廣告
      </span>
    </strong>
  </p>
  <p
    style={{
      marginTop: "0cm",
      marginRight: "0cm",
      marginBottom: "22.5pt",
      marginLeft: "0cm",
      lineHeight: "normal",
      fontSize: 16,
      fontFamily: '"Aptos",sans-serif',
      textAlign: "justify"
    }}
  >
    <span
      style={{ fontSize: 14, fontFamily: '"Nunito Sans"', color: "#1A1A1A" }}
    >
      1
    </span>
    <span
      style={{ fontSize: 14, fontFamily: '"Nunito Sans"', color: "#1A1A1A" }}
    >
      4
    </span>
    <span
      style={{ fontSize: 14, fontFamily: '"Nunito Sans"', color: "#1A1A1A" }}
    >
      .1&nbsp;
    </span>
    <span
      style={{ fontSize: 14, fontFamily: '"PMingLiU",serif', color: "#1A1A1A" }}
    >
      超鏈接
    </span>
    <span
      style={{ fontSize: 14, fontFamily: '"Nunito Sans"', color: "#1A1A1A" }}
    >
      <br />
      &nbsp;
    </span>
    <span
      style={{ fontSize: 14, fontFamily: '"PMingLiU",serif', color: "#1A1A1A" }}
    >
      我們的平台可能包含超鏈接到其他由第三方擁有或營運的網站或互聯網的內容。我們無法控制這些鏈接的網站或內容，因此我們不對任何鏈接網站的內容中包含的任何錯誤、遺漏、延誤、誹謗、淫穢、色情、褻瀆、不準確或任何其他令人反感的材料的後果承擔責任，也不會對閣下進入任何鏈接網站的後果承擔責任。我們沒有認可或驗證這些超鏈接網站或內容，閣下同意完全承擔進入或使用此類超鏈接的網站或內容之所有風險。
    </span>
  </p>
  <p
    style={{
      marginTop: "0cm",
      marginRight: "0cm",
      marginBottom: "0cm",
      marginLeft: "0cm",
      lineHeight: "normal",
      fontSize: 16,
      fontFamily: '"Aptos",sans-serif',
      textAlign: "justify"
    }}
  >
    <span
      style={{ fontSize: 14, fontFamily: '"Nunito Sans"', color: "#1A1A1A" }}
    >
      1
    </span>
    <span
      style={{ fontSize: 14, fontFamily: '"Nunito Sans"', color: "#1A1A1A" }}
    >
      4
    </span>
    <span
      style={{ fontSize: 14, fontFamily: '"Nunito Sans"', color: "#1A1A1A" }}
    >
      .2&nbsp;
    </span>
    <span
      style={{ fontSize: 14, fontFamily: '"PMingLiU",serif', color: "#1A1A1A" }}
    >
      鏈接至我們
    </span>
    <span
      style={{ fontSize: 14, fontFamily: '"Nunito Sans"', color: "#1A1A1A" }}
    >
      <br />
      &nbsp;
    </span>
    <span
      style={{ fontSize: 14, fontFamily: '"PMingLiU",serif', color: "#1A1A1A" }}
    >
      閣下可以鏈接到我們的平台，只要鏈接是公平合法和不會損害我們的聲譽或不會利用它取得利益。
    </span>
    <span
      style={{ fontSize: 14, fontFamily: '"Nunito Sans"', color: "#1A1A1A" }}
    >
      <br />
      &nbsp;
    </span>
    <span
      style={{ fontSize: 14, fontFamily: '"PMingLiU",serif', color: "#1A1A1A" }}
    >
      閣下不得建立鏈接：
    </span>
  </p>
  <ol style={{ marginBottom: "0cm", marginTop: "0cm" }} start={1} type="a">
    <li
      style={{
        marginTop: "0cm",
        marginRight: "0cm",
        marginBottom: "8.0pt",
        marginLeft: "0cm",
        lineHeight: "normal",
        fontSize: 16,
        fontFamily: '"Aptos",sans-serif',
        color: "#1A1A1A",
        textAlign: "justify"
      }}
    >
      <span style={{ fontSize: 14, fontFamily: '"PMingLiU",serif' }}>
        來意味我們與閣下有任何聯系、批准或認可
      </span>
      <span style={{ fontSize: 14, fontFamily: '"Nunito Sans"' }}>;</span>
    </li>
    <li
      style={{
        marginTop: "0cm",
        marginRight: "0cm",
        marginBottom: "8.0pt",
        marginLeft: "0cm",
        lineHeight: "normal",
        fontSize: 16,
        fontFamily: '"Aptos",sans-serif',
        color: "#1A1A1A",
        textAlign: "justify"
      }}
    >
      <span style={{ fontSize: 14, fontFamily: '"PMingLiU",serif' }}>
        於任何不屬於閣下的網站上。
      </span>
    </li>
  </ol>
  <p
    style={{
      marginTop: "0cm",
      marginRight: "0cm",
      marginBottom: "22.5pt",
      marginLeft: "0cm",
      lineHeight: "normal",
      fontSize: 16,
      fontFamily: '"Aptos",sans-serif',
      textAlign: "justify"
    }}
  >
    <span
      style={{ fontSize: 14, fontFamily: '"PMingLiU",serif', color: "#1A1A1A" }}
    >
      我們可以在沒有理由或事先通知閣下的情況下撤銷鏈接許可。
    </span>
  </p>
  <p
    style={{
      marginTop: "0cm",
      marginRight: "0cm",
      marginBottom: "22.5pt",
      marginLeft: "0cm",
      lineHeight: "normal",
      fontSize: 16,
      fontFamily: '"Aptos",sans-serif',
      textAlign: "justify"
    }}
  >
    <span
      style={{ fontSize: 14, fontFamily: '"Nunito Sans"', color: "#1A1A1A" }}
    >
      1
    </span>
    <span
      style={{ fontSize: 14, fontFamily: '"Nunito Sans"', color: "#1A1A1A" }}
    >
      4
    </span>
    <span
      style={{ fontSize: 14, fontFamily: '"Nunito Sans"', color: "#1A1A1A" }}
    >
      .3&nbsp;
    </span>
    <span
      style={{ fontSize: 14, fontFamily: '"PMingLiU",serif', color: "#1A1A1A" }}
    >
      廣告
    </span>
    <span
      style={{ fontSize: 14, fontFamily: '"Nunito Sans"', color: "#1A1A1A" }}
    >
      <br />
      &nbsp;
    </span>
    <span
      style={{ fontSize: 14, fontFamily: '"PMingLiU",serif', color: "#1A1A1A" }}
    >
      為了宣傳我們或我們供應商的產品及
    </span>
    <span
      style={{ fontSize: 14, fontFamily: '"Nunito Sans"', color: "#1A1A1A" }}
    >
      /
    </span>
    <span
      style={{ fontSize: 14, fontFamily: '"PMingLiU",serif', color: "#1A1A1A" }}
    >
      或服務，我們可能會在平台上貼上橫幅、
    </span>
    <span
      style={{ fontSize: 14, fontFamily: '"Nunito Sans"', color: "#1A1A1A" }}
    >
      Java&nbsp;
    </span>
    <span
      style={{ fontSize: 14, fontFamily: '"PMingLiU",serif', color: "#1A1A1A" }}
    >
      小程序及
    </span>
    <span
      style={{ fontSize: 14, fontFamily: '"Nunito Sans"', color: "#1A1A1A" }}
    >
      /
    </span>
    <span
      style={{ fontSize: 14, fontFamily: '"PMingLiU",serif', color: "#1A1A1A" }}
    >
      或其他材料。廣告的類型和範圍可能會更改。閣下無權就任何此類廣告或其他宣傳材料收取任何費用及
    </span>
    <span
      style={{ fontSize: 14, fontFamily: '"Nunito Sans"', color: "#1A1A1A" }}
    >
      /
    </span>
    <span
      style={{ fontSize: 14, fontFamily: '"PMingLiU",serif', color: "#1A1A1A" }}
    >
      或佣金。
    </span>
  </p>
  <p
    style={{
      marginTop: "15.0pt",
      marginRight: "0cm",
      marginBottom: "15.0pt",
      marginLeft: "0cm",
      lineHeight: "normal",
      fontSize: 16,
      fontFamily: '"Aptos",sans-serif',
      textAlign: "justify"
    }}
  >
    <strong>
      <span
        style={{ fontSize: 14, fontFamily: '"Nunito Sans"', color: "#1A1A1A" }}
      >
        1
      </span>
    </strong>
    <strong>
      <span
        style={{ fontSize: 14, fontFamily: '"Nunito Sans"', color: "#1A1A1A" }}
      >
        5
      </span>
    </strong>
    <strong>
      <span
        style={{ fontSize: 14, fontFamily: '"Nunito Sans"', color: "#1A1A1A" }}
      >
        .&nbsp;
      </span>
    </strong>
    <strong>
      <span
        style={{
          fontSize: 14,
          fontFamily: '"PMingLiU",serif',
          color: "#1A1A1A"
        }}
      >
        閣下提交的資訊
      </span>
    </strong>
  </p>
  <p
    style={{
      marginTop: "0cm",
      marginRight: "0cm",
      marginBottom: "22.5pt",
      marginLeft: "0cm",
      lineHeight: "normal",
      fontSize: 16,
      fontFamily: '"Aptos",sans-serif',
      textAlign: "justify"
    }}
  >
    <span
      style={{ fontSize: 14, fontFamily: '"Nunito Sans"', color: "#1A1A1A" }}
    >
      1
    </span>
    <span
      style={{ fontSize: 14, fontFamily: '"Nunito Sans"', color: "#1A1A1A" }}
    >
      5
    </span>
    <span
      style={{ fontSize: 14, fontFamily: '"Nunito Sans"', color: "#1A1A1A" }}
    >
      .1&nbsp;
    </span>
    <span
      style={{ fontSize: 14, fontFamily: '"PMingLiU",serif', color: "#1A1A1A" }}
    >
      閣下提交的資訊
    </span>
    <span
      style={{ fontSize: 14, fontFamily: '"Nunito Sans"', color: "#1A1A1A" }}
    >
      <br />
      &nbsp;
    </span>
    <span
      style={{ fontSize: 14, fontFamily: '"PMingLiU",serif', color: "#1A1A1A" }}
    >
      閣下授予我們全球性的、不可撤銷的、非專有的、免版稅的許可以作使用、複製、存儲、改編、出版、翻譯和分發閣下在任何現有或未來媒體上提交給平台及
    </span>
    <span
      style={{ fontSize: 14, fontFamily: '"Nunito Sans"', color: "#1A1A1A" }}
    >
      /
    </span>
    <span
      style={{ fontSize: 14, fontFamily: '"PMingLiU",serif', color: "#1A1A1A" }}
    >
      或我們（統稱為「提交」）的資料（包括但不限於文本（問題，評論，意見和建議）、圖像、圖像、音頻、視頻、視聽、腳本、軟件和文件）。閣下也授予我們對以上許可的權利進行再許可的權利。
    </span>
    <span
      style={{ fontSize: 14, fontFamily: '"Nunito Sans"', color: "#1A1A1A" }}
    >
      <br />
      &nbsp;
    </span>
    <span
      style={{ fontSize: 14, fontFamily: '"PMingLiU",serif', color: "#1A1A1A" }}
    >
      當閣下向本平台發布評論時，代表閣下也授予我們使用閣下提交的姓名或用戶名稱的權利。
    </span>
    <span
      style={{ fontSize: 14, fontFamily: '"Nunito Sans"', color: "#1A1A1A" }}
    >
      <br />
      &nbsp;
    </span>
    <span
      style={{ fontSize: 14, fontFamily: '"PMingLiU",serif', color: "#1A1A1A" }}
    >
      閣下不得使用虛假的電子郵件地址提交資訊，假裝成不是自己或者以其他方式誤導我們。我們可能會發布、刪除或編輯閣下提交的資料。
    </span>
    <span
      style={{ fontSize: 14, fontFamily: '"Nunito Sans"', color: "#1A1A1A" }}
    >
      <br />
      &nbsp;
    </span>
    <span
      style={{ fontSize: 14, fontFamily: '"PMingLiU",serif', color: "#1A1A1A" }}
    >
      閣下在此放棄在適用法律允許的最大範圍內提交內容中的所有精神權利。閣下保證並表示已在適用法律允許的最大範圍內放棄在提交資訊時的所有其他精神權利。
    </span>
  </p>
  <p
    style={{
      marginTop: "0cm",
      marginRight: "0cm",
      marginBottom: "22.5pt",
      marginLeft: "0cm",
      lineHeight: "normal",
      fontSize: 16,
      fontFamily: '"Aptos",sans-serif',
      textAlign: "justify"
    }}
  >
    <span
      style={{ fontSize: 14, fontFamily: '"Nunito Sans"', color: "#1A1A1A" }}
    >
      1
    </span>
    <span
      style={{ fontSize: 14, fontFamily: '"Nunito Sans"', color: "#1A1A1A" }}
    >
      5
    </span>
    <span
      style={{ fontSize: 14, fontFamily: '"Nunito Sans"', color: "#1A1A1A" }}
    >
      .2&nbsp;
    </span>
    <span
      style={{ fontSize: 14, fontFamily: '"PMingLiU",serif', color: "#1A1A1A" }}
    >
      個人資料及私隱政策
    </span>
    <span
      style={{ fontSize: 14, fontFamily: '"Nunito Sans"', color: "#1A1A1A" }}
    >
      <br />
      &nbsp;
    </span>
    <span
      style={{ fontSize: 14, fontFamily: '"PMingLiU",serif', color: "#1A1A1A" }}
    >
      當閣下使用平台和服務時候，閣下的服務賬戶註冊、商品購買及
    </span>
    <span
      style={{ fontSize: 14, fontFamily: '"Nunito Sans"', color: "#1A1A1A" }}
    >
      /
    </span>
    <span
      style={{ fontSize: 14, fontFamily: '"PMingLiU",serif', color: "#1A1A1A" }}
    >
      或申請或服務訂閱時，按照個人資料（私隱）條例和我們的私隱政策規定，個人資料及其他資料可能由我們收集、使用、披露及保留。閣下確認已閱讀並同意私隱政策（可通過平台查看），並同意我們按照私隱政策中列明的目的收集、使用和披露閣下的個人資料。
    </span>
  </p>
  <p
    style={{
      marginTop: "15.0pt",
      marginRight: "0cm",
      marginBottom: "15.0pt",
      marginLeft: "0cm",
      lineHeight: "normal",
      fontSize: 16,
      fontFamily: '"Aptos",sans-serif',
      textAlign: "justify"
    }}
  >
    <strong>
      <span
        style={{ fontSize: 14, fontFamily: '"Nunito Sans"', color: "#1A1A1A" }}
      >
        1
      </span>
    </strong>
    <strong>
      <span
        style={{ fontSize: 14, fontFamily: '"Nunito Sans"', color: "#1A1A1A" }}
      >
        6
      </span>
    </strong>
    <strong>
      <span
        style={{ fontSize: 14, fontFamily: '"Nunito Sans"', color: "#1A1A1A" }}
      >
        .&nbsp;
      </span>
    </strong>
    <strong>
      <span
        style={{
          fontSize: 14,
          fontFamily: '"PMingLiU",serif',
          color: "#1A1A1A"
        }}
      >
        終止
      </span>
    </strong>
  </p>
  <p
    style={{
      marginTop: "0cm",
      marginRight: "0cm",
      marginBottom: "0cm",
      marginLeft: "0cm",
      lineHeight: "normal",
      fontSize: 16,
      fontFamily: '"Aptos",sans-serif',
      textAlign: "justify"
    }}
  >
    <span
      style={{ fontSize: 14, fontFamily: '"Nunito Sans"', color: "#1A1A1A" }}
    >
      1
    </span>
    <span
      style={{ fontSize: 14, fontFamily: '"Nunito Sans"', color: "#1A1A1A" }}
    >
      6
    </span>
    <span
      style={{ fontSize: 14, fontFamily: '"Nunito Sans"', color: "#1A1A1A" }}
    >
      .1&nbsp;
    </span>
    <span
      style={{ fontSize: 14, fontFamily: '"PMingLiU",serif', color: "#1A1A1A" }}
    >
      我方通知的終止
    </span>
    <span
      style={{ fontSize: 14, fontFamily: '"Nunito Sans"', color: "#1A1A1A" }}
    >
      <br />
      &nbsp;
    </span>
    <span
      style={{ fontSize: 14, fontFamily: '"PMingLiU",serif', color: "#1A1A1A" }}
    >
      我們可能會即時暫停或終止閣下使用平台及
    </span>
    <span
      style={{ fontSize: 14, fontFamily: '"Nunito Sans"', color: "#1A1A1A" }}
    >
      /
    </span>
    <span
      style={{ fontSize: 14, fontFamily: '"PMingLiU",serif', color: "#1A1A1A" }}
    >
      或服務及
    </span>
    <span
      style={{ fontSize: 14, fontFamily: '"Nunito Sans"', color: "#1A1A1A" }}
    >
      /
    </span>
    <span
      style={{ fontSize: 14, fontFamily: '"PMingLiU",serif', color: "#1A1A1A" }}
    >
      或閣下任何的服務賬戶及
    </span>
    <span
      style={{ fontSize: 14, fontFamily: '"Nunito Sans"', color: "#1A1A1A" }}
    >
      /
    </span>
    <span
      style={{ fontSize: 14, fontFamily: '"PMingLiU",serif', color: "#1A1A1A" }}
    >
      或禁用閣下的用戶名稱和密碼。我們可能出於任何原因禁止閣下進入及使用平台及
    </span>
    <span
      style={{ fontSize: 14, fontFamily: '"Nunito Sans"', color: "#1A1A1A" }}
    >
      /
    </span>
    <span
      style={{ fontSize: 14, fontFamily: '"PMingLiU",serif', color: "#1A1A1A" }}
    >
      或服務（或其任何部分），包括違反任何本使用條款或任何適用的條款及細則，或者閣下的行為與任何適用的條款及細則不一致，或者我們或任何監管機構認為本平台不適合繼續提供有關的服務。
    </span>
    <span
      style={{ fontSize: 14, fontFamily: '"Nunito Sans"', color: "#1A1A1A" }}
    >
      <br />
      &nbsp;
    </span>
    <span
      style={{ fontSize: 14, fontFamily: '"PMingLiU",serif', color: "#1A1A1A" }}
    >
      在無損我們在本條款及細則下的其他權利，如果閣下以任何方式違反了這些使用條款，或者我們有理由懷疑閣下以任何方式違反了本使用條款或其他適用的條款及細則，我們也可能：
    </span>
  </p>
  <ol style={{ marginBottom: "0cm", marginTop: "0cm" }} start={1} type="a">
    <li
      style={{
        marginTop: "0cm",
        marginRight: "0cm",
        marginBottom: "8.0pt",
        marginLeft: "0cm",
        lineHeight: "normal",
        fontSize: 16,
        fontFamily: '"Aptos",sans-serif',
        color: "#1A1A1A",
        textAlign: "justify"
      }}
    >
      <span style={{ fontSize: 14, fontFamily: '"PMingLiU",serif' }}>
        阻止以閣下
      </span>
      <span style={{ fontSize: 14, fontFamily: '"Nunito Sans"' }}>IP</span>
      <span style={{ fontSize: 14, fontFamily: '"PMingLiU",serif' }}>
        地址進入本平台及
      </span>
      <span style={{ fontSize: 14, fontFamily: '"Nunito Sans"' }}>/</span>
      <span style={{ fontSize: 14, fontFamily: '"PMingLiU",serif' }}>
        或服務的電腦；
      </span>
    </li>
    <li
      style={{
        marginTop: "0cm",
        marginRight: "0cm",
        marginBottom: "8.0pt",
        marginLeft: "0cm",
        lineHeight: "normal",
        fontSize: 16,
        fontFamily: '"Aptos",sans-serif',
        color: "#1A1A1A",
        textAlign: "justify"
      }}
    >
      <span style={{ fontSize: 14, fontFamily: '"PMingLiU",serif' }}>
        聯絡閣下的所有互聯網服務供應商，並要求他們停止閣下進入及使用本平台及
      </span>
      <span style={{ fontSize: 14, fontFamily: '"Nunito Sans"' }}>/</span>
      <span style={{ fontSize: 14, fontFamily: '"PMingLiU",serif' }}>
        或服務；
      </span>
    </li>
    <li
      style={{
        marginTop: "0cm",
        marginRight: "0cm",
        marginBottom: "8.0pt",
        marginLeft: "0cm",
        lineHeight: "normal",
        fontSize: 16,
        fontFamily: '"Aptos",sans-serif',
        color: "#1A1A1A",
        textAlign: "justify"
      }}
    >
      <span style={{ fontSize: 14, fontFamily: '"PMingLiU",serif' }}>
        對閣下採取法律行動，無論是基於違約或其他原因
      </span>
      <span style={{ fontSize: 14, fontFamily: '"Nunito Sans"' }}>;</span>
      <span style={{ fontSize: 14, fontFamily: '"PMingLiU",serif' }}>及</span>
      <span style={{ fontSize: 14, fontFamily: '"Nunito Sans"' }}>/</span>
      <span style={{ fontSize: 14, fontFamily: '"PMingLiU",serif' }}>或</span>
    </li>
    <li
      style={{
        marginTop: "0cm",
        marginRight: "0cm",
        marginBottom: "8.0pt",
        marginLeft: "0cm",
        lineHeight: "normal",
        fontSize: 16,
        fontFamily: '"Aptos",sans-serif',
        color: "#1A1A1A",
        textAlign: "justify"
      }}
    >
      <span style={{ fontSize: 14, fontFamily: '"PMingLiU",serif' }}>
        暫停或刪除閣下的賬戶。
      </span>
    </li>
  </ol>
  <p
    style={{
      marginTop: "0cm",
      marginRight: "0cm",
      marginBottom: "22.5pt",
      marginLeft: "0cm",
      lineHeight: "normal",
      fontSize: 16,
      fontFamily: '"Aptos",sans-serif',
      textAlign: "justify"
    }}
  >
    <span
      style={{ fontSize: 14, fontFamily: '"Nunito Sans"', color: "#1A1A1A" }}
    >
      1
    </span>
    <span
      style={{ fontSize: 14, fontFamily: '"Nunito Sans"', color: "#1A1A1A" }}
    >
      6
    </span>
    <span
      style={{ fontSize: 14, fontFamily: '"Nunito Sans"', color: "#1A1A1A" }}
    >
      .2&nbsp;
    </span>
    <span
      style={{ fontSize: 14, fontFamily: '"PMingLiU",serif', color: "#1A1A1A" }}
    >
      閣下提出的終止
    </span>
    <span
      style={{ fontSize: 14, fontFamily: '"Nunito Sans"', color: "#1A1A1A" }}
    >
      <br />
      &nbsp;
    </span>
    <span
      style={{ fontSize: 14, fontFamily: '"PMingLiU",serif', color: "#1A1A1A" }}
    >
      閣下可以透過相關平台的聯絡資料，發出事先書面通知書通知我們取消服務賬戶。
    </span>
  </p>
  <p
    style={{
      marginTop: "15.0pt",
      marginRight: "0cm",
      marginBottom: "15.0pt",
      marginLeft: "0cm",
      lineHeight: "normal",
      fontSize: 16,
      fontFamily: '"Aptos",sans-serif',
      textAlign: "justify"
    }}
  >
    <strong>
      <span
        style={{ fontSize: 14, fontFamily: '"Nunito Sans"', color: "#1A1A1A" }}
      >
        1
      </span>
    </strong>
    <strong>
      <span
        style={{ fontSize: 14, fontFamily: '"Nunito Sans"', color: "#1A1A1A" }}
      >
        7
      </span>
    </strong>
    <strong>
      <span
        style={{ fontSize: 14, fontFamily: '"Nunito Sans"', color: "#1A1A1A" }}
      >
        .&nbsp;
      </span>
    </strong>
    <strong>
      <span
        style={{
          fontSize: 14,
          fontFamily: '"PMingLiU",serif',
          color: "#1A1A1A"
        }}
      >
        概括
      </span>
    </strong>
  </p>
  <p
    style={{
      marginTop: "0cm",
      marginRight: "0cm",
      marginBottom: "22.5pt",
      marginLeft: "0cm",
      lineHeight: "normal",
      fontSize: 16,
      fontFamily: '"Aptos",sans-serif',
      textAlign: "justify"
    }}
  >
    <span
      style={{ fontSize: 14, fontFamily: '"Nunito Sans"', color: "#1A1A1A" }}
    >
      1
    </span>
    <span
      style={{ fontSize: 14, fontFamily: '"Nunito Sans"', color: "#1A1A1A" }}
    >
      7
    </span>
    <span
      style={{ fontSize: 14, fontFamily: '"Nunito Sans"', color: "#1A1A1A" }}
    >
      .1&nbsp;
    </span>
    <span
      style={{ fontSize: 14, fontFamily: '"PMingLiU",serif', color: "#1A1A1A" }}
    >
      電子通訊
    </span>
    <span
      style={{ fontSize: 14, fontFamily: '"Nunito Sans"', color: "#1A1A1A" }}
    >
      <br />
      &nbsp;
    </span>
    <span
      style={{ fontSize: 14, fontFamily: '"PMingLiU",serif', color: "#1A1A1A" }}
    >
      我們可能會透過平台發布通知和以我們認為合適的方式與閣下聯繫。閣下同意我們透過電子方式、手機文字或多媒體短信或其他方式向閣下提供的所有協議、通知和其他交流均符合任何此類通信以書面形式提出的法律要求。
    </span>
  </p>
  <p
    style={{
      marginTop: "0cm",
      marginRight: "0cm",
      marginBottom: "22.5pt",
      marginLeft: "0cm",
      lineHeight: "normal",
      fontSize: 16,
      fontFamily: '"Aptos",sans-serif',
      textAlign: "justify"
    }}
  >
    <span
      style={{ fontSize: 14, fontFamily: '"Nunito Sans"', color: "#1A1A1A" }}
    >
      1
    </span>
    <span
      style={{ fontSize: 14, fontFamily: '"Nunito Sans"', color: "#1A1A1A" }}
    >
      7
    </span>
    <span
      style={{ fontSize: 14, fontFamily: '"Nunito Sans"', color: "#1A1A1A" }}
    >
      .2&nbsp;
    </span>
    <span
      style={{ fontSize: 14, fontFamily: '"PMingLiU",serif', color: "#1A1A1A" }}
    >
      累積權利和補救措施
    </span>
    <span
      style={{ fontSize: 14, fontFamily: '"Nunito Sans"', color: "#1A1A1A" }}
    >
      <br />
      &nbsp;
    </span>
    <span
      style={{ fontSize: 14, fontFamily: '"PMingLiU",serif', color: "#1A1A1A" }}
    >
      除非另有明確規定，我們在這些使用條款下的權利和補救措施除了是累積及沒偏見外，亦不會因為我們行使了某條款權利和補救措施而令其他條款權利和補救措施不適用。
    </span>
  </p>
  <p
    style={{
      marginTop: "0cm",
      marginRight: "0cm",
      marginBottom: "22.5pt",
      marginLeft: "0cm",
      lineHeight: "normal",
      fontSize: 16,
      fontFamily: '"Aptos",sans-serif',
      textAlign: "justify"
    }}
  >
    <span
      style={{ fontSize: 14, fontFamily: '"Nunito Sans"', color: "#1A1A1A" }}
    >
      1
    </span>
    <span
      style={{ fontSize: 14, fontFamily: '"Nunito Sans"', color: "#1A1A1A" }}
    >
      7
    </span>
    <span
      style={{ fontSize: 14, fontFamily: '"Nunito Sans"', color: "#1A1A1A" }}
    >
      .3&nbsp;
    </span>
    <span
      style={{ fontSize: 14, fontFamily: '"PMingLiU",serif', color: "#1A1A1A" }}
    >
      不放棄
    </span>
    <span
      style={{ fontSize: 14, fontFamily: '"Nunito Sans"', color: "#1A1A1A" }}
    >
      <br />
      &nbsp;
    </span>
    <span
      style={{ fontSize: 14, fontFamily: '"PMingLiU",serif', color: "#1A1A1A" }}
    >
      我們未能執行這些使用條款並不代表我們放棄使用這些條款，絕對不會影響我們以後執行這些使用條款的權利。如果閣下違反了這些使用條款，我們仍然有權在任何情況下行使我們的權利和補救措施。
    </span>
  </p>
  <p
    style={{
      marginTop: "0cm",
      marginRight: "0cm",
      marginBottom: "22.5pt",
      marginLeft: "0cm",
      lineHeight: "normal",
      fontSize: 16,
      fontFamily: '"Aptos",sans-serif',
      textAlign: "justify"
    }}
  >
    <span
      style={{ fontSize: 14, fontFamily: '"Nunito Sans"', color: "#1A1A1A" }}
    >
      1
    </span>
    <span
      style={{ fontSize: 14, fontFamily: '"Nunito Sans"', color: "#1A1A1A" }}
    >
      7
    </span>
    <span
      style={{ fontSize: 14, fontFamily: '"Nunito Sans"', color: "#1A1A1A" }}
    >
      .4&nbsp;
    </span>
    <span
      style={{ fontSize: 14, fontFamily: '"PMingLiU",serif', color: "#1A1A1A" }}
    >
      分割
    </span>
    <span
      style={{ fontSize: 14, fontFamily: '"Nunito Sans"', color: "#1A1A1A" }}
    >
      <br />
      &nbsp;
    </span>
    <span
      style={{ fontSize: 14, fontFamily: '"PMingLiU",serif', color: "#1A1A1A" }}
    >
      即使本使用條款中的任何條款成為非法的，無效的或不可執行的，本使用條款其餘合法，有效和可執行的條款不應因此受到影響或損害，並且是可繼續執行的。
    </span>
  </p>
  <p
    style={{
      marginTop: "0cm",
      marginRight: "0cm",
      marginBottom: "22.5pt",
      marginLeft: "0cm",
      lineHeight: "normal",
      fontSize: 16,
      fontFamily: '"Aptos",sans-serif',
      textAlign: "justify"
    }}
  >
    <span
      style={{ fontSize: 14, fontFamily: '"Nunito Sans"', color: "#1A1A1A" }}
    >
      1
    </span>
    <span
      style={{ fontSize: 14, fontFamily: '"Nunito Sans"', color: "#1A1A1A" }}
    >
      7
    </span>
    <span
      style={{ fontSize: 14, fontFamily: '"Nunito Sans"', color: "#1A1A1A" }}
    >
      .5&nbsp;
    </span>
    <span
      style={{ fontSize: 14, fontFamily: '"PMingLiU",serif', color: "#1A1A1A" }}
    >
      第三方的權利
    </span>
    <span
      style={{ fontSize: 14, fontFamily: '"Nunito Sans"', color: "#1A1A1A" }}
    >
      <br />
      &nbsp;
    </span>
    <span
      style={{ fontSize: 14, fontFamily: '"PMingLiU",serif', color: "#1A1A1A" }}
    >
      除
    </span>
    <span
      style={{ fontSize: 14, fontFamily: '"Nunito Sans"', color: "#1A1A1A" }}
    >
      &nbsp;
    </span>
    <span
      style={{ fontSize: 14, fontFamily: '"Nunito Sans"', color: "#1A1A1A" }}
    >
      Good Club
    </span>
    <span
      style={{ fontSize: 14, fontFamily: '"PMingLiU",serif', color: "#1A1A1A" }}
    >
      的集團公司或聯屬公司外，任何並非本使用條款的一方的人士均無權根據香港「合約（第三者權利）條例」（第
    </span>
    <span
      style={{ fontSize: 14, fontFamily: '"Nunito Sans"', color: "#1A1A1A" }}
    >
      623
    </span>
    <span
      style={{ fontSize: 14, fontFamily: '"PMingLiU",serif', color: "#1A1A1A" }}
    >
      章）執行本使用條款的任何條款及
    </span>
    <span
      style={{ fontSize: 14, fontFamily: '"Nunito Sans"', color: "#1A1A1A" }}
    >
      /
    </span>
    <span
      style={{ fontSize: 14, fontFamily: '"PMingLiU",serif', color: "#1A1A1A" }}
    >
      或利益。
    </span>
  </p>
  <p
    style={{
      marginTop: "0cm",
      marginRight: "0cm",
      marginBottom: "22.5pt",
      marginLeft: "0cm",
      lineHeight: "normal",
      fontSize: 16,
      fontFamily: '"Aptos",sans-serif',
      textAlign: "justify"
    }}
  >
    <span
      style={{ fontSize: 14, fontFamily: '"Nunito Sans"', color: "#1A1A1A" }}
    >
      1
    </span>
    <span
      style={{ fontSize: 14, fontFamily: '"Nunito Sans"', color: "#1A1A1A" }}
    >
      7
    </span>
    <span
      style={{ fontSize: 14, fontFamily: '"Nunito Sans"', color: "#1A1A1A" }}
    >
      .6&nbsp;
    </span>
    <span
      style={{ fontSize: 14, fontFamily: '"PMingLiU",serif', color: "#1A1A1A" }}
    >
      治理法律
    </span>
    <span
      style={{ fontSize: 14, fontFamily: '"Nunito Sans"', color: "#1A1A1A" }}
    >
      <br />
      &nbsp;
    </span>
    <span
      style={{ fontSize: 14, fontFamily: '"PMingLiU",serif', color: "#1A1A1A" }}
    >
      使用平台及
    </span>
    <span
      style={{ fontSize: 14, fontFamily: '"Nunito Sans"', color: "#1A1A1A" }}
    >
      /
    </span>
    <span
      style={{ fontSize: 14, fontFamily: '"PMingLiU",serif', color: "#1A1A1A" }}
    >
      或服務以及這些使用條款應受中華人民共和國香港特別行政區（以下簡稱「香港」）法律的管轄和解釋。香港法院擁有專屬司法管轄權，可聆訊及裁定任何訴訟或法律程序，並解決可能因本使用條款或與本使用條款有關而引起的任何爭議。
    </span>
  </p>
  <p
    style={{
      marginTop: "0cm",
      marginRight: "0cm",
      marginBottom: "22.5pt",
      marginLeft: "0cm",
      lineHeight: "normal",
      fontSize: 16,
      fontFamily: '"Aptos",sans-serif',
      textAlign: "justify"
    }}
  >
    <span
      style={{ fontSize: 14, fontFamily: '"Nunito Sans"', color: "#1A1A1A" }}
    >
      1
    </span>
    <span
      style={{ fontSize: 14, fontFamily: '"Nunito Sans"', color: "#1A1A1A" }}
    >
      7
    </span>
    <span
      style={{ fontSize: 14, fontFamily: '"Nunito Sans"', color: "#1A1A1A" }}
    >
      .7&nbsp;
    </span>
    <span
      style={{ fontSize: 14, fontFamily: '"PMingLiU",serif', color: "#1A1A1A" }}
    >
      禁制令
    </span>
    <span
      style={{ fontSize: 14, fontFamily: '"Nunito Sans"', color: "#1A1A1A" }}
    >
      <br />
      &nbsp;
    </span>
    <span
      style={{ fontSize: 14, fontFamily: '"PMingLiU",serif', color: "#1A1A1A" }}
    >
      如果我們真誠地確定違約或不履行合約的唯一適當或足夠的補救是禁制令，則我們可以立即尋求禁制令。
    </span>
  </p>
  <p
    style={{
      marginTop: "0cm",
      marginRight: "0cm",
      marginBottom: "22.5pt",
      marginLeft: "0cm",
      lineHeight: "normal",
      fontSize: 16,
      fontFamily: '"Aptos",sans-serif',
      textAlign: "justify"
    }}
  >
    <span
      style={{ fontSize: 14, fontFamily: '"Nunito Sans"', color: "#1A1A1A" }}
    >
      1
    </span>
    <span
      style={{ fontSize: 14, fontFamily: '"Nunito Sans"', color: "#1A1A1A" }}
    >
      7
    </span>
    <span
      style={{ fontSize: 14, fontFamily: '"Nunito Sans"', color: "#1A1A1A" }}
    >
      .8&nbsp;
    </span>
    <span
      style={{ fontSize: 14, fontFamily: '"PMingLiU",serif', color: "#1A1A1A" }}
    >
      修訂
    </span>
    <span
      style={{ fontSize: 14, fontFamily: '"Nunito Sans"', color: "#1A1A1A" }}
    >
      <br />
      &nbsp;
    </span>
    <span
      style={{ fontSize: 14, fontFamily: '"PMingLiU",serif', color: "#1A1A1A" }}
    >
      我們可能透過平台或以其他通知方式修改本使用條款中的條款及細則。這些修改在我們通過上述方式指定的日期生效。如果閣下在此日期之後使用本平台或服務，則視為閣下已接受這些修改。如果閣下不接受這些修改，則必須停止進入或使用本平台和服務，並終止這些使用條款。
    </span>
  </p>
  <p
    style={{
      marginTop: "0cm",
      marginRight: "0cm",
      marginBottom: "22.5pt",
      marginLeft: "0cm",
      lineHeight: "normal",
      fontSize: 16,
      fontFamily: '"Aptos",sans-serif',
      textAlign: "justify"
    }}
  >
    <span
      style={{ fontSize: 14, fontFamily: '"Nunito Sans"', color: "#1A1A1A" }}
    >
      1
    </span>
    <span
      style={{ fontSize: 14, fontFamily: '"Nunito Sans"', color: "#1A1A1A" }}
    >
      7
    </span>
    <span
      style={{ fontSize: 14, fontFamily: '"Nunito Sans"', color: "#1A1A1A" }}
    >
      .9&nbsp;
    </span>
    <span
      style={{ fontSize: 14, fontFamily: '"PMingLiU",serif', color: "#1A1A1A" }}
    >
      有約束力和決定性的
    </span>
    <span
      style={{ fontSize: 14, fontFamily: '"Nunito Sans"', color: "#1A1A1A" }}
    >
      <br />
      &nbsp;
    </span>
    <span
      style={{ fontSize: 14, fontFamily: '"PMingLiU",serif', color: "#1A1A1A" }}
    >
      閣下確認並同意，我們或我們的服務供應商就平台和服務保留的任何記錄（包括與服務有關的任何電話交談記錄），對閣下都具有約束力和決定性，並且是我們和閣下之間傳輸任何信息及
    </span>
    <span
      style={{ fontSize: 14, fontFamily: '"Nunito Sans"', color: "#1A1A1A" }}
    >
      /
    </span>
    <span
      style={{ fontSize: 14, fontFamily: '"PMingLiU",serif', color: "#1A1A1A" }}
    >
      或數據的確鑿證據。閣下在此同意所有這些記錄都可以作為證據，而且閣下不得僅僅因這些記錄是電子形式或是電腦系統的輸出而對這些記錄的可接受性、可靠性、準確性或真實性提出質疑或爭議，並且閣下在此放棄任何權利來反對（如有的話）。
    </span>
    <span
      style={{ fontSize: 14, fontFamily: '"Nunito Sans"', color: "#1A1A1A" }}
    >
      &nbsp;
    </span>
  </p>
  <p
    style={{
      marginTop: "0cm",
      marginRight: "0cm",
      marginBottom: "22.5pt",
      marginLeft: "0cm",
      lineHeight: "normal",
      fontSize: 16,
      fontFamily: '"Aptos",sans-serif',
      textAlign: "justify"
    }}
  >
    <span
      style={{ fontSize: 14, fontFamily: '"Nunito Sans"', color: "#1A1A1A" }}
    >
      &nbsp;
    </span>
  </p>
  <p
    style={{
      marginTop: "15.0pt",
      marginRight: "0cm",
      marginBottom: "15.0pt",
      marginLeft: "0cm",
      lineHeight: "normal",
      fontSize: 16,
      fontFamily: '"Aptos",sans-serif',
      textAlign: "justify"
    }}
  >
    <strong>
      <span
        style={{
          fontSize: 18,
          fontFamily: '"PMingLiU",serif',
          color: "#1A1A1A"
        }}
      >
        銷售條款及細則
      </span>
    </strong>
  </p>
  <p
    style={{
      marginTop: "0cm",
      marginRight: "0cm",
      marginBottom: "22.5pt",
      marginLeft: "0cm",
      lineHeight: "normal",
      fontSize: 16,
      fontFamily: '"Aptos",sans-serif',
      textAlign: "justify"
    }}
  >
    <span
      style={{ fontSize: 14, fontFamily: '"PMingLiU",serif', color: "#1A1A1A" }}
    >
      以下的銷售條款及細則（包括任何相關的政策和通知）（「銷售條款及細則」）適用於閣下在
    </span>
    <span
      style={{ fontSize: 14, fontFamily: '"Nunito Sans"', color: "#1A1A1A" }}
    >
      &nbsp;
    </span>
    <span
      style={{ fontSize: 14, fontFamily: '"Nunito Sans"', color: "#1A1A1A" }}
    >
      goodclub.hk
    </span>
    <span
      style={{ fontSize: 14, fontFamily: '"PMingLiU",serif', color: "#1A1A1A" }}
    >
      或
    </span>
    <span
      style={{ fontSize: 14, fontFamily: '"Nunito Sans"', color: "#1A1A1A" }}
    >
      goodclub.com.hk
    </span>
    <span
      style={{ fontSize: 14, fontFamily: '"PMingLiU",serif', color: "#1A1A1A" }}
    >
      （以下統稱「網站」，包括提供的所有產品和服務）購買商品及
    </span>
    <span
      style={{ fontSize: 14, fontFamily: '"Nunito Sans"', color: "#1A1A1A" }}
    >
      /
    </span>
    <span
      style={{ fontSize: 14, fontFamily: '"PMingLiU",serif', color: "#1A1A1A" }}
    >
      或服務（「商品」）。如閣下在
    </span>
    <span
      style={{ fontSize: 14, fontFamily: '"Nunito Sans"', color: "#1A1A1A" }}
    >
      &nbsp;
    </span>
    <span
      style={{ fontSize: 14, fontFamily: '"Nunito Sans"', color: "#1A1A1A" }}
    >
      Good Club
    </span>
    <span
      style={{ fontSize: 14, fontFamily: '"Nunito Sans"', color: "#1A1A1A" }}
    >
      &nbsp;
    </span>
    <span
      style={{ fontSize: 14, fontFamily: '"PMingLiU",serif', color: "#1A1A1A" }}
    >
      網站上或通過
    </span>
    <span
      style={{ fontSize: 14, fontFamily: '"Nunito Sans"', color: "#1A1A1A" }}
    >
      &nbsp;
    </span>
    <span
      style={{ fontSize: 14, fontFamily: '"Nunito Sans"', color: "#1A1A1A" }}
    >
      Good Club
    </span>
    <span
      style={{ fontSize: 14, fontFamily: '"PMingLiU",serif', color: "#1A1A1A" }}
    >
      網站購買或申請任何保險產品或服務，此類交易受相關保單文件和條款和細則約束及不受這銷售條款及細則約束。閣下同意遵守這些銷售條款及細則，我們可能不時發布或修改的所有相關政策，恕不另行通知。除非另有界定，否則本協議詞彙應具有使用條款中定義的含義。
    </span>
  </p>
  <p
    style={{
      marginTop: "15.0pt",
      marginRight: "0cm",
      marginBottom: "15.0pt",
      marginLeft: "0cm",
      lineHeight: "normal",
      fontSize: 16,
      fontFamily: '"Aptos",sans-serif',
      textAlign: "justify"
    }}
  >
    <strong>
      <span
        style={{ fontSize: 14, fontFamily: '"Nunito Sans"', color: "#1A1A1A" }}
      >
        1.&nbsp;
      </span>
    </strong>
    <strong>
      <span
        style={{
          fontSize: 14,
          fontFamily: '"PMingLiU",serif',
          color: "#1A1A1A"
        }}
      >
        購物
      </span>
    </strong>
    <strong>
      <span
        style={{ fontSize: 14, fontFamily: '"Nunito Sans"', color: "#1A1A1A" }}
      >
        /
      </span>
    </strong>
    <strong>
      <span
        style={{
          fontSize: 14,
          fontFamily: '"PMingLiU",serif',
          color: "#1A1A1A"
        }}
      >
        兌換獎賞
      </span>
    </strong>
  </p>
  <ol style={{ marginBottom: "0cm", marginTop: "0cm" }} start={1} type="a">
    <ol style={{ marginBottom: "0cm", marginTop: "0cm" }} start={1} type="a">
      <ol style={{ marginBottom: "0cm", marginTop: "0cm" }} start={1} type="a">
        <li
          style={{
            marginTop: "0cm",
            marginRight: "0cm",
            marginBottom: "8.0pt",
            marginLeft: "0cm",
            lineHeight: "normal",
            fontSize: 16,
            fontFamily: '"Aptos",sans-serif',
            color: "#1A1A1A",
            textAlign: "justify"
          }}
        >
          <span style={{ fontSize: 14, fontFamily: '"PMingLiU",serif' }}>
            網站上的所有商品均由我們或供應商銷售。但是，我們或供應商並不保證網站上所有商品描述的準確性。閣下保證沒有依賴我們或供應商在網站上作出的任何描述（包括但不限於措辭、圖片、照片、規格、視頻或音頻），並且閣下同意我們或供應商對此類描述不承擔任何責任。
          </span>
        </li>
        <li
          style={{
            marginTop: "0cm",
            marginRight: "0cm",
            marginBottom: "8.0pt",
            marginLeft: "0cm",
            lineHeight: "normal",
            fontSize: 16,
            fontFamily: '"Aptos",sans-serif',
            color: "#1A1A1A",
            textAlign: "justify"
          }}
        >
          <span style={{ fontSize: 14, fontFamily: '"PMingLiU",serif' }}>
            要在網站上購物或兌換獎賞，閣下必須在網站上註冊成為我們的會員。我們提供的一些商品及
          </span>
          <span style={{ fontSize: 14, fontFamily: '"Nunito Sans"' }}>/</span>
          <span style={{ fontSize: 14, fontFamily: '"PMingLiU",serif' }}>
            或服務必需在閣下已年滿
          </span>
          <span style={{ fontSize: 14, fontFamily: '"Nunito Sans"' }}>18</span>
          <span style={{ fontSize: 14, fontFamily: '"PMingLiU",serif' }}>
            歲或以上，或已獲得所需的許可的情況下方可合法購買或兌換。當閣下註冊成為會員及
          </span>
          <span style={{ fontSize: 14, fontFamily: '"Nunito Sans"' }}>/</span>
          <span style={{ fontSize: 14, fontFamily: '"PMingLiU",serif' }}>
            或購物
          </span>
          <span style={{ fontSize: 14, fontFamily: '"Nunito Sans"' }}>/</span>
          <span style={{ fontSize: 14, fontFamily: '"PMingLiU",serif' }}>
            或兌換獎賞，即表示閣下正作出一個可以信賴的聲明，表示閣下已年滿
          </span>
          <span style={{ fontSize: 14, fontFamily: '"Nunito Sans"' }}>18</span>
          <span style={{ fontSize: 14, fontFamily: '"PMingLiU",serif' }}>
            歲或以上，並有能力形成具法律約束力的合約，並合法完成閣下的購買或兌換。
          </span>
        </li>
        <li
          style={{
            marginTop: "0cm",
            marginRight: "0cm",
            marginBottom: "8.0pt",
            marginLeft: "0cm",
            lineHeight: "normal",
            fontSize: 16,
            fontFamily: '"Aptos",sans-serif',
            color: "#1A1A1A",
            textAlign: "justify"
          }}
        >
          <span style={{ fontSize: 14, fontFamily: '"PMingLiU",serif' }}>
            當閣下在網站上下訂單來購買商或兌換品及
          </span>
          <span style={{ fontSize: 14, fontFamily: '"Nunito Sans"' }}>/</span>
          <span style={{ fontSize: 14, fontFamily: '"PMingLiU",serif' }}>
            或服務（「採購訂單」）時，閣下的採購訂單是向我們或相關供應商（如第
          </span>
          <span style={{ fontSize: 14, fontFamily: '"Nunito Sans"' }}>16</span>
          <span style={{ fontSize: 14, fontFamily: '"PMingLiU",serif' }}>
            條中定義的）（視情況而定）購買或兌換我們或相關供應商（視情況而定）可能接受或可能不接受的商品購買議案。除了這些銷售條款及細則之外，購買或兌換
          </span>
          <span style={{ fontSize: 14, fontFamily: '"Nunito Sans"' }}>
            &nbsp;
          </span>
          <span style={{ fontSize: 14, fontFamily: '"Nunito Sans"' }}>
            Good Club
          </span>
          <span style={{ fontSize: 14, fontFamily: '"PMingLiU",serif' }}>
            上的任何商品也可能受限於該商品的其他條款及細則（「附加條款」）。所有附加條款將在閣下確定購買或兌換之前通知閣下。凡有任何商品銷售的附加條款，通過下訂單購買或兌換此類商品，即表示閣下同意受附加條款約束。如果附加條款與銷售條款及細則的任何部分之間有任何不一致的情況，將以該商品銷售的附加條款為準。
          </span>
        </li>
        <li
          style={{
            marginTop: "0cm",
            marginRight: "0cm",
            marginBottom: "8.0pt",
            marginLeft: "0cm",
            lineHeight: "normal",
            fontSize: 16,
            fontFamily: '"Aptos",sans-serif',
            color: "#1A1A1A",
            textAlign: "justify"
          }}
        >
          <span style={{ fontSize: 14, fontFamily: '"PMingLiU",serif' }}>
            一旦閣下在網站上下了採購
          </span>
          <span style={{ fontSize: 14, fontFamily: '"Nunito Sans"' }}>/</span>
          <span style={{ fontSize: 14, fontFamily: '"PMingLiU",serif' }}>
            兌換訂單，此採購或兌換訂單將被視為不可撤銷和絕對性的。閣下同意我們或供應商根據銷售條款及細則，附加條款（如適用）和任何其他適用的條款及細則來處理採購或兌換訂單，恕不另行通知。
          </span>
        </li>
        <li
          style={{
            marginTop: "0cm",
            marginRight: "0cm",
            marginBottom: "8.0pt",
            marginLeft: "0cm",
            lineHeight: "normal",
            fontSize: 16,
            fontFamily: '"Aptos",sans-serif',
            color: "#1A1A1A",
            textAlign: "justify"
          }}
        >
          <span style={{ fontSize: 14, fontFamily: '"PMingLiU",serif' }}>
            我們或供應商（視情況而定）保留不接受閣下任何採購
          </span>
          <span style={{ fontSize: 14, fontFamily: '"Nunito Sans"' }}>/</span>
          <span style={{ fontSize: 14, fontFamily: '"PMingLiU",serif' }}>
            兌換訂單的絕對酌情決定權。如果閣下已經下了採購
          </span>
          <span style={{ fontSize: 14, fontFamily: '"Nunito Sans"' }}>/</span>
          <span style={{ fontSize: 14, fontFamily: '"PMingLiU",serif' }}>
            兌換訂單並且被我們接納，閣下將透過電子郵件或我們或供應商（視情況而定）確定的任何方式，收到採購
          </span>
          <span style={{ fontSize: 14, fontFamily: '"Nunito Sans"' }}>/</span>
          <span style={{ fontSize: 14, fontFamily: '"PMingLiU",serif' }}>
            兌換訂單確認信。每份採購
          </span>
          <span style={{ fontSize: 14, fontFamily: '"Nunito Sans"' }}>/</span>
          <span style={{ fontSize: 14, fontFamily: '"PMingLiU",serif' }}>
            兌換訂單都應構成具有法律約束力的協議（「客戶合同」）。如果是我們出售貨物，閣下和我們之間將簽訂客戶合同；如果貨物是由供應商出售的，則閣下與供應商之間會簽訂客戶合同。閣下也可以透過電子郵件發票或在閣下的
          </span>
          <span style={{ fontSize: 14, fontFamily: '"Nunito Sans"' }}>
            Good Club
          </span>
          <span style={{ fontSize: 14, fontFamily: '"PMingLiU",serif' }}>
            賬戶（就本條款及細則而言，「服務賬戶」）的訂單記錄中查看閣下已被我們接納的訂單。
          </span>
        </li>
        <li
          style={{
            marginTop: "0cm",
            marginRight: "0cm",
            marginBottom: "8.0pt",
            marginLeft: "0cm",
            lineHeight: "normal",
            fontSize: 16,
            fontFamily: '"Aptos",sans-serif',
            color: "#1A1A1A",
            textAlign: "justify"
          }}
        >
          <span style={{ fontSize: 14, fontFamily: '"PMingLiU",serif' }}>
            即使閣下可能已經收到我們的訂單確認信或已經付款，我們也有權因為任何原因（包括但不限於貨物缺貨或網站出現定價錯誤
          </span>
          <span style={{ fontSize: 14, fontFamily: '"Nunito Sans"' }}>
            &nbsp;
          </span>
          <span style={{ fontSize: 14, fontFamily: '"PMingLiU",serif' }}>
            ）而自行決定拒絕、取消、終止、更改或保留任何訂單或客戶合同。我們會通過我們認為合適的方式通知閣下我們的決定。閣下同意我們沒有義務向閣下作出任何賠償或對因此而可能遭受的任何損失和損害承擔責任。
          </span>
        </li>
        <li
          style={{
            marginTop: "0cm",
            marginRight: "0cm",
            marginBottom: "8.0pt",
            marginLeft: "0cm",
            lineHeight: "normal",
            fontSize: 16,
            fontFamily: '"Aptos",sans-serif',
            color: "#1A1A1A",
            textAlign: "justify"
          }}
        >
          <span style={{ fontSize: 14, fontFamily: '"PMingLiU",serif' }}>
            我們也有權自行決定
          </span>
          <span style={{ fontSize: 14, fontFamily: '"Nunito Sans"' }}>(i)</span>
          <span style={{ fontSize: 14, fontFamily: '"PMingLiU",serif' }}>
            指定每次購物或兌換付款方式；
          </span>
          <span style={{ fontSize: 14, fontFamily: '"Nunito Sans"' }}>
            (ii)
          </span>
          <span style={{ fontSize: 14, fontFamily: '"PMingLiU",serif' }}>
            指定每次購物或兌換的取件方式（無論是送貨，在指定地點收取還是我們指定的其他方式）
          </span>
          <span style={{ fontSize: 14, fontFamily: '"Nunito Sans"' }}>
            ; (iii)
          </span>
          <span style={{ fontSize: 14, fontFamily: '"PMingLiU",serif' }}>
            限制每個訂單、每件產品、每次服務或每個客戶及
          </span>
          <span style={{ fontSize: 14, fontFamily: '"Nunito Sans"' }}>/</span>
          <span style={{ fontSize: 14, fontFamily: '"PMingLiU",serif' }}>
            或每個服務賬戶購買或兌換的商品數量；
          </span>
          <span style={{ fontSize: 14, fontFamily: '"Nunito Sans"' }}>
            (iv)
          </span>
          <span style={{ fontSize: 14, fontFamily: '"PMingLiU",serif' }}>
            在我們指定的時間內限制透過特定付款方式結算的金額；
          </span>
          <span style={{ fontSize: 14, fontFamily: '"Nunito Sans"' }}>
            &nbsp;
          </span>
          <span style={{ fontSize: 14, fontFamily: '"PMingLiU",serif' }}>
            及
          </span>
          <span style={{ fontSize: 14, fontFamily: '"Nunito Sans"' }}>/</span>
          <span style={{ fontSize: 14, fontFamily: '"PMingLiU",serif' }}>
            或
          </span>
          <span style={{ fontSize: 14, fontFamily: '"Nunito Sans"' }}>
            &nbsp;(v)
          </span>
          <span style={{ fontSize: 14, fontFamily: '"PMingLiU",serif' }}>
            隨時修改退款處理方法，恕不另行通知。這些限制可能適用於由同一客戶或同一服務賬戶下的訂單或使用相同帳單及
          </span>
          <span style={{ fontSize: 14, fontFamily: '"Nunito Sans"' }}>/</span>
          <span style={{ fontSize: 14, fontFamily: '"PMingLiU",serif' }}>
            或交付
          </span>
          <span style={{ fontSize: 14, fontFamily: '"Nunito Sans"' }}>/</span>
          <span style={{ fontSize: 14, fontFamily: '"PMingLiU",serif' }}>
            收取地址的訂單。我們會使用我們認為合適的方式通知閣下任何變化。我們進一步保留禁止銷售給經銷商或批發商的權利。
          </span>
        </li>
        <li
          style={{
            marginTop: "0cm",
            marginRight: "0cm",
            marginBottom: "8.0pt",
            marginLeft: "0cm",
            lineHeight: "normal",
            fontSize: 16,
            fontFamily: '"Aptos",sans-serif',
            color: "#1A1A1A",
            textAlign: "justify"
          }}
        >
          <span style={{ fontSize: 14, fontFamily: '"PMingLiU",serif' }}>
            任何已購買或兌換的商品的所有風險都在交貨時轉交給閣下。假如貨品是在智能櫃自行領取，所有風險都在閣下在智能櫃自行領取貨品時轉交給閣下。假如貨品是透過換領電子郵件、換領二維碼
          </span>
          <span style={{ fontSize: 14, fontFamily: '"Nunito Sans"' }}>(</span>
          <span style={{ fontSize: 14, fontFamily: '"PMingLiU",serif' }}>
            虛擬產品
          </span>
          <span style={{ fontSize: 14, fontFamily: '"Nunito Sans"' }}>
            )&nbsp;
          </span>
          <span style={{ fontSize: 14, fontFamily: '"PMingLiU",serif' }}>
            或換領信件的方式發送，所有風險都在
          </span>
          <span style={{ fontSize: 14, fontFamily: '"Nunito Sans"' }}>
            &nbsp;
          </span>
          <span style={{ fontSize: 14, fontFamily: '"Nunito Sans"' }}>
            Good Club
          </span>
          <span style={{ fontSize: 14, fontFamily: '"PMingLiU",serif' }}>
            發出相關換領電子郵件、
          </span>
          <span style={{ fontSize: 14, fontFamily: '"Nunito Sans"' }}>
            &nbsp;
          </span>
          <span style={{ fontSize: 14, fontFamily: '"PMingLiU",serif' }}>
            換領二維碼或換領信件時轉交給閣下。
          </span>
        </li>
        <li
          style={{
            marginTop: "0cm",
            marginRight: "0cm",
            marginBottom: "8.0pt",
            marginLeft: "0cm",
            lineHeight: "normal",
            fontSize: 16,
            fontFamily: '"Aptos",sans-serif',
            color: "#1A1A1A",
            textAlign: "justify"
          }}
        >
          <span style={{ fontSize: 14, fontFamily: '"PMingLiU",serif' }}>
            如果閣下是
          </span>
          <span style={{ fontSize: 14, fontFamily: '"Nunito Sans"' }}>
            &nbsp;
          </span>
          <span style={{ fontSize: 14, fontFamily: '"Nunito Sans"' }}>
            Good
          </span>
          <span style={{ fontSize: 14, fontFamily: '"Nunito Sans"' }}>
            &nbsp;Club
          </span>
          <span style={{ fontSize: 14, fontFamily: '"PMingLiU",serif' }}>
            的會員，並且將
          </span>
          <span style={{ fontSize: 14, fontFamily: '"Nunito Sans"' }}>
            &nbsp;
          </span>
          <span style={{ fontSize: 14, fontFamily: '"Nunito Sans"' }}>
            Good&nbsp;
          </span>
          <span style={{ fontSize: 14, fontFamily: '"Nunito Sans"' }}>
            Club&nbsp;
          </span>
          <span style={{ fontSize: 14, fontFamily: '"PMingLiU",serif' }}>
            的賬戶與本網站鏈接，即代表閣下在本網站購物將會獲得
          </span>
          <span style={{ fontSize: 14, fontFamily: '"Nunito Sans"' }}>
            &nbsp;
          </span>
          <span style={{ fontSize: 14, fontFamily: '"Nunito Sans"' }}>
            Good&nbsp;
          </span>
          <span style={{ fontSize: 14, fontFamily: '"Nunito Sans"' }}>
            Club&nbsp;
          </span>
          <span style={{ fontSize: 14, fontFamily: '"PMingLiU",serif' }}>
            積分
          </span>
          <span style={{ fontSize: 14, fontFamily: '"Nunito Sans"' }}>
            &nbsp;
          </span>
          <span style={{ fontSize: 14, fontFamily: '"PMingLiU",serif' }}>
            （不包括與購買或申請保險產品或服務有關的購買、服務訂閱、交易或付款）。除非另有特別註明，
          </span>
          <span style={{ fontSize: 14, fontFamily: '"Nunito Sans"' }}>
            Good&nbsp;
          </span>
          <span style={{ fontSize: 14, fontFamily: '"Nunito Sans"' }}>
            Club&nbsp;
          </span>
          <span style={{ fontSize: 14, fontFamily: '"PMingLiU",serif' }}>
            積分是根據訂單的消費淨額計算（消費淨額並不包外括以
          </span>
          <span style={{ fontSize: 14, fontFamily: '"Nunito Sans"' }}>
            &nbsp;
          </span>
          <span style={{ fontSize: 14, fontFamily: '"Nunito Sans"' }}>
            Good&nbsp;
          </span>
          <span style={{ fontSize: 14, fontFamily: '"Nunito Sans"' }}>
            Club&nbsp;
          </span>
          <span style={{ fontSize: 14, fontFamily: '"PMingLiU",serif' }}>
            積分兌換獎賞、使用優惠券、禮券、折扣、其他折扣金額及運費），如有小數點，將上捨至最接近的整數。
          </span>
          <span style={{ fontSize: 14, fontFamily: '"Nunito Sans"' }}>
            Good&nbsp;
          </span>
          <span style={{ fontSize: 14, fontFamily: '"Nunito Sans"' }}>
            Club&nbsp;
          </span>
          <span style={{ fontSize: 14, fontFamily: '"PMingLiU",serif' }}>
            將在訂單中的所有商品交付給閣下後大約
          </span>
          <span style={{ fontSize: 14, fontFamily: '"Nunito Sans"' }}>10</span>
          <span style={{ fontSize: 14, fontFamily: '"PMingLiU",serif' }}>
            個工作天後將
          </span>
          <span style={{ fontSize: 14, fontFamily: '"Nunito Sans"' }}>
            &nbsp;
          </span>
          <span style={{ fontSize: 14, fontFamily: '"Nunito Sans"' }}>
            Good&nbsp;
          </span>
          <span style={{ fontSize: 14, fontFamily: '"Nunito Sans"' }}>
            Club&nbsp;
          </span>
          <span style={{ fontSize: 14, fontFamily: '"PMingLiU",serif' }}>
            積分存入閣下的
          </span>
          <span style={{ fontSize: 14, fontFamily: '"Nunito Sans"' }}>
            &nbsp;
          </span>
          <span style={{ fontSize: 14, fontFamily: '"Nunito Sans"' }}>
            Good&nbsp;
          </span>
          <span style={{ fontSize: 14, fontFamily: '"Nunito Sans"' }}>
            Club&nbsp;
          </span>
          <span style={{ fontSize: 14, fontFamily: '"PMingLiU",serif' }}>
            賬戶。退款的商品將不會獲得
          </span>
          <span style={{ fontSize: 14, fontFamily: '"Nunito Sans"' }}>
            &nbsp;
          </span>
          <span style={{ fontSize: 14, fontFamily: '"Nunito Sans"' }}>
            Good&nbsp;
          </span>
          <span style={{ fontSize: 14, fontFamily: '"Nunito Sans"' }}>
            Club&nbsp;
          </span>
          <span style={{ fontSize: 14, fontFamily: '"PMingLiU",serif' }}>
            積分。當我們收到已購買商品的全額付款後，所有已購買或兌換商品的所有權將在上述風險轉交給閣下時同時轉交給閣下。
          </span>
        </li>
      </ol>
    </ol>
  </ol>
  <p
    style={{
      marginTop: "15.0pt",
      marginRight: "0cm",
      marginBottom: "15.0pt",
      marginLeft: "0cm",
      lineHeight: "normal",
      fontSize: 16,
      fontFamily: '"Aptos",sans-serif',
      textAlign: "justify"
    }}
  >
    <strong>
      <span
        style={{ fontSize: 14, fontFamily: '"Nunito Sans"', color: "#1A1A1A" }}
      >
        2.&nbsp;
      </span>
    </strong>
    <strong>
      <span
        style={{
          fontSize: 14,
          fontFamily: '"PMingLiU",serif',
          color: "#1A1A1A"
        }}
      >
        關於
      </span>
    </strong>
    <strong>
      <span
        style={{ fontSize: 14, fontFamily: '"Nunito Sans"', color: "#1A1A1A" }}
      >
        &nbsp;
      </span>
    </strong>
    <span
      style={{ fontSize: 14, fontFamily: '"Nunito Sans"', color: "#1A1A1A" }}
    >
      Good&nbsp;
    </span>
    <span
      style={{ fontSize: 14, fontFamily: '"Nunito Sans"', color: "#1A1A1A" }}
    >
      Club<strong>&nbsp;</strong>
    </span>
    <strong>
      <span
        style={{
          fontSize: 14,
          fontFamily: '"PMingLiU",serif',
          color: "#1A1A1A"
        }}
      >
        積分
      </span>
    </strong>
  </p>
  <ol style={{ marginBottom: "0cm", marginTop: "0cm" }} start={1} type="a">
    <ol style={{ marginBottom: "0cm", marginTop: "0cm" }} start={1} type="a">
      <ol style={{ marginBottom: "0cm", marginTop: "0cm" }} start={1} type="a">
        <li
          style={{
            marginTop: "0cm",
            marginRight: "0cm",
            marginBottom: "8.0pt",
            marginLeft: "0cm",
            lineHeight: "normal",
            fontSize: 16,
            fontFamily: '"Aptos",sans-serif',
            color: "#1A1A1A",
            textAlign: "justify"
          }}
        >
          <span style={{ fontSize: 14, fontFamily: '"Nunito Sans"' }}>
            Good&nbsp;
          </span>
          <span style={{ fontSize: 14, fontFamily: '"Nunito Sans"' }}>
            Club&nbsp;
          </span>
          <span style={{ fontSize: 14, fontFamily: '"PMingLiU",serif' }}>
            會員必須於交易前把
          </span>
          <span style={{ fontSize: 14, fontFamily: '"Nunito Sans"' }}>
            &nbsp;
          </span>
          <span style={{ fontSize: 14, fontFamily: '"Nunito Sans"' }}>
            Good&nbsp;
          </span>
          <span style={{ fontSize: 14, fontFamily: '"Nunito Sans"' }}>
            Club&nbsp;
          </span>
          <span style={{ fontSize: 14, fontFamily: '"PMingLiU",serif' }}>
            賬戶連結至已成功啟動的
          </span>
          <span style={{ fontSize: 14, fontFamily: '"Nunito Sans"' }}>
            &nbsp;
          </span>
          <span style={{ fontSize: 14, fontFamily: '"Nunito Sans"' }}>
            Good&nbsp;
          </span>
          <span style={{ fontSize: 14, fontFamily: '"Nunito Sans"' }}>
            Club
          </span>
          <span style={{ fontSize: 14, fontFamily: '"PMingLiU",serif' }}>
            會員賬戶，才可使用及賺取
          </span>
          <span style={{ fontSize: 14, fontFamily: '"Nunito Sans"' }}>
            &nbsp;
          </span>
          <span style={{ fontSize: 14, fontFamily: '"Nunito Sans"' }}>
            Good&nbsp;
          </span>
          <span style={{ fontSize: 14, fontFamily: '"Nunito Sans"' }}>
            Club&nbsp;
          </span>
          <span style={{ fontSize: 14, fontFamily: '"PMingLiU",serif' }}>
            積分。若未成功連結
          </span>
          <span style={{ fontSize: 14, fontFamily: '"Nunito Sans"' }}>
            &nbsp;
          </span>
          <span style={{ fontSize: 14, fontFamily: '"Nunito Sans"' }}>
            Good&nbsp;
          </span>
          <span style={{ fontSize: 14, fontFamily: '"Nunito Sans"' }}>
            Club&nbsp;
          </span>
          <span style={{ fontSize: 14, fontFamily: '"PMingLiU",serif' }}>
            會員賬戶，恕不作事後補發安排。
          </span>
        </li>
        <li
          style={{
            marginTop: "0cm",
            marginRight: "0cm",
            marginBottom: "8.0pt",
            marginLeft: "0cm",
            lineHeight: "normal",
            fontSize: 16,
            fontFamily: '"Aptos",sans-serif',
            color: "#1A1A1A",
            textAlign: "justify"
          }}
        >
          <span style={{ fontSize: 14, fontFamily: '"PMingLiU",serif' }}>
            除非另有特別註明，有關使用及賺取
          </span>
          <span style={{ fontSize: 14, fontFamily: '"Nunito Sans"' }}>
            Good&nbsp;
          </span>
          <span style={{ fontSize: 14, fontFamily: '"Nunito Sans"' }}>
            Club&nbsp;
          </span>
          <span style={{ fontSize: 14, fontFamily: '"PMingLiU",serif' }}>
            積分的安排只適用於透過
          </span>
          <span style={{ fontSize: 14, fontFamily: '"Nunito Sans"' }}>
            &nbsp;
          </span>
          <span style={{ fontSize: 14, fontFamily: '"Nunito Sans"' }}>
            Good&nbsp;
          </span>
          <span style={{ fontSize: 14, fontFamily: '"Nunito Sans"' }}>
            Club
          </span>
          <span style={{ fontSize: 14, fontFamily: '"Nunito Sans"' }}>
            , Good Mask, Good Meal, Good Easy, Good Insurance
          </span>
          <span style={{ fontSize: 14, fontFamily: '"PMingLiU",serif' }}>
            及
          </span>
          <span style={{ fontSize: 14, fontFamily: '"Nunito Sans"' }}>
            &nbsp;Good Property
          </span>
          <span style={{ fontSize: 14, fontFamily: '"Nunito Sans"' }}>
            &nbsp;
          </span>
          <span style={{ fontSize: 14, fontFamily: '"PMingLiU",serif' }}>
            完成交易的訂單，不適用於
          </span>
          <span style={{ fontSize: 14, fontFamily: '"Nunito Sans"' }}>
            &nbsp;
          </span>
          <span style={{ fontSize: 14, fontFamily: '"Nunito Sans"' }}>
            Good Trip
          </span>
          <span style={{ fontSize: 14, fontFamily: '"Nunito Sans"' }}>
            &nbsp;
          </span>
          <span style={{ fontSize: 14, fontFamily: '"PMingLiU",serif' }}>
            的交易。
          </span>
        </li>
        <li
          style={{
            marginTop: "0cm",
            marginRight: "0cm",
            marginBottom: "8.0pt",
            marginLeft: "0cm",
            lineHeight: "normal",
            fontSize: 16,
            fontFamily: '"Aptos",sans-serif',
            color: "#1A1A1A",
            textAlign: "justify"
          }}
        >
          <span style={{ fontSize: 14, fontFamily: '"PMingLiU",serif' }}>
            閣下所賺取的
          </span>
          <span style={{ fontSize: 14, fontFamily: '"Nunito Sans"' }}>
            &nbsp;
          </span>
          <span style={{ fontSize: 14, fontFamily: '"Nunito Sans"' }}>
            Good&nbsp;
          </span>
          <span style={{ fontSize: 14, fontFamily: '"Nunito Sans"' }}>
            Club
          </span>
          <span style={{ fontSize: 14, fontFamily: '"PMingLiU",serif' }}>
            積分將於貨品送抵後
          </span>
          <span style={{ fontSize: 14, fontFamily: '"Nunito Sans"' }}>
            &nbsp;10&nbsp;
          </span>
          <span style={{ fontSize: 14, fontFamily: '"PMingLiU",serif' }}>
            日後自動存入
          </span>
          <span style={{ fontSize: 14, fontFamily: '"Nunito Sans"' }}>
            &nbsp;
          </span>
          <span style={{ fontSize: 14, fontFamily: '"Nunito Sans"' }}>
            Good&nbsp;
          </span>
          <span style={{ fontSize: 14, fontFamily: '"Nunito Sans"' }}>
            Club Club&nbsp;
          </span>
          <span style={{ fontSize: 14, fontFamily: '"PMingLiU",serif' }}>
            賬戶內。
          </span>
        </li>
        <li
          style={{
            marginTop: "0cm",
            marginRight: "0cm",
            marginBottom: "8.0pt",
            marginLeft: "0cm",
            lineHeight: "normal",
            fontSize: 16,
            fontFamily: '"Aptos",sans-serif',
            color: "#1A1A1A",
            textAlign: "justify"
          }}
        >
          <span style={{ fontSize: 14, fontFamily: '"PMingLiU",serif' }}>
            如有任何爭議，
          </span>
          <span style={{ fontSize: 14, fontFamily: '"Nunito Sans"' }}>
            Good&nbsp;
          </span>
          <span style={{ fontSize: 14, fontFamily: '"Nunito Sans"' }}>
            Club
          </span>
          <span style={{ fontSize: 14, fontFamily: '"PMingLiU",serif' }}>
            保留一切最終決定權。
          </span>
        </li>
        <li
          style={{
            marginTop: "0cm",
            marginRight: "0cm",
            marginBottom: "8.0pt",
            marginLeft: "0cm",
            lineHeight: "normal",
            fontSize: 16,
            fontFamily: '"Aptos",sans-serif',
            color: "#1A1A1A",
            textAlign: "justify"
          }}
        >
          <span style={{ fontSize: 14, fontFamily: '"Nunito Sans"' }}>
            Good&nbsp;
          </span>
          <span style={{ fontSize: 14, fontFamily: '"Nunito Sans"' }}>
            Club&nbsp;
          </span>
          <span style={{ fontSize: 14, fontFamily: '"PMingLiU",serif' }}>
            有權隨時更改此條款及細則，而無須對會員作事先通知，
          </span>
          <span style={{ fontSize: 14, fontFamily: '"Nunito Sans"' }}>
            Good&nbsp;
          </span>
          <span style={{ fontSize: 14, fontFamily: '"Nunito Sans"' }}>
            Club
          </span>
          <span style={{ fontSize: 14, fontFamily: '"PMingLiU",serif' }}>
            並不會就有關更改負上任何責任。
          </span>
        </li>
      </ol>
    </ol>
  </ol>
  <p
    style={{
      marginTop: "15.0pt",
      marginRight: "0cm",
      marginBottom: "15.0pt",
      marginLeft: "0cm",
      lineHeight: "normal",
      fontSize: 16,
      fontFamily: '"Aptos",sans-serif',
      textAlign: "justify"
    }}
  >
    <strong>
      <span
        style={{ fontSize: 14, fontFamily: '"Nunito Sans"', color: "#1A1A1A" }}
      >
        3.&nbsp;
      </span>
    </strong>
    <strong>
      <span
        style={{
          fontSize: 14,
          fontFamily: '"PMingLiU",serif',
          color: "#1A1A1A"
        }}
      >
        存貨
      </span>
    </strong>
  </p>
  <ol style={{ marginBottom: "0cm", marginTop: "0cm" }} start={1} type="a">
    <ol style={{ marginBottom: "0cm", marginTop: "0cm" }} start={1} type="a">
      <ol style={{ marginBottom: "0cm", marginTop: "0cm" }} start={1} type="a">
        <li
          style={{
            marginTop: "0cm",
            marginRight: "0cm",
            marginBottom: "8.0pt",
            marginLeft: "0cm",
            lineHeight: "normal",
            fontSize: 16,
            fontFamily: '"Aptos",sans-serif',
            color: "#1A1A1A",
            textAlign: "justify"
          }}
        >
          <span style={{ fontSize: 14, fontFamily: '"PMingLiU",serif' }}>
            在
          </span>
          <span style={{ fontSize: 14, fontFamily: '"Nunito Sans"' }}>
            &nbsp;
          </span>
          <span style={{ fontSize: 14, fontFamily: '"Nunito Sans"' }}>
            Good&nbsp;
          </span>
          <span style={{ fontSize: 14, fontFamily: '"Nunito Sans"' }}>
            Club
          </span>
          <span style={{ fontSize: 14, fontFamily: '"PMingLiU",serif' }}>
            上售賣或兌換的商品可能由我們或供應商（視情況而定）提供。我們及其關聯公司不會明示或暗示地贊助，認可或推薦任何供應商透過
          </span>
          <span style={{ fontSize: 14, fontFamily: '"Nunito Sans"' }}>
            &nbsp;
          </span>
          <span style={{ fontSize: 14, fontFamily: '"Nunito Sans"' }}>
            Good&nbsp;
          </span>
          <span style={{ fontSize: 14, fontFamily: '"Nunito Sans"' }}>
            Club&nbsp;
          </span>
          <span style={{ fontSize: 14, fontFamily: '"PMingLiU",serif' }}>
            提供的任何第三方產品、服務、信息或內容。
          </span>
        </li>
        <li
          style={{
            marginTop: "0cm",
            marginRight: "0cm",
            marginBottom: "8.0pt",
            marginLeft: "0cm",
            lineHeight: "normal",
            fontSize: 16,
            fontFamily: '"Aptos",sans-serif',
            color: "#1A1A1A",
            textAlign: "justify"
          }}
        >
          <span style={{ fontSize: 14, fontFamily: '"PMingLiU",serif' }}>
            如網站所示，可在
          </span>
          <span style={{ fontSize: 14, fontFamily: '"Nunito Sans"' }}>
            &nbsp;
          </span>
          <span style={{ fontSize: 14, fontFamily: '"Nunito Sans"' }}>
            Good&nbsp;
          </span>
          <span style={{ fontSize: 14, fontFamily: '"Nunito Sans"' }}>
            Club&nbsp;
          </span>
          <span style={{ fontSize: 14, fontFamily: '"PMingLiU",serif' }}>
            上購買或兌換的商品可能受到我們或供應商（視情況而定）所施加的供應限制和條款所規範。供應限制和條款可能包括貨物交付到的國家的任何法律、條例、規則或限制；定價和付款條款；訂單和交付細節；退貨，退款和取消政策；產品和服務保證；及其他合約條款，而且可能不時更改，而不另行通知。閣下應仔細審查並確保嚴格遵守這些供應限制和條款。
          </span>
        </li>
        <li
          style={{
            marginTop: "0cm",
            marginRight: "0cm",
            marginBottom: "8.0pt",
            marginLeft: "0cm",
            lineHeight: "normal",
            fontSize: 16,
            fontFamily: '"Aptos",sans-serif',
            color: "#1A1A1A",
            textAlign: "justify"
          }}
        >
          <span style={{ fontSize: 14, fontFamily: '"PMingLiU",serif' }}>
            在
          </span>
          <span style={{ fontSize: 14, fontFamily: '"Nunito Sans"' }}>
            &nbsp;
          </span>
          <span style={{ fontSize: 14, fontFamily: '"Nunito Sans"' }}>
            Good&nbsp;
          </span>
          <span style={{ fontSize: 14, fontFamily: '"Nunito Sans"' }}>
            Club&nbsp;
          </span>
          <span style={{ fontSize: 14, fontFamily: '"PMingLiU",serif' }}>
            上提供的商品規格，包括尺碼、尺寸、重量、數量、顏色、容量和其他產品特性或描述僅供參考和方便之用，可能會與貨物的實際規格有些微差距。
          </span>
        </li>
        <li
          style={{
            marginTop: "0cm",
            marginRight: "0cm",
            marginBottom: "8.0pt",
            marginLeft: "0cm",
            lineHeight: "normal",
            fontSize: 16,
            fontFamily: '"Aptos",sans-serif',
            color: "#1A1A1A",
            textAlign: "justify"
          }}
        >
          <span style={{ fontSize: 14, fontFamily: '"Nunito Sans"' }}>
            Good&nbsp;
          </span>
          <span style={{ fontSize: 14, fontFamily: '"Nunito Sans"' }}>
            Club
          </span>
          <span style={{ fontSize: 14, fontFamily: '"PMingLiU",serif' }}>
            的所有商品供應存貨只能由我們或供應商（視情況而定）自行決定，此類商品可能隨時取消或更改而不作另行通知。
          </span>
        </li>
      </ol>
    </ol>
  </ol>
  <p
    style={{
      marginTop: "15.0pt",
      marginRight: "0cm",
      marginBottom: "15.0pt",
      marginLeft: "0cm",
      lineHeight: "normal",
      fontSize: 16,
      fontFamily: '"Aptos",sans-serif',
      textAlign: "justify"
    }}
  >
    <strong>
      <span
        style={{ fontSize: 14, fontFamily: '"Nunito Sans"', color: "#1A1A1A" }}
      >
        4.&nbsp;
      </span>
    </strong>
    <strong>
      <span
        style={{
          fontSize: 14,
          fontFamily: '"PMingLiU",serif',
          color: "#1A1A1A"
        }}
      >
        保證
      </span>
    </strong>
  </p>
  <ol style={{ marginBottom: "0cm", marginTop: "0cm" }} start={1} type="a">
    <ol style={{ marginBottom: "0cm", marginTop: "0cm" }} start={1} type="a">
      <ol style={{ marginBottom: "0cm", marginTop: "0cm" }} start={1} type="a">
        <li
          style={{
            marginTop: "0cm",
            marginRight: "0cm",
            marginBottom: "8.0pt",
            marginLeft: "0cm",
            lineHeight: "normal",
            fontSize: 16,
            fontFamily: '"Aptos",sans-serif',
            color: "#1A1A1A",
            textAlign: "justify"
          }}
        >
          <span style={{ fontSize: 14, fontFamily: '"PMingLiU",serif' }}>
            如果貨物總計費用的任何部分在付款截止日期之前仍未被全數支付，我們或供應商將不承擔任何責任。
          </span>
        </li>
        <li
          style={{
            marginTop: "0cm",
            marginRight: "0cm",
            marginBottom: "8.0pt",
            marginLeft: "0cm",
            lineHeight: "normal",
            fontSize: 16,
            fontFamily: '"Aptos",sans-serif',
            color: "#1A1A1A",
            textAlign: "justify"
          }}
        >
          <span style={{ fontSize: 14, fontFamily: '"PMingLiU",serif' }}>
            貨物的保養（如有）將由供應商提供（「供應商保養」），並應遵守供應商保養的條款及細則。除非由於我們或我們的授權代表的重大過失或故意違約而導致損失或損害，否則我們和我們的附屬機構不會直接或間接地以任何方式承擔閣下、任何貨物使用者或任何其他人士因使用貨物（或任何其他部分）而可能招致或遭受的任何損失或損害。
          </span>
        </li>
        <li
          style={{
            marginTop: "0cm",
            marginRight: "0cm",
            marginBottom: "8.0pt",
            marginLeft: "0cm",
            lineHeight: "normal",
            fontSize: 16,
            fontFamily: '"Aptos",sans-serif',
            color: "#1A1A1A",
            textAlign: "justify"
          }}
        >
          <span style={{ fontSize: 14, fontFamily: '"PMingLiU",serif' }}>
            我們和我們的附屬機構也不承擔任何與供應商保養有關的責任或風險。如果出現故障、製作上的缺陷以及貨物的材料或設計錯誤，閣下可以在相關供應商的保養期內自費將貨物直接退回供應商進行維修或更換（由供應商決定）（受供應商保養條款及細則的約束）。我們和我們的附屬機構對任何與這些貨物的供應商保養有關的任何事情概不負責。
          </span>
        </li>
        <li
          style={{
            marginTop: "0cm",
            marginRight: "0cm",
            marginBottom: "8.0pt",
            marginLeft: "0cm",
            lineHeight: "normal",
            fontSize: 16,
            fontFamily: '"Aptos",sans-serif',
            color: "#1A1A1A",
            textAlign: "justify"
          }}
        >
          <span style={{ fontSize: 14, fontFamily: '"PMingLiU",serif' }}>
            我們或供應商不會因任何目的描述或保證貨物的狀態、質素、狀況或適用性，亦不保證貨物符合任何規格、要求或習慣做法。
          </span>
        </li>
        <li
          style={{
            marginTop: "0cm",
            marginRight: "0cm",
            marginBottom: "8.0pt",
            marginLeft: "0cm",
            lineHeight: "normal",
            fontSize: 16,
            fontFamily: '"Aptos",sans-serif',
            color: "#1A1A1A",
            textAlign: "justify"
          }}
        >
          <span style={{ fontSize: 14, fontFamily: '"PMingLiU",serif' }}>
            對於閣下或第三方的任何行為或疏忽及其後果，我們或供應商將不承擔任何責任，我們或供應商也不對貨品到期後產生的任何缺陷或損壞承擔責任。
          </span>
        </li>
        <li
          style={{
            marginTop: "0cm",
            marginRight: "0cm",
            marginBottom: "8.0pt",
            marginLeft: "0cm",
            lineHeight: "normal",
            fontSize: 16,
            fontFamily: '"Aptos",sans-serif',
            color: "#1A1A1A",
            textAlign: "justify"
          }}
        >
          <span style={{ fontSize: 14, fontFamily: '"PMingLiU",serif' }}>
            我們或供應商不會對貨物的任何狀態或狀況或由閣下或任何第三方未經我們的同意而造成任何由於磨損，故意處理不當，疏忽，不恰當使用，不當改動或對貨物的任何修理或改變的任何缺陷承擔責任。
          </span>
        </li>
        <li
          style={{
            marginTop: "0cm",
            marginRight: "0cm",
            marginBottom: "8.0pt",
            marginLeft: "0cm",
            lineHeight: "normal",
            fontSize: 16,
            fontFamily: '"Aptos",sans-serif',
            color: "#1A1A1A",
            textAlign: "justify"
          }}
        >
          <span style={{ fontSize: 14, fontFamily: '"PMingLiU",serif' }}>
            未經我們事先書面許可，我們或供應商不承擔由於閣下或任何第三方因貨物的任何更改或操作而遭受的任何直接或間接損失或損害的責任。閣下同意閣下應賠償我們及
          </span>
          <span style={{ fontSize: 14, fontFamily: '"Nunito Sans"' }}>/</span>
          <span style={{ fontSize: 14, fontFamily: '"PMingLiU",serif' }}>
            或供應商因此類索賠而引起的任何損失或損害。
          </span>
        </li>
      </ol>
    </ol>
  </ol>
  <p
    style={{
      marginTop: "15.0pt",
      marginRight: "0cm",
      marginBottom: "15.0pt",
      marginLeft: "0cm",
      lineHeight: "normal",
      fontSize: 16,
      fontFamily: '"Aptos",sans-serif',
      textAlign: "justify"
    }}
  >
    <strong>
      <span
        style={{ fontSize: 14, fontFamily: '"Nunito Sans"', color: "#1A1A1A" }}
      >
        5.&nbsp;
      </span>
    </strong>
    <strong>
      <span
        style={{
          fontSize: 14,
          fontFamily: '"PMingLiU",serif',
          color: "#1A1A1A"
        }}
      >
        退貨
      </span>
    </strong>
  </p>
  <ol style={{ marginBottom: "0cm", marginTop: "0cm" }} start={1} type="a">
    <ol style={{ marginBottom: "0cm", marginTop: "0cm" }} start={1} type="a">
      <ol style={{ marginBottom: "0cm", marginTop: "0cm" }} start={1} type="a">
        <li
          style={{
            marginTop: "0cm",
            marginRight: "0cm",
            marginBottom: "8.0pt",
            marginLeft: "0cm",
            lineHeight: "normal",
            fontSize: 16,
            fontFamily: '"Aptos",sans-serif',
            color: "#1A1A1A",
            textAlign: "justify"
          }}
        >
          <span style={{ fontSize: 14, fontFamily: '"PMingLiU",serif' }}>
            閣下必須在收到商品即時檢查及確認商品狀況，否則視為閣下已接受商品，狀況良好且質量令人滿意。
          </span>
        </li>
        <li
          style={{
            marginTop: "0cm",
            marginRight: "0cm",
            marginBottom: "8.0pt",
            marginLeft: "0cm",
            lineHeight: "normal",
            fontSize: 16,
            fontFamily: '"Aptos",sans-serif',
            color: "#1A1A1A",
            textAlign: "justify"
          }}
        >
          <span style={{ fontSize: 14, fontFamily: '"PMingLiU",serif' }}>
            閣下同意承擔退貨決定的全部責任，並且我們不對因任何退貨請求而引起的任何損失和損害承擔或負責任。
          </span>
        </li>
      </ol>
    </ol>
  </ol>
  <p
    style={{
      marginTop: "0cm",
      marginRight: "0cm",
      marginBottom: "8.0pt",
      marginLeft: "108.0pt",
      lineHeight: "normal",
      fontSize: 16,
      fontFamily: '"Aptos",sans-serif',
      textAlign: "justify"
    }}
  >
    <span
      style={{ fontSize: 14, fontFamily: '"Nunito Sans"', color: "#1A1A1A" }}
    >
      &nbsp;
    </span>
  </p>
  <p
    style={{
      marginTop: "15.0pt",
      marginRight: "0cm",
      marginBottom: "15.0pt",
      marginLeft: "0cm",
      lineHeight: "normal",
      fontSize: 16,
      fontFamily: '"Aptos",sans-serif',
      textAlign: "justify"
    }}
  >
    <strong>
      <span
        style={{ fontSize: 14, fontFamily: '"Nunito Sans"', color: "#1A1A1A" }}
      >
        6.&nbsp;
      </span>
    </strong>
    <strong>
      <span
        style={{
          fontSize: 14,
          fontFamily: '"PMingLiU",serif',
          color: "#1A1A1A"
        }}
      >
        送貨
      </span>
    </strong>
  </p>
  <ol style={{ marginBottom: "0cm", marginTop: "0cm" }} start={1} type="a">
    <ol style={{ marginBottom: "0cm", marginTop: "0cm" }} start={1} type="a">
      <ol style={{ marginBottom: "0cm", marginTop: "0cm" }} start={1} type="a">
        <li
          style={{
            marginTop: "0cm",
            marginRight: "0cm",
            marginBottom: "8.0pt",
            marginLeft: "0cm",
            lineHeight: "normal",
            fontSize: 16,
            fontFamily: '"Aptos",sans-serif',
            color: "#1A1A1A",
            textAlign: "justify"
          }}
        >
          <span style={{ fontSize: 14, fontFamily: '"PMingLiU",serif' }}>
            所有送貨應按照並遵守我們的送貨政策。請參閱我們網站上的「送貨方式」
          </span>
          <span style={{ fontSize: 14, fontFamily: '"Nunito Sans"' }}>
            &nbsp;
          </span>
          <span style={{ fontSize: 14, fontFamily: '"PMingLiU",serif' }}>
            了解更多關於收費，送貨區域和送貨時間的詳細資料。
          </span>
        </li>
        <li
          style={{
            marginTop: "0cm",
            marginRight: "0cm",
            marginBottom: "8.0pt",
            marginLeft: "0cm",
            lineHeight: "normal",
            fontSize: 16,
            fontFamily: '"Aptos",sans-serif',
            color: "#1A1A1A",
            textAlign: "justify"
          }}
        >
          <span style={{ fontSize: 14, fontFamily: '"PMingLiU",serif' }}>
            儘管有上述第
          </span>
          <span style={{ fontSize: 14, fontFamily: '"Nunito Sans"' }}>
            &nbsp;6(a)&nbsp;
          </span>
          <span style={{ fontSize: 14, fontFamily: '"PMingLiU",serif' }}>
            條的規定，我們可以指定每次訂單的取貨方式，無論是透過送貨到提供的送貨地址，在我們指定的地點收取或我們指定的其他方式。將貨物送到的送貨地址或收取貨物或其他代表閣下的人在我們指定的地點或者透過我們指定的其他方式收取貨物，都被視為適當的運送方式，閣下不應對我們提出索賠，我們對因此類索賠造成的任何損失或損害不承擔責任。
          </span>
        </li>
        <li
          style={{
            marginTop: "0cm",
            marginRight: "0cm",
            marginBottom: "8.0pt",
            marginLeft: "0cm",
            lineHeight: "normal",
            fontSize: 16,
            fontFamily: '"Aptos",sans-serif',
            color: "#1A1A1A",
            textAlign: "justify"
          }}
        >
          <span style={{ fontSize: 14, fontFamily: '"PMingLiU",serif' }}>
            儘管我們會盡力確保在
          </span>
          <span style={{ fontSize: 14, fontFamily: '"Nunito Sans"' }}>
            &nbsp;
          </span>
          <span style={{ fontSize: 14, fontFamily: '"Nunito Sans"' }}>
            Good Club
          </span>
          <span style={{ fontSize: 14, fontFamily: '"PMingLiU",serif' }}>
            訂購的商品準時交付，但我們都不能保證該貨物將按照閣下確認的交付日期和收貨方式交付給閣下；
          </span>
          <span style={{ fontSize: 14, fontFamily: '"Nunito Sans"' }}>
            &nbsp;
          </span>
          <span style={{ fontSize: 14, fontFamily: '"PMingLiU",serif' }}>
            並且在法律允許的範圍內，由於貨物延遲或不能交付或貨物在運輸過程中發生任何損壞而導致閣下蒙受的任何損失，我們概不負責。
          </span>
        </li>
        <li
          style={{
            marginTop: "0cm",
            marginRight: "0cm",
            marginBottom: "8.0pt",
            marginLeft: "0cm",
            lineHeight: "normal",
            fontSize: 16,
            fontFamily: '"Aptos",sans-serif',
            color: "#1A1A1A",
            textAlign: "justify"
          }}
        >
          <span style={{ fontSize: 14, fontFamily: '"PMingLiU",serif' }}>
            獎賞送貨安排視乎每個商戶不同的送貨安排，請在產品說明頁上查看相關的派送詳情。
          </span>
        </li>
      </ol>
    </ol>
  </ol>
  <p
    style={{
      marginTop: "15.0pt",
      marginRight: "0cm",
      marginBottom: "15.0pt",
      marginLeft: "0cm",
      lineHeight: "normal",
      fontSize: 16,
      fontFamily: '"Aptos",sans-serif',
      textAlign: "justify"
    }}
  >
    <strong>
      <span
        style={{ fontSize: 14, fontFamily: '"Nunito Sans"', color: "#1A1A1A" }}
      >
        7.&nbsp;
      </span>
    </strong>
    <strong>
      <span
        style={{
          fontSize: 14,
          fontFamily: '"PMingLiU",serif',
          color: "#1A1A1A"
        }}
      >
        健康，安全和產品警告
      </span>
    </strong>
  </p>
  <p
    style={{
      marginTop: "0cm",
      marginRight: "0cm",
      marginBottom: "22.5pt",
      marginLeft: "0cm",
      lineHeight: "normal",
      fontSize: 16,
      fontFamily: '"Aptos",sans-serif',
      textAlign: "justify"
    }}
  >
    <span
      style={{ fontSize: 14, fontFamily: '"Nunito Sans"', color: "#1A1A1A" }}
    >
      Good Club
    </span>
    <span
      style={{ fontSize: 14, fontFamily: '"Nunito Sans"', color: "#1A1A1A" }}
    >
      &nbsp;
    </span>
    <span
      style={{ fontSize: 14, fontFamily: '"PMingLiU",serif', color: "#1A1A1A" }}
    >
      上提供的某些商品可能不適合某年齡以下的兒童使用，或商品可能要求家長監督
    </span>
    <span
      style={{ fontSize: 14, fontFamily: '"Nunito Sans"', color: "#1A1A1A" }}
    >
      /
    </span>
    <span
      style={{ fontSize: 14, fontFamily: '"PMingLiU",serif', color: "#1A1A1A" }}
    >
      演示如何使用（例如兒童產品）。閣下需根據所有適用的安全標籤、健康警告、裝配說明和其他警示性聲明和指示使用商品，並考慮兒童的年齡和技能水平決定商品或服務是否適合兒童使用。如果閣下對商品的使用或安全性有疑問，請聯絡有問題的製造商或供應商，或諮詢專業人士的建議。
    </span>
  </p>
  <p
    style={{
      marginTop: "15.0pt",
      marginRight: "0cm",
      marginBottom: "15.0pt",
      marginLeft: "0cm",
      lineHeight: "normal",
      fontSize: 16,
      fontFamily: '"Aptos",sans-serif',
      textAlign: "justify"
    }}
  >
    <strong>
      <span
        style={{ fontSize: 14, fontFamily: '"Nunito Sans"', color: "#1A1A1A" }}
      >
        8.&nbsp;
      </span>
    </strong>
    <strong>
      <span
        style={{
          fontSize: 14,
          fontFamily: '"PMingLiU",serif',
          color: "#1A1A1A"
        }}
      >
        兒童
      </span>
    </strong>
  </p>
  <p
    style={{
      marginTop: "0cm",
      marginRight: "0cm",
      marginBottom: "22.5pt",
      marginLeft: "0cm",
      lineHeight: "normal",
      fontSize: 16,
      fontFamily: '"Aptos",sans-serif',
      textAlign: "justify"
    }}
  >
    <span
      style={{ fontSize: 14, fontFamily: '"PMingLiU",serif', color: "#1A1A1A" }}
    >
      我們可能會出售兒童產品或服務供成人購買。我們不出售供兒童購買的商品。如果未滿
    </span>
    <span
      style={{ fontSize: 14, fontFamily: '"Nunito Sans"', color: "#1A1A1A" }}
    >
      &nbsp;18&nbsp;
    </span>
    <span
      style={{ fontSize: 14, fontFamily: '"PMingLiU",serif', color: "#1A1A1A" }}
    >
      歲，則不得使用
    </span>
    <span
      style={{ fontSize: 14, fontFamily: '"Nunito Sans"', color: "#1A1A1A" }}
    >
      &nbsp;
    </span>
    <span
      style={{ fontSize: 14, fontFamily: '"Nunito Sans"', color: "#1A1A1A" }}
    >
      Good Club
    </span>
    <span
      style={{ fontSize: 14, fontFamily: '"PMingLiU",serif', color: "#1A1A1A" }}
    >
      。&nbsp;
    </span>
    <span
      style={{ fontSize: 14, fontFamily: '"Nunito Sans"', color: "#1A1A1A" }}
    >
      Good Club
    </span>
    <span
      style={{ fontSize: 14, fontFamily: '"Nunito Sans"', color: "#1A1A1A" }}
    >
      &nbsp;
    </span>
    <span
      style={{ fontSize: 14, fontFamily: '"PMingLiU",serif', color: "#1A1A1A" }}
    >
      可能有一些不適合
    </span>
    <span
      style={{ fontSize: 14, fontFamily: '"Nunito Sans"', color: "#1A1A1A" }}
    >
      &nbsp;18&nbsp;
    </span>
    <span
      style={{ fontSize: 14, fontFamily: '"PMingLiU",serif', color: "#1A1A1A" }}
    >
      歲以下人士觀看的內容。在閣下允許他人查看
    </span>
    <span
      style={{ fontSize: 14, fontFamily: '"Nunito Sans"', color: "#1A1A1A" }}
    >
      &nbsp;
    </span>
    <span
      style={{ fontSize: 14, fontFamily: '"Nunito Sans"', color: "#1A1A1A" }}
    >
      Good Club
    </span>
    <span
      style={{ fontSize: 14, fontFamily: '"PMingLiU",serif', color: "#1A1A1A" }}
    >
      之前，閣下有責任查看任何希望或打算查看
    </span>
    <span
      style={{ fontSize: 14, fontFamily: '"Nunito Sans"', color: "#1A1A1A" }}
    >
      &nbsp;
    </span>
    <span
      style={{ fontSize: 14, fontFamily: '"Nunito Sans"', color: "#1A1A1A" }}
    >
      Good Club
    </span>
    <span
      style={{ fontSize: 14, fontFamily: '"Nunito Sans"', color: "#1A1A1A" }}
    >
      &nbsp;
    </span>
    <span
      style={{ fontSize: 14, fontFamily: '"PMingLiU",serif', color: "#1A1A1A" }}
    >
      的人的身份及
    </span>
    <span
      style={{ fontSize: 14, fontFamily: '"Nunito Sans"', color: "#1A1A1A" }}
    >
      /
    </span>
    <span
      style={{ fontSize: 14, fontFamily: '"PMingLiU",serif', color: "#1A1A1A" }}
    >
      或年齡。
    </span>
  </p>
  <p
    style={{
      marginTop: "15.0pt",
      marginRight: "0cm",
      marginBottom: "15.0pt",
      marginLeft: "0cm",
      lineHeight: "normal",
      fontSize: 16,
      fontFamily: '"Aptos",sans-serif',
      textAlign: "justify"
    }}
  >
    <strong>
      <span
        style={{ fontSize: 14, fontFamily: '"Nunito Sans"', color: "#1A1A1A" }}
      >
        9.&nbsp;
      </span>
    </strong>
    <strong>
      <span
        style={{
          fontSize: 14,
          fontFamily: '"PMingLiU",serif',
          color: "#1A1A1A"
        }}
      >
        其他業務
      </span>
    </strong>
  </p>
  <p
    style={{
      marginTop: "0cm",
      marginRight: "0cm",
      marginBottom: "22.5pt",
      marginLeft: "0cm",
      lineHeight: "normal",
      fontSize: 16,
      fontFamily: '"Aptos",sans-serif',
      textAlign: "justify"
    }}
  >
    <span
      style={{ fontSize: 14, fontFamily: '"PMingLiU",serif', color: "#1A1A1A" }}
    >
      除了我們和我們的關聯公司以外的其他各方透過
    </span>
    <span
      style={{ fontSize: 14, fontFamily: '"Nunito Sans"', color: "#1A1A1A" }}
    >
      &nbsp;
    </span>
    <span
      style={{ fontSize: 14, fontFamily: '"Nunito Sans"', color: "#1A1A1A" }}
    >
      Good Club
    </span>
    <span
      style={{ fontSize: 14, fontFamily: '"PMingLiU",serif', color: "#1A1A1A" }}
    >
      或透過鏈結到其他企業的網站銷售產品或提供服務。我們不對這些網站的業務或內容承擔責任、提供保證或認可。
    </span>
    <span
      style={{ fontSize: 14, fontFamily: '"Nunito Sans"', color: "#1A1A1A" }}
    >
      Good Club
    </span>
    <span
      style={{ fontSize: 14, fontFamily: '"Nunito Sans"', color: "#1A1A1A" }}
    >
      &nbsp;
    </span>
    <span
      style={{ fontSize: 14, fontFamily: '"PMingLiU",serif', color: "#1A1A1A" }}
    >
      對這些業務或任何第三方的行為、疏忽、產品或內容不承擔任何責任或義務。
    </span>
    <span
      style={{ fontSize: 14, fontFamily: '"Nunito Sans"', color: "#1A1A1A" }}
    >
      Good Club
    </span>
    <span
      style={{ fontSize: 14, fontFamily: '"Nunito Sans"', color: "#1A1A1A" }}
    >
      &nbsp;
    </span>
    <span
      style={{ fontSize: 14, fontFamily: '"PMingLiU",serif', color: "#1A1A1A" }}
    >
      上提供的某些產品或服務可能需要直接與供應商聯絡，我們不會參與閣下與供應商之間的任何互動，交流或談判。在訂購這些產品或服務時，閣下應該意識到透過使用
    </span>
    <span
      style={{ fontSize: 14, fontFamily: '"Nunito Sans"', color: "#1A1A1A" }}
    >
      &nbsp;
    </span>
    <span
      style={{ fontSize: 14, fontFamily: '"Nunito Sans"', color: "#1A1A1A" }}
    >
      Good Club
    </span>
    <span
      style={{ fontSize: 14, fontFamily: '"Nunito Sans"', color: "#1A1A1A" }}
    >
      &nbsp;
    </span>
    <span
      style={{ fontSize: 14, fontFamily: '"PMingLiU",serif', color: "#1A1A1A" }}
    >
      選擇與供應商交流或談判，包括但不限於解決任何性質的爭議，衝突或主張時應負全部責任（且我們不承擔任何責任）。
    </span>
  </p>
  <p
    style={{
      marginTop: "15.0pt",
      marginRight: "0cm",
      marginBottom: "15.0pt",
      marginLeft: "0cm",
      lineHeight: "normal",
      fontSize: 16,
      fontFamily: '"Aptos",sans-serif',
      textAlign: "justify"
    }}
  >
    <strong>
      <span
        style={{ fontSize: 14, fontFamily: '"Nunito Sans"', color: "#1A1A1A" }}
      >
        10.&nbsp;
      </span>
    </strong>
    <strong>
      <span
        style={{
          fontSize: 14,
          fontFamily: '"PMingLiU",serif',
          color: "#1A1A1A"
        }}
      >
        責任
      </span>
    </strong>
  </p>
  <p
    style={{
      marginTop: "0cm",
      marginRight: "0cm",
      marginBottom: "0cm",
      marginLeft: "0cm",
      lineHeight: "normal",
      fontSize: 16,
      fontFamily: '"Aptos",sans-serif',
      textAlign: "justify"
    }}
  >
    <span
      style={{ fontSize: 14, fontFamily: '"PMingLiU",serif', color: "#1A1A1A" }}
    >
      關於閣下使用
    </span>
    <span
      style={{ fontSize: 14, fontFamily: '"Nunito Sans"', color: "#1A1A1A" }}
    >
      &nbsp;
    </span>
    <span
      style={{ fontSize: 14, fontFamily: '"Nunito Sans"', color: "#1A1A1A" }}
    >
      Good Club
    </span>
    <span
      style={{ fontSize: 14, fontFamily: '"PMingLiU",serif', color: "#1A1A1A" }}
    >
      提供的貨物、服務和資訊，閣下同意：
    </span>
  </p>
  <ol style={{ marginBottom: "0cm", marginTop: "0cm" }} start={1} type="a">
    <ol style={{ marginBottom: "0cm", marginTop: "0cm" }} start={1} type="a">
      <ol style={{ marginBottom: "0cm", marginTop: "0cm" }} start={1} type="a">
        <li
          style={{
            marginTop: "0cm",
            marginRight: "0cm",
            marginBottom: "8.0pt",
            marginLeft: "0cm",
            lineHeight: "normal",
            fontSize: 16,
            fontFamily: '"Aptos",sans-serif',
            color: "#1A1A1A",
            textAlign: "justify"
          }}
        >
          <span style={{ fontSize: 14, fontFamily: '"PMingLiU",serif' }}>
            在法律允許的範圍內，所有關於商品的保證、陳述或聲明，不論是明示或暗示，這些信息不包含在網站上的均被排除在外；
          </span>
        </li>
        <li
          style={{
            marginTop: "0cm",
            marginRight: "0cm",
            marginBottom: "8.0pt",
            marginLeft: "0cm",
            lineHeight: "normal",
            fontSize: 16,
            fontFamily: '"Aptos",sans-serif',
            color: "#1A1A1A",
            textAlign: "justify"
          }}
        >
          <span style={{ fontSize: 14, fontFamily: '"PMingLiU",serif' }}>
            在法律允許的範圍內，我們和我們的聯屬公司不會對閣下或任何人承擔或負責因使用商品或因購買或兌換
          </span>
          <span style={{ fontSize: 14, fontFamily: '"Nunito Sans"' }}>
            &nbsp;
          </span>
          <span style={{ fontSize: 14, fontFamily: '"Nunito Sans"' }}>
            Good Club
          </span>
          <span style={{ fontSize: 14, fontFamily: '"PMingLiU",serif' }}>
            上的商品而產生的任何成本、索賠、損害或損失，包括任何形式，無論是直接或間接的收入損失、利潤損失或任何相應的合同損失、侵權行為的所有責任；
          </span>
        </li>
        <li
          style={{
            marginTop: "0cm",
            marginRight: "0cm",
            marginBottom: "8.0pt",
            marginLeft: "0cm",
            lineHeight: "normal",
            fontSize: 16,
            fontFamily: '"Aptos",sans-serif',
            color: "#1A1A1A",
            textAlign: "justify"
          }}
        >
          <span style={{ fontSize: 14, fontFamily: '"PMingLiU",serif' }}>
            儘管本銷售條款及細則有任何其他規定，我們對閣下或任何其他方所需承擔的所有責任應限制於閣下向我們購買時所支付的總金額。
          </span>
        </li>
      </ol>
    </ol>
  </ol>
  <p
    style={{
      marginTop: "15.0pt",
      marginRight: "0cm",
      marginBottom: "15.0pt",
      marginLeft: "0cm",
      lineHeight: "normal",
      fontSize: 16,
      fontFamily: '"Aptos",sans-serif',
      textAlign: "justify"
    }}
  >
    <strong>
      <span
        style={{ fontSize: 14, fontFamily: '"Nunito Sans"', color: "#1A1A1A" }}
      >
        11.&nbsp;
      </span>
    </strong>
    <strong>
      <span
        style={{
          fontSize: 14,
          fontFamily: '"PMingLiU",serif',
          color: "#1A1A1A"
        }}
      >
        更改服務或修改條款
      </span>
    </strong>
  </p>
  <p
    style={{
      marginTop: "0cm",
      marginRight: "0cm",
      marginBottom: "22.5pt",
      marginLeft: "0cm",
      lineHeight: "normal",
      fontSize: 16,
      fontFamily: '"Aptos",sans-serif',
      textAlign: "justify"
    }}
  >
    <span
      style={{ fontSize: 14, fontFamily: '"PMingLiU",serif', color: "#1A1A1A" }}
    >
      我們保留可隨時更改
    </span>
    <span
      style={{ fontSize: 14, fontFamily: '"Nunito Sans"', color: "#1A1A1A" }}
    >
      &nbsp;
    </span>
    <span
      style={{ fontSize: 14, fontFamily: '"Nunito Sans"', color: "#1A1A1A" }}
    >
      Good Club
    </span>
    <span
      style={{ fontSize: 14, fontFamily: '"PMingLiU",serif', color: "#1A1A1A" }}
    >
      ，本銷售條款及細則以及所有相關的政策和通知的權利；除非我們另有規定，此類修改於網站上發布後應立即生效。在任何此類修改後使用
    </span>
    <span
      style={{ fontSize: 14, fontFamily: '"Nunito Sans"', color: "#1A1A1A" }}
    >
      &nbsp;
    </span>
    <span
      style={{ fontSize: 14, fontFamily: '"Nunito Sans"', color: "#1A1A1A" }}
    >
      Good Club
    </span>
    <span
      style={{ fontSize: 14, fontFamily: '"Nunito Sans"', color: "#1A1A1A" }}
    >
      &nbsp;
    </span>
    <span
      style={{ fontSize: 14, fontFamily: '"PMingLiU",serif', color: "#1A1A1A" }}
    >
      即表示閣下同意遵守修訂後的條款並受其約束。在使用或瀏覽
    </span>
    <span
      style={{ fontSize: 14, fontFamily: '"Nunito Sans"', color: "#1A1A1A" }}
    >
      &nbsp;
    </span>
    <span
      style={{ fontSize: 14, fontFamily: '"Nunito Sans"', color: "#1A1A1A" }}
    >
      Good Club
    </span>
    <span
      style={{ fontSize: 14, fontFamily: '"Nunito Sans"', color: "#1A1A1A" }}
    >
      &nbsp;
    </span>
    <span
      style={{ fontSize: 14, fontFamily: '"PMingLiU",serif', color: "#1A1A1A" }}
    >
      或於
    </span>
    <span
      style={{ fontSize: 14, fontFamily: '"Nunito Sans"', color: "#1A1A1A" }}
    >
      &nbsp;
    </span>
    <span
      style={{ fontSize: 14, fontFamily: '"Nunito Sans"', color: "#1A1A1A" }}
    >
      Good Club
    </span>
    <span
      style={{ fontSize: 14, fontFamily: '"Nunito Sans"', color: "#1A1A1A" }}
    >
      &nbsp;
    </span>
    <span
      style={{ fontSize: 14, fontFamily: '"PMingLiU",serif', color: "#1A1A1A" }}
    >
      購物或兌換時，閣下將受到本銷售條款及細則以及所有相關政策和通知的約束。閣下應定期瀏覽本網站以查看最新的銷售條款及細則。如果本銷售條款及細則或相關政策或通知的任何條款被視為無效，或因任何原因無法執行該條款，該條款將被視為可分割，並不會影響任何其餘條款的有效性和可執行性。
    </span>
  </p>
  <p
    style={{
      marginTop: "15.0pt",
      marginRight: "0cm",
      marginBottom: "15.0pt",
      marginLeft: "0cm",
      lineHeight: "normal",
      fontSize: 16,
      fontFamily: '"Aptos",sans-serif',
      textAlign: "justify"
    }}
  >
    <strong>
      <span
        style={{ fontSize: 14, fontFamily: '"Nunito Sans"', color: "#1A1A1A" }}
      >
        12.&nbsp;
      </span>
    </strong>
    <strong>
      <span
        style={{
          fontSize: 14,
          fontFamily: '"PMingLiU",serif',
          color: "#1A1A1A"
        }}
      >
        超出我們合理控制的原因
      </span>
    </strong>
  </p>
  <p
    style={{
      marginTop: "0cm",
      marginRight: "0cm",
      marginBottom: "22.5pt",
      marginLeft: "0cm",
      lineHeight: "normal",
      fontSize: 16,
      fontFamily: '"Aptos",sans-serif',
      textAlign: "justify"
    }}
  >
    <span
      style={{ fontSize: 14, fontFamily: '"PMingLiU",serif', color: "#1A1A1A" }}
    >
      如果由於一些超出我們合理控制範圍的原因而導致延誤或失敗，我們將不會對延誤或未能履行我們的義務承擔責任。
    </span>
  </p>
  <p
    style={{
      marginTop: "15.0pt",
      marginRight: "0cm",
      marginBottom: "15.0pt",
      marginLeft: "0cm",
      lineHeight: "normal",
      fontSize: 16,
      fontFamily: '"Aptos",sans-serif',
      textAlign: "justify"
    }}
  >
    <strong>
      <span
        style={{ fontSize: 14, fontFamily: '"Nunito Sans"', color: "#1A1A1A" }}
      >
        13.&nbsp;
      </span>
    </strong>
    <strong>
      <span
        style={{
          fontSize: 14,
          fontFamily: '"PMingLiU",serif',
          color: "#1A1A1A"
        }}
      >
        貨物運送到香港以外的地方
      </span>
    </strong>
  </p>
  <p
    style={{
      marginTop: "0cm",
      marginRight: "0cm",
      marginBottom: "22.5pt",
      marginLeft: "0cm",
      lineHeight: "normal",
      fontSize: 16,
      fontFamily: '"Aptos",sans-serif',
      textAlign: "justify"
    }}
  >
    <span
      style={{ fontSize: 14, fontFamily: '"PMingLiU",serif', color: "#1A1A1A" }}
    >
      送貨地點僅限於香港。
    </span>
  </p>
  <p
    style={{
      marginTop: "15.0pt",
      marginRight: "0cm",
      marginBottom: "15.0pt",
      marginLeft: "0cm",
      lineHeight: "normal",
      fontSize: 16,
      fontFamily: '"Aptos",sans-serif',
      textAlign: "justify"
    }}
  >
    <strong>
      <span
        style={{ fontSize: 14, fontFamily: '"Nunito Sans"', color: "#1A1A1A" }}
      >
        14.&nbsp;
      </span>
    </strong>
    <strong>
      <span
        style={{
          fontSize: 14,
          fontFamily: '"PMingLiU",serif',
          color: "#1A1A1A"
        }}
      >
        分配
      </span>
    </strong>
  </p>
  <p
    style={{
      marginTop: "0cm",
      marginRight: "0cm",
      marginBottom: "22.5pt",
      marginLeft: "0cm",
      lineHeight: "normal",
      fontSize: 16,
      fontFamily: '"Aptos",sans-serif',
      textAlign: "justify"
    }}
  >
    <span
      style={{ fontSize: 14, fontFamily: '"PMingLiU",serif', color: "#1A1A1A" }}
    >
      我們可以隨時將本銷售條款及細則中包含的全部或部分權利及
    </span>
    <span
      style={{ fontSize: 14, fontFamily: '"Nunito Sans"', color: "#1A1A1A" }}
    >
      /
    </span>
    <span
      style={{ fontSize: 14, fontFamily: '"PMingLiU",serif', color: "#1A1A1A" }}
    >
      或義務分配、轉讓及
    </span>
    <span
      style={{ fontSize: 14, fontFamily: '"Nunito Sans"', color: "#1A1A1A" }}
    >
      /
    </span>
    <span
      style={{ fontSize: 14, fontFamily: '"PMingLiU",serif', color: "#1A1A1A" }}
    >
      或分包給任何人及
    </span>
    <span
      style={{ fontSize: 14, fontFamily: '"Nunito Sans"', color: "#1A1A1A" }}
    >
      /
    </span>
    <span
      style={{ fontSize: 14, fontFamily: '"PMingLiU",serif', color: "#1A1A1A" }}
    >
      或實體，而無需通知閣下。
    </span>
  </p>
  <p
    style={{
      marginTop: "15.0pt",
      marginRight: "0cm",
      marginBottom: "15.0pt",
      marginLeft: "0cm",
      lineHeight: "normal",
      fontSize: 16,
      fontFamily: '"Aptos",sans-serif',
      textAlign: "justify"
    }}
  >
    <strong>
      <span
        style={{ fontSize: 14, fontFamily: '"Nunito Sans"', color: "#1A1A1A" }}
      >
        15.&nbsp;
      </span>
    </strong>
    <strong>
      <span
        style={{
          fontSize: 14,
          fontFamily: '"PMingLiU",serif',
          color: "#1A1A1A"
        }}
      >
        管轄法律和管轄權
      </span>
    </strong>
  </p>
  <p
    style={{
      marginTop: "0cm",
      marginRight: "0cm",
      marginBottom: "22.5pt",
      marginLeft: "0cm",
      lineHeight: "normal",
      fontSize: 16,
      fontFamily: '"Aptos",sans-serif',
      textAlign: "justify"
    }}
  >
    <span
      style={{ fontSize: 14, fontFamily: '"PMingLiU",serif', color: "#1A1A1A" }}
    >
      本銷售條款及細則受中華人民共和國香港特別行政區（以下簡稱「香港」）法律的管轄和解釋。
    </span>
    <span
      style={{ fontSize: 14, fontFamily: '"Nunito Sans"', color: "#1A1A1A" }}
    >
      &nbsp;
    </span>
    <span
      style={{ fontSize: 14, fontFamily: '"PMingLiU",serif', color: "#1A1A1A" }}
    >
      閣下同意服從香港法院的專屬管轄權。
    </span>
  </p>
  <p
    style={{
      marginTop: "15.0pt",
      marginRight: "0cm",
      marginBottom: "15.0pt",
      marginLeft: "0cm",
      lineHeight: "normal",
      fontSize: 16,
      fontFamily: '"Aptos",sans-serif',
      textAlign: "justify"
    }}
  >
    <strong>
      <span
        style={{ fontSize: 14, fontFamily: '"Nunito Sans"', color: "#1A1A1A" }}
      >
        16.&nbsp;
      </span>
    </strong>
    <strong>
      <span
        style={{
          fontSize: 14,
          fontFamily: '"PMingLiU",serif',
          color: "#1A1A1A"
        }}
      >
        其他
      </span>
    </strong>
  </p>
  <p
    style={{
      marginTop: "0cm",
      marginRight: "0cm",
      marginBottom: "22.5pt",
      marginLeft: "0cm",
      lineHeight: "normal",
      fontSize: 16,
      fontFamily: '"Aptos",sans-serif',
      textAlign: "justify"
    }}
  >
    <span
      style={{ fontSize: 14, fontFamily: '"PMingLiU",serif', color: "#1A1A1A" }}
    >
      本銷售條款及細則，
    </span>
    <span
      style={{ fontSize: 14, fontFamily: '"Nunito Sans"', color: "#1A1A1A" }}
    >
      &nbsp;
    </span>
    <span
      style={{ fontSize: 14, fontFamily: '"PMingLiU",serif', color: "#1A1A1A" }}
    >
      概以英文版本為準。我們提供的翻譯資訊旨在方便閣下於本平台瀏覽、購物和通訊。我們的翻譯只為了閣下的方便而設。「供應商」是指閣下根據本銷售條款及細則下，透過
    </span>
    <span
      style={{ fontSize: 14, fontFamily: '"Nunito Sans"', color: "#1A1A1A" }}
    >
      &nbsp;
    </span>
    <span
      style={{ fontSize: 14, fontFamily: '"Nunito Sans"', color: "#1A1A1A" }}
    >
      Good Club
    </span>
    <span
      style={{ fontSize: 14, fontFamily: '"PMingLiU",serif', color: "#1A1A1A" }}
    >
      購買的任何第三方商品的供應商。
    </span>
  </p>
  <p
    style={{
      marginTop: "0cm",
      marginRight: "0cm",
      marginBottom: "22.5pt",
      marginLeft: "0cm",
      lineHeight: "normal",
      fontSize: 16,
      fontFamily: '"Aptos",sans-serif',
      textAlign: "justify"
    }}
  >
    <span
      style={{ fontSize: 14, fontFamily: '"PMingLiU",serif', color: "#1A1A1A" }}
    >
      最後更新：
    </span>
    <span
      style={{ fontSize: 14, fontFamily: '"Nunito Sans"', color: "#1A1A1A" }}
    >
      2024
    </span>
    <span
      style={{ fontSize: 14, fontFamily: '"PMingLiU",serif', color: "#1A1A1A" }}
    >
      年
    </span>
    <span
      style={{ fontSize: 14, fontFamily: '"Nunito Sans"', color: "#1A1A1A" }}
    >
      5
    </span>
    <span
      style={{ fontSize: 14, fontFamily: '"PMingLiU",serif', color: "#1A1A1A" }}
    >
      月
    </span>
    <span
      style={{ fontSize: 14, fontFamily: '"Nunito Sans"', color: "#1A1A1A" }}
    >
      31
    </span>
    <span
      style={{ fontSize: 14, fontFamily: '"PMingLiU",serif', color: "#1A1A1A" }}
    >
      日
    </span>
  </p>
  <p
    style={{
      marginTop: "0cm",
      marginRight: "0cm",
      marginBottom: "8.0pt",
      marginLeft: "0cm",
      lineHeight: "115%",
      fontSize: 16,
      fontFamily: '"Aptos",sans-serif'
    }}
  >
    &nbsp;
  </p>
</>

    </div>
  );
};

export default TermsComponent;
