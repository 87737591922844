/*
 * @Author: Michael Lam 127068684+MichaelLam888@users.noreply.github.com
 * @Date: 2024-05-25 17:03:06
 * @LastEditTime: 2024-05-28 21:45:16
 * @Description: 
 */
import React from 'react';
import '../css/ConfirmationDialog.css'; // Assuming you have corresponding CSS file




const AddToCartDialog0 = ({ product, onCancel }) => {


  return (

    <div className="confirmation-overlay">

      <div className="confirmation-dialog">
        <p>由於機票價格浮動，如果閣下想換領</p>
        <p>請按下方 確認  以聯絡我們真人客服人員為你安排，感謝 </p>
        <div className="button-container">
          <button onClick={onCancel}>返回</button>
          <a href="https://api.whatsapp.com/send?phone=61032237" target='_blank' rel="noreferrer" ><button >確認</button></a>
        </div>
      </div>

    </div>
  );
};

export default AddToCartDialog0;
